import React, { useState } from "react";
import { Row, Col, Card, Form, Dropdown, Button } from "react-bootstrap";
import PageTitle from "../../components/PageTitle";
import Table from "../../components/Table";
import SessionSelector from "../apps/FileManager/SessionSelector";
import { records as data } from "./data";
type RowIdType = string | number; // Define a type alias for row IDs
const MbbsStudentList = () => {
  const [selectedRows, setSelectedRows] = useState<RowIdType[]>([]);
  const [selectedOption, setSelectedOption] = useState<string>("");

  const handleRowSelect = (rowId: RowIdType, isSelected: boolean) => {
    setSelectedRows((prevSelectedRows) =>
      isSelected
        ? [...prevSelectedRows, rowId]
        : prevSelectedRows.filter((id) => id !== rowId)
    );
  };

  const handleSelectOption = (option: string) => {
    setSelectedOption(option);
    if (option === "Select all") {
      const allRowIds = data.map((row) => row.id);
      setSelectedRows(allRowIds);
    }
    if (option === "Export session") {
      const allRowIds = data.map((row) => row.id);
      setSelectedRows(allRowIds);
    }
  };
  const columns = [
    {
      Header: "",
      accessor: "checkbox",
      Cell: ({ row }: { row: any }) => (
        <Form.Check
          type="checkbox"
          checked={selectedRows.includes(row.original.id)}
          onChange={(e) => handleRowSelect(row.original.id, e.target.checked)}
        />
      ),

      sort: false,
    },
    {
      Header: "S.No.",
      accessor: "id",
      sort: true,
    },
    {
      Header: "Enrollment No",
      accessor: "enrollment_no",
      sort: true,
    },
    // {
    //   Header: "Roll No",
    //   accessor: "roll_number",
    //   sort: true,
    // },
    {
      Header: "Name",
      accessor: "name",
      sort: true,
    },
    {
      Header: "Educational Details",
      accessor: "secondStepStatus",
      Cell: ({ value }: { value: any }) => (
        <span>{value ? "Completed" : "Incomplete"}</span>
      ),
    },

    {
      Header: "Details",
      accessor: "a",
      Cell: () => (
        <a href={`/details/1`}>
          <i className="uil uil-eye"></i>
        </a>
      ),
      sort: false,
    },
    {
      Header: "Action",
      accessor: "b",
      Cell: () => (
        <a href={`/edit/2`}>
          <i className="uil uil-edit"></i>
        </a>
      ),
      sort: false,
    },
  ];

  const sizePerPageList = [
    { text: "10", value: 10 },
    { text: "25", value: 25 },
    { text: "50", value: 50 },
    { text: "All", value: data.length },
  ];

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "MBBS", path: "/pages/mbbs-student-list" },
          {
            label: "MBBS Student List",
            path: "/pages/mbbs-student-list",
            active: true,
          },
        ]}
        title={"MBBS Student List"}
      />
      <Row>
        <Col>
          <Dropdown
            onSelect={(eventKey) => eventKey && handleSelectOption(eventKey)}
          >
            <Dropdown.Toggle variant="primary" id="dropdown-basic">
              Select Option
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey="Export session" className="d-flex">
                <Form.Check
                  type="checkbox"
                  checked={selectedOption === "Export session"}
                  // onChange={(e) =>
                  //   handleRowSelect(row.original.id, e.target.checked)
                  // }
                />{" "}
                <div> Export session</div>
              </Dropdown.Item>
              <Dropdown.Item eventKey="Select all" className="d-flex">
                <Form.Check
                  type="checkbox"
                  checked={selectedOption === "Select all"}
                  // onChange={(e) =>
                  //   handleRowSelect(row.original.id, e.target.checked)
                  // }
                />{" "}
                <div>Email for stage-2</div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col>
          <Button variant="primary" disabled={selectedOption !== "Select all"}>
            Send reminder email
          </Button>
        </Col>

        <Col>
          <Button variant="primary" disabled={selectedOption !== "Select all"}>
            Export to session
          </Button>
        </Col>
        <Col>
          <div className="inbox-rightbar h-100 pb-0">
            <SessionSelector />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Table
                columns={columns}
                data={data}
                pageSize={5}
                sizePerPageList={sizePerPageList}
                isSortable={true}
                pagination={true}
                isSearchable={true}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default MbbsStudentList;
