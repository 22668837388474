import React, { useState } from "react";
import { Row, Col, Card } from "react-bootstrap";

// components
import PageTitle from "../../components/PageTitle";
import Table from "../../components/Table";

//dummy data
import { records as data } from "./data";

const columns = [
  {
    Header: "S.No.",
    sort: true,
    accessor: "id",
  },
  {
    Header: "Years",
    accessor: "years",
    sort: false,
  },
  {
    Header: "Enrollment No",
    accessor: "enrollmentNo",
    sort: true,
  },
  {
    Header: "Roll No",
    accessor: "rollNo",
    sort: true,
  },
  {
    Header: "Full Name",
    accessor: "fullName",
    sort: false,
  },
  {
    Header: "Details",
    accessor: "details",
    sort: true,
  },
  {
    Header: "Action",
    accessor: "action",
    sort: false,
  },
];

const sizePerPageList = [
  {
    text: "5",
    value: 5,
  },
  {
    text: "10",
    value: 10,
  },
  {
    text: "25",
    value: 25,
  },
  {
    text: "All",
    value: data.length,
  },
];

const MbbsElectives = () => {
  const [selectedYear, setSelectedYear] = useState<number | "">(""); // State to hold the selected year

  // Function to handle year selection change
  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(parseInt(event.target.value));
  };

  // Filter data based on selected year
  const filteredData = selectedYear !== "" ? data.filter(item => item.years === selectedYear) : data;

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "MBBS", path: "/pages/mbbs-student-list" },
          {
            label: "MBBS Electives",
            path: "/pages/mbbs-student-list",
            active: true,
          },
        ]}
        title={"MBBS Electives"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className="d-flex gap-5">
                <div><h4 className="header-title">MBBS Electives Students list</h4></div>
                <div className="">
                  <select
                    value={selectedYear}
                    onChange={handleYearChange}
                    aria-label="Default select example"
                  >
                    <option value="">Select Years</option>
                    <option value="2018">2018</option>
                    <option value="2019">2019</option>
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                  </select>
                </div>
              </div>

              <Table
                columns={columns}
                data={filteredData}
                pageSize={5}
                sizePerPageList={sizePerPageList}
                isSortable={true}
                pagination={true}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default MbbsElectives;
