import React from "react";
import {
  Col,
  Row,
  InputGroup,
  FormControl,
  ButtonGroup,
  Dropdown,
  Button,
  OverlayTrigger,
  Tooltip,
  Form,
} from "react-bootstrap";

const SessionSelector = () => {
  return (
    <>
      <Row>
        <Col xs={12}>
          <div className="d-block">
            <div className="float-lg-end">
              <div className="d-lg-flex align-items-center mb-2 mt-0">
                <ButtonGroup>
                  <Dropdown>
                    <Dropdown.Toggle variant="light">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="viewoptions">View Options</Tooltip>
                        }
                      >
                        <span className="text-dark">
                          Session <i className="bi bi-caret-down"></i>
                        </span>
                      </OverlayTrigger>
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                      <Dropdown.Item>2024</Dropdown.Item>
                      <Dropdown.Item>2023</Dropdown.Item>
                      <Dropdown.Item>2022</Dropdown.Item>
                      <Dropdown.Item>2021</Dropdown.Item>
                      <Dropdown.Item>2020</Dropdown.Item>
                      <Dropdown.Item>2019</Dropdown.Item>
                      <Dropdown.Item>2018</Dropdown.Item>
                      <Dropdown.Item>2017</Dropdown.Item>
                      <Dropdown.Item>2016</Dropdown.Item>
                      <Dropdown.Item>2015</Dropdown.Item>
                      <Dropdown.Item>2014</Dropdown.Item>
                      <Dropdown.Item>2013</Dropdown.Item>
                      <Dropdown.Item>2012</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <Dropdown>
                    <Dropdown.Toggle variant="light">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="viewoptions">View Options</Tooltip>
                        }
                      >
                        <Button variant="success" className="">
                          {/* <span className="text-dark"> */}
                          <i className="bi bi-file-earmark-excel"></i>
                          {/* </span> */}
                        </Button>
                      </OverlayTrigger>
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                      {/* <Dropdown.Item
                        href="#"
                        className="text-center border-bottom"
                      >
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="newwindow">New Window</Tooltip>}
                        >
                          <i className="bi bi-window me-2 fs-15"></i>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="newfolder">New Folder</Tooltip>}
                        >
                          <i className="bi bi-folder-plus fs-15"></i>
                        </OverlayTrigger>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        Edit
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="cut">Cut</Tooltip>}
                        >
                          <i className="bi bi-scissors mx-2 fs-15"></i>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="copy">Copy</Tooltip>}
                        >
                          <i className="bi bi-clipboard-check me-2 fs-15"></i>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="paste">Paste</Tooltip>}
                        >
                          <i className="bi bi-clipboard-data fs-15"></i>
                        </OverlayTrigger>
                      </Dropdown.Item>
                      <Dropdown.Item>Select All</Dropdown.Item>
                      <Dropdown.Item className="d-flex">
                        <Form.Check>
                          <Form.Check.Input
                            type="checkbox"
                            className="me-2"
                            id="hiddenFiles"
                          />
                        </Form.Check>
                        Show Hidden Files
                      </Dropdown.Item>
                      <Dropdown.Item className="d-flex">
                        <Form.Check>
                          <Form.Check.Input
                            type="checkbox"
                            className="me-2"
                            id="hiddenFiles"
                          />
                        </Form.Check>
                        Show Sidebar
                      </Dropdown.Item>
                      <Dropdown.Item>Help</Dropdown.Item> */}
                      <Dropdown.Item>Excel Export</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </ButtonGroup>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SessionSelector;
