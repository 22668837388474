interface Records {
  id: number;
  years:number;
  enrollmentNo: number;
  rollNo: string;
  fullName: string;
  details: string;
  action: string;
}


const records: Records[] = [
  {
    id: 1,
    years:2020,
    enrollmentNo: 32,
    rollNo: "Burt",
    fullName: "Kaggle",
    details: "+1 (823) 532-2427",
    action: "+1 (823) 532-2427",
  },
  
  {
    id: 2,
    years:2023,
    enrollmentNo: 32,
    rollNo: "Burt",
    fullName: "Kaggle",
    details: "+1 (823) 532-2427",
    action: "+1 (823) 532-2427",
  },
  {
    id: 3,
    years:2020,
    enrollmentNo: 32,
    rollNo: "Burt",
    fullName: "Kaggle",
    details: "+1 (823) 532-2427",
    action: "+1 (823) 532-2427",
  },
  {
    id: 4,
    years:2020,
    enrollmentNo: 32,
    rollNo: "Burt",
    fullName: "Kaggle",
    details: "+1 (823) 532-2427",
    action: "+1 (823) 532-2427",
  },
  {
    id: 5,
    years:2020,
    enrollmentNo: 32,
    rollNo: "Burt",
    fullName: "Kaggle",
    details: "+1 (823) 532-2427",
    action: "+1 (823) 532-2427",
  },
  {
    id: 6,
    years:2020,
    enrollmentNo: 32,
    rollNo: "Burt",
    fullName: "Kaggle",
    details: "+1 (823) 532-2427",
    action: "+1 (823) 532-2427",
  },
  {
    id: 7,
    years:2022,
    enrollmentNo: 32,
    rollNo: "Burt",
    fullName: "Kaggle",
    details: "+1 (823) 532-2427",
    action: "+1 (823) 532-2427",
  },
  {
    id: 8,
    years:2018,
    enrollmentNo: 32,
    rollNo: "Burt",
    fullName: "Kaggle",
    details: "+1 (823) 532-2427",
    action: "+1 (823) 532-2427",
  },
  {
    id: 9,
    years:2021,
    enrollmentNo: 32,
    rollNo: "Burt",
    fullName: "Kaggle",
    details: "+1 (823) 532-2427",
    action: "+1 (823) 532-2427",
  },
  {
    id: 10,
    years:2023,
    enrollmentNo: 32,
    rollNo: "Burt",
    fullName: "Kaggle",
    details: "+1 (823) 532-2427",
    action: "+1 (823) 532-2427",
  },
];

export { records };
