// import { height } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Button, Card, Image, Col, Form, Row, Table } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import { logoutUser } from "../../redux/actions";
import AuthModal from "../../components/Modal";
import { Alert } from "react-bootstrap";
import api from "../../utils/ApiMethod";
import ViewModal from "./ViewModal";
import img from "../../assets/images/new.gif";
import { ADMIN_LOGIN_BASE_URL } from "../../apiservices/apiService";

interface OrgtnalSruItem {
  _id?: string;
  aboutText?: string;
  isDeleted?: boolean;
}

interface userData {
  name?: string;
  email?: string;
  punchingid?: string;
  password?: string;
  hobbies?: string;
  role?: string;
  isDeleted?: boolean;
  imageUrl?: string;
  _id?: string;
  department?: string;
}

interface OrgtnalStru {
  _id: string;
  name?: string;
  bannerUrl?: string;
  mobileBannerUrl?: string;
  aboutText?: string;
  // OrgtnalSruItem:array;
  isDeleted: boolean;
  email?: string;
  hobbies?: string;
  role?: string;
  punchingid?: string;
  superDuperAdminApprovalStatus?: string;
  superAdminApprovalStatus?: string;
  adminApprovalStatus?: string;
  innerData?: userData[] | [];
  department?: string;
}

interface UpdateServiceSectionProps {
  itemId: string; // Replace 'boolean' with the appropriate type if needed
  parentId?: string;
  innerdata: userData[];
}
interface ParentId {
  itemId?: string; // Replace 'boolean' with the appropriate type if needed
  id?: string;
  parentId?: string;
}
interface id {
  id?: string;
}

// -------------------------------------  for organizational Structured Item  ---------------
// ---999999999999999999 for update item
const UpdateEmployee: React.FC<UpdateServiceSectionProps> = ({
  itemId,
  parentId,
  innerdata,
}) => {
  const [validated, setValidated] = useState<boolean>(false);
  const [serviceSection, setServiceSection] = useState<OrgtnalStru[]>([]);
  const [aboutText, setAboutText] = useState("");
  const [name, setName] = useState("");
  const [department, setDepartment] = useState("");
  const [role, setRole] = useState("");
  const [description, setDescription] = useState("");
  const [isError, setIsError] = useState<boolean>(false);
  const [isResponse, setIsResponse] = useState("");
  const [url, setUrl] = useState("");
  const [image, setImage] = useState<File | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();

  // console.log("You are in the update section");
  // ------------------------- for saving the data to updte
  const { user, userLoggedIn, loading, error, userId } = useSelector(
    (state: RootState) => ({
      user: state.Auth.user,
      loading: state.Auth.loading,
      error: state.Auth.error,
      userLoggedIn: state.Auth.userLoggedIn,
      userId: state.Auth._id,
    })
  );
  let profileId = user._id ? user._id : user.user ? user.user._id : "";
  const Key_Key = user.moniterd_ttl
    ? user.moniterd_ttl
    : user.user
    ? user.user.moniterd_ttl
    : "";
  let profileName = user.name ? user.name : user.user.name;

  useEffect(() => {
    // const fetchData = async () => {
    //   try {
    //     const response = await fetch(
    //       `http://165.22.219.69:5002/user/${profileId}/${Key_Key}`,
    //       {
    //         method: 'GET',
    //         credentials: 'include',
    //       }
    //     );
    //     if (!response.ok) {
    //       // Handle non-successful response (optional)
    //       console.error('Error fetching data:', response.statusText);
    //       return;
    //     }
    //     if (response.status === 440) {
    //       alert('Session Expired')
    //       dispatch(logoutUser());
    //       window.location.href = 'http://localhost:3001/auth/login'; // Use the full URL, including the protocol (http or https)
    //     }
    //     const data = await response.json();
    //     setServiceSection(data);
    //   } catch (error) {
    //     // Handle fetch error
    //     console.error('Error during fetch:', error);
    //   }
    // };

    const fetchData = async () => {
      try {
        // const response = await fetch(
        //   `http://165.22.219.69:5002/user/${profileId}/${Key_Key}`,
        //   {
        //     method: 'GET',
        //     credentials: 'include',
        //   }
        // );

        let admin =
          Role == "Chess"
            ? `/user/${profileId}/${Key_Key}`
            : `/user/${profileId}/${Key_Key}/${Departmentforfilter}`;
        const response = await api.fetchDataAuth(
          // `/user/${profileId}/${Key_Key}/${Departmentforfilter}`
          `${admin}`
        );

        // const response = await api.fetchDataAuth(
        //   `/user/${profileId}/${Key_Key}`
        // );
        if (response.status === 400) {
          setIsError(true);
          let errorMessage = await response.message;
          setIsResponse(errorMessage);
        }

        if (response.status === 440) {
          alert("Session Expired");
          dispatch(logoutUser());
          window.location.href = ADMIN_LOGIN_BASE_URL; // Use the full URL, including the protocol (http or https)
        }
        if (!response.status == true) {
          // console.log("response--", await response.json())
          let errorMsg = await response.json();
          setIsResponse(errorMsg.message);
          // console.log("response from update user---",response)
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.data;
        setServiceSection(data);
      } catch (error) {
        // Handle fetch error
        console.error("Error during fetch:", error);
      }
    };
    fetchData(); // Call the async function directly
    // Note: You may want to add a cleanup function here if needed
  }, [profileId, Key_Key]); // Include dependencies if needed

  const handleSubmit = async (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  //   ------------------------------------------ setting image in the input--------------------------------
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImage(e.target.files[0]);
    }
  };

  const UpdateOrgStruItem = async (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      const formData = new FormData();
      formData.append("aboutText", aboutText);
      formData.append("name", name);
      formData.append("role", role);
      formData.append("modifiedBy", profileName);
      formData.append("department", department);
      if (image) {
        formData.append("image", image);
      }
      try {
        // const response = await fetch(
        //   `http://165.22.219.69:5002/api/update/user/${profileId}/editor/${itemId}/${Key_Key}`,
        //   // `http://165.22.219.69:5002/api/update/user/${profileId}/editor/${itemId}`,
        //   {
        //     method: "PATCH",
        //     body: formData,
        //     credentials: "include",
        //   }
        // );
        // const response = await api.updatePatchData(
        //   `/api/update/user/${profileId}/editor/${itemId}/${Key_Key}`,
        //   formData
        // );
        const response = await api.updatePatchData(
          `/api/update/user/${profileId}/employee/${itemId}/${Key_Key}`,
          formData
        );

        // console.log(response.status, "response.status");

        if (response.status === 440) {
          alert("Session Expired");
          dispatch(logoutUser());
          window.location.href = ADMIN_LOGIN_BASE_URL; // Use the full URL, including the protocol (http or https)
        }

        if (response.status === 429) {
          // console.log("response---6566656", response)
          setShowModal(true);
        }
        // console.log("response--statue", response)

        if (response.status === 400) {
          setIsError(true);
          let errorMessage = await response.json();
          setIsResponse(errorMessage.message);
        }

        if (!response.status == true) {
          // console.log("response--", await response.json())
          let errorMsg = await response.json();
          setIsResponse(errorMsg.message);
          // console.log("response from update user---",response)
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Assuming you want to parse the JSON response

        if (response.status == 200 || response.status == 201) {
          alert("Data updated");
          window.location.reload();
        }
      } catch (error: any) {
        // setIsError(true);
        // setIsResponse(error.message)
        console.error("Error during edit the banner:", error);
      }
    }
    setValidated(true);
  };
  let Role = user.hobbies ? user.hobbies : user.user.hobbies;

  let Departmentforfilter = user.department
    ? user.department
    : user.user.department;

  const Cancle = () => {
    window.location.reload();
  };
  return (
    <>
      <Card>
        <Card.Body>
          {(innerdata || [])
            ?.filter((item) => {
              // console.log("from the line 248--", item._ === itemId);
              return item._id === itemId;
            })
            ?.map((filterItem, index) => (
              <Form
                style={{ width: "100%" }}
                onSubmit={UpdateOrgStruItem}
                encType="multipart/form-data"
                key={index}
              >
                <h4>Update User</h4>
                {/* <> */}
                {isError && (
                  <Alert variant="danger" className="my-2">
                    {isResponse}
                  </Alert>
                )}
                {showModal && <AuthModal />}
                <Form.Group>
                  <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                    <h5>Name</h5>
                  </Form.Label>
                  <Form.Control
                    className="accordion-item"
                    type="text"
                    placeholder={filterItem?.name}
                    defaultValue={filterItem?.name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>

                {Role == "Chess" && (
                  <Form.Group>
                    <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                      <h5>Department</h5>
                    </Form.Label>
                    <Form.Control
                      className="accordion-item"
                      type="text"
                      placeholder={filterItem?.department}
                      defaultValue={filterItem?.department}
                      onChange={(e) => setDepartment(e.target.value)}
                    />
                  </Form.Group>
                )}

                <Form.Group
                  className="mb-3 mt-2"
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setRole(e.target.value)
                  }
                >
                  <Form.Select>
                    <option disabled selected>
                      {filterItem?.role}
                    </option>
                    {Role === "Chess" ? (
                      <>
                        <option value="Super Admin">Super Admin</option>
                        <option value="Admin">Admin</option>
                      </>
                    ) : (
                      ""
                    )}

                    {Role === "Music" ? <option value="User">User</option> : ""}

                    {/* <option value='Administrator'>Administrator</option> */}
                    {/* <option value='Author'>Author</option> */}
                    {/* <option value='Contributor'>Contributor</option> */}
                    {/* <option value='Subscriber'>Subscriber</option> */}
                  </Form.Select>
                </Form.Group>

                <Form.Group>
                  <Form.Label className="d-flex pt-1 justify-content-start">
                    <h5>Profile Image</h5>
                  </Form.Label>
                  <Form.Control
                    type="file"
                    id="image"
                    name="mobileBannerUrl"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </Form.Group>

                <Row className="mt-2">
                  <Col lg={5}>
                    <Button type="submit">Update</Button>
                  </Col>

                  <Col lg={5}>
                    <button
                      onClick={() => Cancle()}
                      className="btn btn-primary"
                    >
                      Cancle
                    </button>
                  </Col>
                </Row>

                <Form.Group className="pt-5 pb-5"></Form.Group>
              </Form>
            ))}
        </Card.Body>
      </Card>
    </>
  );
};
// _________________________________________________________

// const DeleteOriStruItem = ({id}) =>
const DeleteEmployee: React.FC<ParentId> = ({ id }) => {
  const [validated, setValidated] = useState<boolean>(false);
  const [innerdata, setInnerData] = useState<OrgtnalSruItem[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [isEditItem, setIsEditItem] = useState<string>("");
  const [isResponse, setIsResponse] = useState("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showForAprov, setShowForAprov] = useState<boolean>(false);
  const [showForAprovItemId, setShowForAprovItemId] = useState<string>("");

  const dispatch = useDispatch<AppDispatch>();
  const [data, setData] = useState<OrgtnalStru[]>([]);

  const { user, userLoggedIn, loading, error, userId } = useSelector(
    (state: RootState) => ({
      user: state.Auth.user,
      loading: state.Auth.loading,
      error: state.Auth.error,
      userLoggedIn: state.Auth.userLoggedIn,
      userId: state.Auth.id,
    })
  );
  let profileId = user._id ? user._id : user.user ? user.user._id : "";
  const Key_Key = user.moniterd_ttl
    ? user.moniterd_ttl
    : user.user
    ? user.user.moniterd_ttl
    : "";
  let Departmentforfilter = user.department
    ? user.department
    : user.user.department;
  // --delete org str item
  const deleteItem = async (itemId: string) => {
    // console.log(user, "user.token");
    try {
      // const response = await fetch(
      //   `http://165.22.219.69:5002/api/delete/user/${profileId}/employee/${itemId}/${Key_Key}`,
      //   // `http://165.22.219.69:5002/api/delete/user/${profileId}/employee/${itemId}`,

      //   {
      //     method: "PATCH",
      //     // headers: {
      //     //   token: `${user?.token}`, // Add the token to the Authorization header
      //     // },
      //     credentials: "include",
      //   }
      // );
      const response = await api.updatePatchDataDelete(
        `/api/delete/user/${profileId}/employee/${itemId}/${Key_Key}`,
        ""
      );
      if (response.status === 440) {
        alert("Session Expired");
        dispatch(logoutUser());
        window.location.href = ADMIN_LOGIN_BASE_URL; // Use the full URL, including the protocol (http or https)
      }

      if (!response.status == true) {
        // console.log("response--", await response.json())
        let errorMsg = await response.json();
        setIsResponse(errorMsg.message);
        // console.log("response from update user---",response)
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      // Assuming you want to parse the JSON response

      if (
        response.status == 200 ||
        response.status == 201 ||
        response.status == 204
      ) {
        alert("Deleted");
        window.location.reload();
      }
    } catch (error) {
      // setIsResponse("Try after Some time")
      setShowModal(true);
      console.error("Error during delete the employee:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await fetch(
        //   `http://165.22.219.69:5002/user/${profileId}/${Key_Key}`,
        //   {
        //     method: 'GET',
        //     credentials: 'include',
        //   }
        // );
        let admin =
          Role == "Chess"
            ? `/user/${profileId}/${Key_Key}`
            : `/user/${profileId}/${Key_Key}/${Departmentforfilter}`;
        const response = await api.fetchDataAuth(
          // `/user/${profileId}/${Key_Key}/${Departmentforfilter}`
          `${admin}`
        );
        // const response = await api.fetchDataAuth(
        //   `/user/${profileId}/${Key_Key}`
        // );
        if (response.status === 440) {
          alert("Session Expired");
          dispatch(logoutUser());
          window.location.href = ADMIN_LOGIN_BASE_URL; // Use the full URL, including the protocol (http or https)
        }
        if (!response.status == true) {
          // console.log("response--", await response.json())
          let errorMsg = await response.json();
          setIsResponse(errorMsg.message);
          // console.log("response from update user---",response)
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const res = await response.data;
        setData(res);
      } catch (error) {
        // Handle fetch error
        console.error("Error during fetch:", error);
      }
    };

    fetchData(); // Call the async function directly

    // Note: You may want to add a cleanup function here if needed
  }, [profileId, Key_Key]); // Include dependencies if needed

  const editItem = async (id: string) => {
    let newEditItem = data.find((elem) => {
      return elem._id === id;
    });
    setIsEditItem(id);
  };
  let Role = user.hobbies ? user.hobbies : user.user.hobbies;

  const ViewData = async (id: string) => {
    let newEditItem = data.find((elem) => {
      return elem._id === id;
    });
    setIsEditItem(id);
    setShowForAprov(!showForAprov);
    // setShowForAprovItemId(!showForAprovItemId)
  };

  const closeViewData = () => {
    setShowForAprov(false);
    setIsEditItem("");
  };

  // console.log(data, "data----");

  // console.log('role', Role)
  return (
    // <h1>hi</h1>
    <>
      {showForAprov == false ? (
        <div className="input-group w-50 mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            placeholder="Search..."
            style={{
              borderRadius: ".5rem",
              border: "1px solid red",
              // borderTop: '2px solid red',
              // borderBottom: '2px solid blue',
            }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      ) : (
        ""
      )}

      {/*   */}
      {showForAprov ? (
        <Button className="mt-3 mb-3" onClick={() => closeViewData()}>
          Close
        </Button>
      ) : (
        ""
      )}

      {isEditItem && !showForAprov ? (
        <Row>
          <Col lg={10}>
            {data && (
              <UpdateEmployee
                itemId={isEditItem}
                parentId={id}
                innerdata={data}
              />
            )}
          </Col>
        </Row>
      ) : (
        <>
          {showForAprov == true ? (
            <ViewModal itemId={isEditItem} parentId={id} innerdata={data} />
          ) : (
            <Card>
              <Card.Body>
                <h4>Update or delete User</h4>
                {isResponse && (
                  <Alert variant="danger" className="my-2">
                    {isResponse}
                  </Alert>
                )}
                <div className="table-responsive">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Sr. N</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Punching ID</th>
                        {Role === "Chess" ? <th>Department</th> : ""}
                        {Role === "Chess" || Role === "Music" ? (
                          <th>Action</th>
                        ) : (
                          ""
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {data?.length >= 1
                        ? data
                            ?.filter(
                              (item) =>
                                // Filter the data array based on the search query
                                item &&
                                (item.name
                                  ?.toLowerCase()
                                  .includes(searchQuery.toLowerCase()) ||
                                  item.email
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.department
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ||
                                  item.punchingid
                                    ?.toLowerCase()
                                    .includes(searchQuery.toLowerCase()))
                            )
                            ?.map((item, i) => (
                              <tr key={item?._id}>
                                <td>
                                  {Role == "Chess" &&
                                  item?.superDuperAdminApprovalStatus ==
                                    "Pending" ? (
                                    <Image
                                      src={img}
                                      width={40}
                                      height={8}
                                      alt="Picture of the marquee"
                                      className="pr-1"
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {i + 1}
                                </td>{" "}
                                {/* You can use i+1 as the index */}
                                <td>{item?.name}</td>
                                <td>{item?.email}</td>
                                <td>{item?.role}</td>
                                <td>{item?.punchingid}</td>
                                {Role === "Chess" ? (
                                  <td>{item?.department}</td>
                                ) : (
                                  ""
                                )}
                                <td>
                                  {(Role && Role === "Chess") ||
                                  Role === "Music" ? (
                                    <Row className="gap-3">
                                      <Col lg={2}>
                                        <button
                                          onClick={() => deleteItem(item?._id)}
                                          className="btn btn-danger"
                                        >
                                          Delete
                                        </button>
                                      </Col>
                                      <Col lg={2}>
                                        <button
                                          onClick={() => editItem(item?._id)}
                                          className="btn btn-primary"
                                        >
                                          Update
                                          {/* <Edit /> */}
                                        </button>
                                      </Col>
                                      <Col lg={2}>
                                        <button
                                          onClick={() => ViewData(item._id)}
                                          className="btn btn-primary"
                                        >
                                          View
                                          {/* <Eye /> */}
                                        </button>
                                      </Col>
                                    </Row>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            ))
                        : ""}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          )}
        </>
      )}
    </>
  );
};

// -------------------------------add org item----------------

// _______________________________________________________________

const Employees = () => {
  const { user, userLoggedIn, loading, error, _id } = useSelector(
    (state: RootState) => ({
      user: state.Auth.user,
      loading: state.Auth.loading,
      error: state.Auth.error,
      userLoggedIn: state.Auth.userLoggedIn,
      _id: state.Auth._id,
    })
  );
  let profileId = user._id ? user._id : user.user ? user.user._id : "";
  const Key_Key = user.moniterd_ttl
    ? user.moniterd_ttl
    : user.user
    ? user.user.moniterd_ttl
    : "";
  let Departmentforfilter = user.department
    ? user.department
    : user.user.department;
  let Role = user.hobbies ? user.hobbies : user.user.hobbies;
  let profileName = user.name ? user.name : user.user.name;

  // const user = useSelector((state)=> state.user)
  const [data, setData] = useState<userData[]>([]);
  const [email, setEmail] = useState("");
  const [punchingid, setPunchingID] = useState("");
  const [name, setName] = useState("");
  const [mob, setMob] = useState("");

  const [password, setPassword] = useState("");
  const [designation, setDesignation] = useState("");
  const [image, setImage] = useState<File | null>(null);
  const [role, setRole] = useState(Role === "Chess" ? "" : "User");
  const [department, setDepartment] = useState(
    Role === "Chess" ? "" : Departmentforfilter
  );

  // const [department, setDepartment] = useState("");
  // if (Role == 'Music') {
  //   setDepartment(Departmentforfilter)
  // }
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isResponse, setIsResponse] = useState("");
  const dispatch = useDispatch<AppDispatch>();

  const allOptions = [
    "Anaesthesiology",
    "Anatomy",
    "Biochemistry",
    "Burns & Plastic",
    "C & FM",
    "Cardiology",
    "CFM",
    "CTVS",
    "Dermatology",
    "ENT",
    "FMT",
    "Gastroenterology",
    "General Medicin",
    "General Surgery",
    "Medical Education",
    "Microbiolgy",
    "Neurology",
    "Neurosurgery",
    "OBG",
    "Ophthalmology",
    "Orthopaedics",
    "Paediatrics",
    "Pathology",
    "Pediatric Surgery",
    "Pharmacology",
    "Physiology",
    "PMR",
    "Psychiatry",
    "Pulmonary Medicine",
    "Radio-diagnosis",
    "Radiotherapy",
    "Urology",
  ];

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(); // append every thing one by one inside this form data
    formData.append("email", email);
    formData.append("punchingid", punchingid);
    formData.append("name", name);
    formData.append("password", password);
    formData.append("role", role);
    formData.append("mob", mob);
    formData.append("createdBy", profileName);
    formData.append("department", department);
    formData.append("designation", designation);

    if (image) {
      formData.append("image", image);
    }
    try {
      const response = await api.createData(
        `signup/${profileId}/${Key_Key}`,
        formData
      );
      if (response.status === 440) {
        alert("Session Expired");
        dispatch(logoutUser());
        window.location.href = ADMIN_LOGIN_BASE_URL; // Use the full URL, including the protocol (http or https)
      }
      if (response.status === 400) {
        setIsError(true);
        let errorMessage = await response.message;
        setIsResponse(errorMessage);
      }
      if (!response.status == true) {
        // console.log("response--", await response.json())
        let errorMsg = await response.json();
        setIsResponse(errorMsg.message);
        // console.log("response from update user---",response)
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      if (response.status == 200 || response.status == 201) {
        alert("Added");
        window.location.reload();
      }
    } catch (error: any) {
      // setIsResponse(error)
      // setShowModal(true)
      console.error("Error during add employee:", error);
    }
  };
  // console.log(user, "USER", profileId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await fetch(
        //   `http://165.22.219.69:5002/user/${profileId}/${Key_Key}`,
        //   {
        //     method: 'GET',
        //     credentials: 'include',
        //   }
        // );

        let admin =
          Role == "Chess"
            ? `/user/${profileId}/${Key_Key}`
            : `/user/${profileId}/${Key_Key}/${Departmentforfilter}`;
        const response = await api.fetchDataAuth(
          // `/user/${profileId}/${Key_Key}/${Departmentforfilter}`
          `${admin}`
        );
        // console.log(response, "response");

        if (response.status === 440) {
          alert("Session Expired");
          dispatch(logoutUser());
          window.location.href = ADMIN_LOGIN_BASE_URL; // Use the full URL, including the protocol (http or https)
        }
        if (response.status === 400) {
          setIsError(true);
          let errorMessage = await response.message;
          setIsResponse(errorMessage);
        }

        if (!response.status == true) {
          // console.log("response--", await response.json())
          let errorMsg = await response.json();
          setIsResponse(errorMsg.message);
          // console.log("response from update user---",response)
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const res = response.data;
        // console.log(res, "res");
        setData(res);
      } catch (error) {
        // Handle fetch error
        console.error("Error during fetch:", error);
      }
    };

    fetchData(); // Call the async function directly

    // Note: You may want to add a cleanup function here if needed
  }, [profileId, Key_Key]); // Include dependencies if needed

  //   ------------------------------------------ setting image in the input--------------------------------
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImage(e.target.files[0]);
    }
  };

  /*----------------  handle update----------------*/
  // console.log(data, "DATAAAAA");

  return (
    <>
      <div>
        <Card>
          <Card.Body>
            {(Role && Role === "Chess") || Role === "Music" ? (
              <Row>
                <Col lg={8}>
                  <Form style={{ width: "100%" }} onSubmit={handleSubmit}>
                    {Role === "Chess" ? (
                      <h3>Create User</h3>
                    ) : (
                      <h3>
                        Create User in{" "}
                        <span style={{ color: "red" }}>
                          {Departmentforfilter}
                        </span>{" "}
                        Department
                      </h3>
                    )}
                    {isError && (
                      <Alert variant="danger" className="my-2">
                        {isResponse}
                      </Alert>
                    )}
                    {showModal && <AuthModal />}
                    <Form.Group>
                      <Form.Label className="d-flex  pt-2 justify-content-start font-weight-bold">
                        <h5>
                          Name <span style={{ color: "red" }}> *</span>
                        </h5>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        value={name}
                        required
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Form.Group>

                    {Role == "Chess" && (
                      <Form.Group
                        className="mb-3 mt-2"
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                          setDepartment(e.target.value)
                        }
                      >
                        <Form.Label>Select Department</Form.Label>
                        <Form.Select defaultValue="">
                          <option value="" disabled>
                            {" "}
                            Select Department
                          </option>

                          {allOptions.map((option, index) => (
                            <option key={index} value={option}>
                              {option}
                            </option>
                          ))}
                        </Form.Select>

                        {/* <Form.Control
                          type="text"
                          placeholder="Department"
                          value={department}
                          required
                          onChange={(e) => setDepartment(e.target.value)}
                        /> */}
                      </Form.Group>
                    )}

                    <Form.Group
                      className="mb-3 mt-2"
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                        setDesignation(e.target.value)
                      }
                    >
                      <Form.Label>Select Designation</Form.Label>
                      <Form.Select defaultValue="">
                        <option value="" disabled>
                          {" "}
                          Select Designation
                        </option>
                        <option value="Executive Director & CEO">
                          Executive Director & CEO
                        </option>
                        <option value="Dean (Academics)">
                          Dean (Academics)
                        </option>
                        <option value="Dean (Examinations)">
                          Dean (Examinations)
                        </option>
                        <option value="Dean (Research)">Dean (Research)</option>
                        <option value="Medical Superindent">
                          Medical Superindent
                        </option>
                        <option value="Dy. Director (Admn.)">
                          Dy. Director (Admn.)
                        </option>
                        <option value="Registrar (Academics)">
                          Registrar (Academics)
                        </option>
                        <option value="Principal, College of Nursing">
                          Principal, College of Nursing
                        </option>
                        <option value="Professor">Professor</option>
                        <option value="Additional Professor">
                          Additional Professor
                        </option>
                        <option value="Associate Professor">
                          Associate Professor
                        </option>
                        <option value="Assistant Professor ">
                          Assistant Professor{" "}
                        </option>
                        <option value="Lecturer in Nursing (Assistant Professor)">
                          Lecturer in Nursing (Assistant Professor)
                        </option>
                        <option value="Tutor/Clinical Demonstrator">
                          Tutor/Clinical Demonstrator
                        </option>
                        <option value="OA (NS)">OA (NS)</option>
                        <option value="DEO (Gr. A)">DEO (Gr. A)</option>
                        <option value="SAA">SAA</option>
                        <option value="Stenographer">Stenographer</option>
                        <option value="SKC">SKC</option>
                        <option value="JAA">JAA</option>
                        {/* <option value="Demonstrator">Demonstrator</option> */}
                      </Form.Select>
                    </Form.Group>

                    {/* <Form.Group>
                      <Form.Label className="d-flex  pt-2 justify-content-start font-weight-bold">
                        <h5>
                          Designation <span style={{ color: "red" }}> *</span>
                        </h5>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        value={designation}
                        required
                        onChange={(e) => setDesignation(e.target.value)}
                      />
                    </Form.Group> */}

                    <Form.Group>
                      <Form.Label className="d-flex  pt-2 justify-content-start font-weight-bold">
                        <h5>
                          Punching ID <span style={{ color: "red" }}> *</span>
                        </h5>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Punching ID"
                        value={punchingid}
                        required
                        onChange={(e) => setPunchingID(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="d-flex pt-2 justify-content-start">
                        <h5>
                          Email address <span style={{ color: "red" }}> *</span>
                        </h5>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter Email"
                        value={email}
                        autoComplete="off" // Use camelCase here
                        required
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="d-flex pt-2 justify-content-start font-weight-bold">
                        <h5>
                          Mobile Number <span style={{ color: "red" }}> *</span>
                        </h5>
                      </Form.Label>
                      <Form.Control
                        type="tel"
                        placeholder="Enter Mobile Number"
                        value={mob}
                        pattern="[0-9]*" // Only allow numbers - This line should be inside curly braces if you want it to be treated as a comment.
                        title="Please enter only digits"
                        required
                        onChange={(e) => setMob(e.target.value)}
                      />
                    </Form.Group>

                    {/* Remove for temporary  */}

                    {Role == "Chess" && (
                      <Form.Group>
                        <Form.Label className="d-flex pt-2 justify-content-start">
                          <h5>
                            Enter Password{" "}
                            <span style={{ color: "red" }}> *</span>
                          </h5>
                        </Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Enter password"
                          value={password}
                          autoComplete="off" // Use camelCase here
                          required
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </Form.Group>
                    )}

                    {/* <Form.Group>
                      <Form.Label className="d-flex pt-2 justify-content-start">
                        <h5>
                          Image <span style={{ color: "red" }}> *</span>
                        </h5>
                      </Form.Label>
                      <Form.Control
                        type="file"
                        required
                        id="image"
                        name="image"
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                    </Form.Group> */}

                    {Role == "Chess" && (
                      <Form.Group
                        className="mb-3 mt-2"
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                          setRole(e.target.value)
                        }
                      >
                        <Form.Label>
                          Select Role <span style={{ color: "red" }}>*</span>
                        </Form.Label>

                        <Form.Select required defaultValue="">
                          <option value="" disabled>
                            {" "}
                            Select Role
                          </option>
                          {Role === "Chess" ? (
                            <>
                              <option value="Super Admin">Super Admin</option>
                              <option value="Admin">Admin</option>
                              <option value="User">User</option>
                            </>
                          ) : (
                            ""
                          )}
                          {/* <option value='Administrator'>Administrator</option> */}
                          {/* <option value='Author'>Author</option> */}
                          {/* <option value='Contributor'>Contributor</option> */}
                          {/* <option value='Subscriber'>Subscriber</option> */}
                        </Form.Select>
                      </Form.Group>
                    )}
                    <Form.Group className="pt-2 pb-2">
                      <Button type="submit">Create User +</Button>
                      {/* <Button type='submit' className='mt-5'><Link style={{textAlign:"right", display:"bold", textDecoder:"none"}} className='text-light' to="/login">Add user</Link></Button> */}
                    </Form.Group>
                  </Form>
                </Col>

                {/* -------------------------- update data--------------------------------------------- */}
              </Row>
            ) : (
              ""
            )}

            <div
              style={{ width: "100%", height: "1px", backgroundColor: "red" }}
            ></div>

            {(Role && Role === "Chess") || Role === "Music" ? (
              <Row>
                <Col lg={12}>{user && <DeleteEmployee id={user.id} />}</Col>
              </Row>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h3>You have no access to create a new User</h3>
              </div>
            )}
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default Employees;
