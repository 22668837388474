import React from "react";
import { Row, Col, Card } from "react-bootstrap";

// components
import PageTitle from "../../components/PageTitle";
import Table from "../../components/Table";
import SessionSelector from "../apps/FileManager/SessionSelector";

//dummy data
//import { records as data, expandableRecords } from "./data";
import { records as data } from "./data";
import { render } from "react-dom";

const columns = [
  {
    Header: "S.No.",
    accessor: "id",
    sort: true,
  },
  {
    Header: "Enrollment No",
    accessor: "enrollment_no" as const,
    sort: true,
  },
  {
    Header: "Roll No",
    accessor: "roll_number",
    sort: true,
  },
  {
    Header: "Educational Details",
    accessor: "secondStepStatus",
    Cell: ({ value }: { value: any }) => (
      <span>{value ? "Completed" : "Incomplete"}</span>
    ),
  },
  // {
  //   Header: "Session",
  //   accessor: "session" as const,
  //   sort: true,
  // },
  {
    Header: "Name",
    accessor: "name" as const,
    sort: true,
  },
  {
    Header: "Details",
    accessor: "a",
    Cell: () => (
      <a href={`/details/1`}>
        <i className="uil uil-eye"></i>
      </a>
    ),
    sort: false,
  },
  {
    Header: "Action",
    accessor: "b",
    Cell: () => (
      <a href={`/edit/2`}>
        <i className="uil uil-edit"></i>
      </a>
    ),
    sort: false,
  },
];

const sizePerPageList = [
  {
    text: "10",
    value: 10,
  },
  {
    text: "25",
    value: 25,
  },
  {
    text: "50",
    value: 50,
  },
  {
    text: "All",
    value: data.length,
  },
];

const NursingStudentList = () => {
  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Nursing", path: "/pages/mbbs-student-list" },
          {
            label: "Nursing Student List",
            path: "/pages/nursing-student-list",
            active: true,
          },
        ]}
        title={"Nursing Student List"}
      />

      <Row>
        <Col>
            <div className="inbox-rightbar h-100 pb-0">
              <SessionSelector />
            </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Table
                columns={columns}
                data={data}
                pageSize={5}
                sizePerPageList={sizePerPageList}
                isSortable={true}
                pagination={true}
                isSearchable={true}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default NursingStudentList;
