interface Records {
  id: number;
  course_name: string;
  form_no: string;
  enrollment_no: string;
  session: string;
  admission_date: Date;
  roll_number: number;
  rank: number;
  name: string;
  dob: Date;
  category: string;
  category_selected: string;
  gender: string;
  nationality: string;
  religion: string;
  student_mobile: string;
  email: string;
  aiims_email: string;
  identification_mark: string;
  std_bloodgrp: string;
  father_name: string;
  mother_name: string;
  student_image: string;
  sign_image: string;
  admission_letter: string;
  corr_stateName: string;
  corr_districtName: string;
  corr_town: string;
  corr_police_station: string;
  corr_post_office: string;
  corr_street_address: string;
  corr_pin: number;
  corr_landline: string;
  corr_mobile: string;
  corr_email: string;
  perm_stateName: string;
  perm_districtName: string;
  perm_town: string;
  perm_police_station: string;
  perm_post_office: string;
  perm_street_address: string;
  perm_pin: number;
  perm_landline: string;
  perm_mobile: string;
  perm_email: string;
  gardi_name: string;
  gard_mobile: string;
  guardian_email: string;
  relation: string;
  gardian_address: string;
  school_name: string;
  school_state: string;
  school_city: string;
  school_board: string;
  pass_year: string;
  school_marks: number;
  school_cgpa: number;
  school_division: string;
  schoolname_ten_plus_two: string;
  state_Name_ten_plus_two: string;
  city_ten_plus_two: string;
  board_ten_plus_two: string;
  passyear_ten_plus_two: string;
  marks_ten_plus_two: number;
  cgpa_ten_plus_two: number;
  division_ten_plus_two: string;
  MedicalRegistration: string;
  RegisrationState: string;
  PGRegistration: string;
  update_date: Date;
  updated_by: string;
  secondStepStatus: boolean;
}

export const records: Records[] = [
  {
    id: 28,
    course_name: "01",
    form_no: "",
    enrollment_no: "M2012/028",
    session: "2012",
    admission_date: new Date(""),
    roll_number: 12028,
    rank: 0,
    name: "MUKUL CHOUBISA",
    dob: new Date("1992-03-16"),
    category: "",
    category_selected: "",
    gender: "Male",
    nationality: "Indian",
    religion: "",
    student_mobile: "9661517771",
    email: "MUKULCHOUBISA@GMAIL.COM",
    aiims_email: "",
    identification_mark: "",
    std_bloodgrp: "B+",
    father_name: "SATYANARAYAN CHOUBISA",
    mother_name: "",
    student_image: "",
    sign_image: "",
    admission_letter: "",
    corr_stateName: "",
    corr_districtName: "",
    corr_town: "",
    corr_police_station: "",
    corr_post_office: "",
    corr_street_address: "",
    corr_pin: 0,
    corr_landline: "",
    corr_mobile: "",
    corr_email: "",
    perm_stateName: "",
    perm_districtName: "",
    perm_town: "",
    perm_police_station: "",
    perm_post_office: "",
    perm_street_address: "139,SECTOR 08, HIRAN MAGRI, UDAIPUR",
    perm_pin: 0,
    perm_landline: "0",
    perm_mobile: "",
    perm_email: "",
    gardi_name: "",
    gard_mobile: "",
    guardian_email: "",
    relation: "",
    gardian_address: "",
    school_name: "",
    school_state: "",
    school_city: "",
    school_board: "",
    pass_year: "",
    school_marks: 0,
    school_cgpa: 0,
    school_division: "",
    schoolname_ten_plus_two: "",
    state_Name_ten_plus_two: "",
    city_ten_plus_two: "",
    board_ten_plus_two: "",
    passyear_ten_plus_two: "",
    marks_ten_plus_two: 0,
    cgpa_ten_plus_two: 0,
    division_ten_plus_two: "",
    MedicalRegistration: "",
    RegisrationState: "",
    PGRegistration: "",
    update_date: new Date("0000-00-00"),
    updated_by: "",
    secondStepStatus: true,
  },
  {
    id: 29,
    course_name: "01",
    form_no: "",
    enrollment_no: "M2012/029",
    session: "2012",
    admission_date: new Date(""),
    roll_number: 12029,
    rank: 0,
    name: "SURAJ KUMAR",
    dob: new Date("1992-03-16"),
    category: "",
    category_selected: "",
    gender: "Male",
    nationality: "Indian",
    religion: "",
    student_mobile: "9661517771",
    email: "MUKULCHOUBISA@GMAIL.COM",
    aiims_email: "",
    identification_mark: "",
    std_bloodgrp: "B+",
    father_name: "SATYANARAYAN CHOUBISA",
    mother_name: "",
    student_image: "",
    sign_image: "",
    admission_letter: "",
    corr_stateName: "",
    corr_districtName: "",
    corr_town: "",
    corr_police_station: "",
    corr_post_office: "",
    corr_street_address: "",
    corr_pin: 0,
    corr_landline: "",
    corr_mobile: "",
    corr_email: "",
    perm_stateName: "",
    perm_districtName: "",
    perm_town: "",
    perm_police_station: "",
    perm_post_office: "",
    perm_street_address: "139,SECTOR 08, HIRAN MAGRI, UDAIPUR",
    perm_pin: 0,
    perm_landline: "0",
    perm_mobile: "",
    perm_email: "",
    gardi_name: "",
    gard_mobile: "",
    guardian_email: "",
    relation: "",
    gardian_address: "",
    school_name: "",
    school_state: "",
    school_city: "",
    school_board: "",
    pass_year: "",
    school_marks: 0,
    school_cgpa: 0,
    school_division: "",
    schoolname_ten_plus_two: "",
    state_Name_ten_plus_two: "",
    city_ten_plus_two: "",
    board_ten_plus_two: "",
    passyear_ten_plus_two: "",
    marks_ten_plus_two: 0,
    cgpa_ten_plus_two: 0,
    division_ten_plus_two: "",
    MedicalRegistration: "",
    RegisrationState: "",
    PGRegistration: "",
    update_date: new Date("0000-00-00"),
    updated_by: "",
    secondStepStatus: false,
  },
  {
    id: 30,
    course_name: "01",
    form_no: "",
    enrollment_no: "M2012/030",
    session: "2012",
    admission_date: new Date(""),
    roll_number: 12030,
    rank: 0,
    name: "MANISH KUMAR",
    dob: new Date("1992-03-16"),
    category: "",
    category_selected: "",
    gender: "Male",
    nationality: "Indian",
    religion: "",
    student_mobile: "9661517771",
    email: "MUKULCHOUBISA@GMAIL.COM",
    aiims_email: "",
    identification_mark: "",
    std_bloodgrp: "B+",
    father_name: "SATYANARAYAN CHOUBISA",
    mother_name: "",
    student_image: "",
    sign_image: "",
    admission_letter: "",
    corr_stateName: "",
    corr_districtName: "",
    corr_town: "",
    corr_police_station: "",
    corr_post_office: "",
    corr_street_address: "",
    corr_pin: 0,
    corr_landline: "",
    corr_mobile: "",
    corr_email: "",
    perm_stateName: "",
    perm_districtName: "",
    perm_town: "",
    perm_police_station: "",
    perm_post_office: "",
    perm_street_address: "139,SECTOR 08, HIRAN MAGRI, UDAIPUR",
    perm_pin: 0,
    perm_landline: "0",
    perm_mobile: "",
    perm_email: "",
    gardi_name: "",
    gard_mobile: "",
    guardian_email: "",
    relation: "",
    gardian_address: "",
    school_name: "",
    school_state: "",
    school_city: "",
    school_board: "",
    pass_year: "",
    school_marks: 0,
    school_cgpa: 0,
    school_division: "",
    schoolname_ten_plus_two: "",
    state_Name_ten_plus_two: "",
    city_ten_plus_two: "",
    board_ten_plus_two: "",
    passyear_ten_plus_two: "",
    marks_ten_plus_two: 0,
    cgpa_ten_plus_two: 0,
    division_ten_plus_two: "",
    MedicalRegistration: "",
    RegisrationState: "",
    PGRegistration: "",
    update_date: new Date("0000-00-00"),
    updated_by: "",
    secondStepStatus: false,
  },
  {
    id: 31,
    course_name: "01",
    form_no: "",
    enrollment_no: "M2012/031",
    session: "2012",
    admission_date: new Date(""),
    roll_number: 12031,
    rank: 0,
    name: "SANTOSH SINGH",
    dob: new Date("1992-03-16"),
    category: "",
    category_selected: "",
    gender: "Male",
    nationality: "Indian",
    religion: "",
    student_mobile: "9661517771",
    email: "MUKULCHOUBISA@GMAIL.COM",
    aiims_email: "",
    identification_mark: "",
    std_bloodgrp: "B+",
    father_name: "SATYANARAYAN CHOUBISA",
    mother_name: "",
    student_image: "",
    sign_image: "",
    admission_letter: "",
    corr_stateName: "",
    corr_districtName: "",
    corr_town: "",
    corr_police_station: "",
    corr_post_office: "",
    corr_street_address: "",
    corr_pin: 0,
    corr_landline: "",
    corr_mobile: "",
    corr_email: "",
    perm_stateName: "",
    perm_districtName: "",
    perm_town: "",
    perm_police_station: "",
    perm_post_office: "",
    perm_street_address: "139,SECTOR 08, HIRAN MAGRI, UDAIPUR",
    perm_pin: 0,
    perm_landline: "0",
    perm_mobile: "",
    perm_email: "",
    gardi_name: "",
    gard_mobile: "",
    guardian_email: "",
    relation: "",
    gardian_address: "",
    school_name: "",
    school_state: "",
    school_city: "",
    school_board: "",
    pass_year: "",
    school_marks: 0,
    school_cgpa: 0,
    school_division: "",
    schoolname_ten_plus_two: "",
    state_Name_ten_plus_two: "",
    city_ten_plus_two: "",
    board_ten_plus_two: "",
    passyear_ten_plus_two: "",
    marks_ten_plus_two: 0,
    cgpa_ten_plus_two: 0,
    division_ten_plus_two: "",
    MedicalRegistration: "",
    RegisrationState: "",
    PGRegistration: "",
    update_date: new Date("0000-00-00"),
    updated_by: "",
    secondStepStatus: false,
  },
];
