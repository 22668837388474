import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Dropdown,
  ButtonGroup,
  Card,
  Form,
  Tooltip,
  OverlayTrigger,
  Button,
} from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import classNames from "classnames";
import Table from "../../components/Table";
import PageTitle from "../../components/PageTitle";
import api from "../../utils/ApiMethod";

interface UGStudentData {
  id: string;
  course_name: string;
  form_no: string | null;
  enrollment_no: string;
  session: string;
  admission_date: string;
  roll_number: string;
  rank: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  dob: string;
  category: string;
  category_selected: string;
  gender: string;
  nationality: string;
  religion: string;
  student_mobile: string;
  email: string;
  aiims_email: string;
  identification_mark: string;
  std_bloodgrp: string;
  father_name: string;
  mother_name: string;
  student_image: string | null;
  sign_image: string | null;
  admission_letter: string | null;
  corr_stateName: string;
  corr_districtName: string;
  corr_town: string;
  corr_police_station: string;
  corr_post_office: string;
  corr_street_address: string;
  corr_pin: string;
  corr_landline: string;
  corr_mobile: string;
  corr_email: string;
  perm_stateName: string;
  perm_districtName: string;
  perm_town: string;
  perm_police_station: string;
  perm_post_office: string;
  perm_street_address: string;
  perm_pin: string;
  perm_landline: string;
  perm_mobile: string;
  perm_email: string;
  gardi_fname: string;
  gard_lname: string;
  gard_mobile: string;
  guardian_email: string;
  relation: string;
  gardian_address: string;
  school_name: string;
  school_state: string;
  school_city: string;
  school_board: string;
  pass_year: string;
  school_marks: string;
  marks_obtained: string;
  schoolMarksInPercentage: string;
  school_cgpa: string;
  school_division: string;
  schoolname_ten_plus_two: string;
  state_Name_ten_plus_two: string;
  city_ten_plus_two: string;
  board_ten_plus_two: string;
  passyear_ten_plus_two: string;
  marks_ten_plus_two: string;
  marks_ten_plus_two_obtained: string;
  tenPlusTwoMarksInPercentage: string;
  cgpa_ten_plus_two: string;
  division_ten_plus_two: string;
  MedicalRegistration: string;
  RegisrationState: string;
  PGRegistration: string;
  isDetailsUpdated: string;
  status: string;
  rejectionReason: string;
  same_addr: boolean;
  //   approvedby: string | null;
  //   update_date: string | null;
  updated_by: string;
}

interface SessionInfo {
  courseType: string;
  sessionYear: string;
  status: string;
  _id?: string;
}

interface CourseInfo {
  courseType: string;
  courseName: string;
  courseCode: string;
  status: string;
  _id?: string;
}

interface ExamInfo {
  courseType: string;
  courseCode: string;
  examName: string;
  examCode: string;
  _id?: string;
}

interface SubjectInfo {
  courseType: string;
  courseCode: string;
  // examName: string;
  examCode: string;
  subjectName: string;
  subjectCode: string;
  paperType: string;
  _id?: string;
}

const PGAttendance = () => {
  const [ugStudents, setUGStudents] = useState<UGStudentData[]>([]);
  const [dateRange, setDateRange] = useState<any>([
    new Date(),
    new Date().setDate(new Date().getDate() + 7),
  ]);
  const [startDate, endDate] = dateRange;
  const [session, setSession] = useState([]);
  const [selectedSession, setSelectedSession] = useState<number | "">(""); // State to hold the selected year
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [isResponse, setIsResponse] = useState("");
  const [exams, setExams] = useState([]);
  // { id: "M4", name: "Final Professional MBBS" },
  // { id: "M1", name: "First Professional MBBS" },
  // { id: "M2", name: "Second Professional MBBS" },
  // { id: "M3I", name: "Third Professional Part-I MBBS" },
  // { id: "M3II", name: "Third Professional Part-II MBBS" },
  // { id: "N1", name: "First Professional B.Sc. (Hons.) Nursing" },
  // { id: "N2", name: "Second Professional B.Sc. (Hons.) Nursing" },
  // { id: "N3", name: "Third Professional B.Sc. (Hons.) Nursing" },
  // { id: "N4", name: "Fourth Professional B.Sc. (Hons.) Nursing" },

  const [selectedExam, setSelectedExam] = useState("");

  const [subjects, setSubjects] = useState([]);
  // { id: "CFM-I", name: "Community & Family Medicine-I" },
  // { id: "CFM-II", name: "Community & Family Medicine-II" },
  // { id: "CFM-P", name: "Community & Family Medicine-P" },
  // { id: "GM-I", name: "General Medicine-I" },
  // { id: "GM-II", name: "General Medicine-II" },
  // { id: "GM-P", name: "General Medicine-P" },
  // { id: "GS-I", name: "General Surgery-I" },
  // { id: "GS-II", name: "General Surgery-II" },
  // { id: "GS-P", name: "General Surgery-P" },
  // { id: "OG-o", name: "Obstetrics and Gynaecology-o" },
  // { id: "OG-P", name: "Obstetrics and Gynaecology-P" },
  // { id: "Ped-o", name: "Paediatrics-o" },
  // { id: "Ped-P", name: "Paediatrics-P" },
  // { id: "Anat-I", name: "Anatomy-I" },
  // { id: "Anat-II", name: "Anatomy-II" },
  // { id: "Anat-P", name: "Anatomy-P" },
  // { id: "Bioch-I", name: "Biochemistry-I" },
  // { id: "Bioch-II", name: "Biochemistry-II" },
  // { id: "Bioch-P", name: "Biochemistry-P" },
  // { id: "Phys-I", name: "Physiology-I" },
  // { id: "Phys-II", name: "Physiology-II" },
  // { id: "Phys-P", name: "Physiology-P" },
  // { id: "Micro-I", name: "Microbiology-I" },
  // { id: "Micro-II", name: "Microbiology-II" },
  // { id: "Micro-P", name: "Microbiology-P" },
  // { id: "Path-I", name: "Pathology-I" },
  // { id: "Path-II", name: "Pathology-II" },
  // { id: "Path-P", name: "Pathology-P" },
  // { id: "Pharm-I", name: "Pharmacology-I" },
  // { id: "Pharm-II", name: "Pharmacology-II" },
  // { id: "Pharm-P", name: "Pharmacology-P" },
  const [selectedSubjects, setSelectedSubjects] = useState("");

  /*
   * handle date change
   */
  const onDateChange = (date: Date) => {
    if (date) {
      setDateRange(date);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.fetchData(`/api/all/session`);

        if (response.status !== 200) {
          const errorMsg = await response.json();
          setIsResponse(errorMsg?.message || "Unknown error occurred.");
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // const data = await response.json(); // Assuming response.data is JSON data
        setSession(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsResponse("Error fetching courses. Please try again later.");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.fetchData(`/api/active/course`);

        if (response.status !== 200) {
          const errorMsg = await response.json();
          setIsResponse(errorMsg?.message || "Unknown error occurred.");
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // const data = await response.json(); // Assuming response.data is JSON data
        setCourses(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsResponse("Error fetching courses. Please try again later.");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.fetchData(`/api/active/exam`);

        if (response.status !== 200) {
          const errorMsg = await response.json();
          setIsResponse(errorMsg?.message || "Unknown error occurred.");
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // const data = await response.json(); // Assuming response.data is JSON data
        setExams(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsResponse("Error fetching examination. Please try again later.");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.fetchData(`/api/active/subject`);

        if (response.status !== 200) {
          const errorMsg = await response.json();
          setIsResponse(errorMsg?.message || "Unknown error occurred.");
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // const data = await response.json(); // Assuming response.data is JSON data
        setSubjects(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsResponse("Error fetching examination. Please try again later.");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.fetchData(`/api/all/UG/student`);

        if (response.status !== 200) {
          const errorMsg = await response.json();
          setIsResponse(errorMsg?.message || "Unknown error occurred.");
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // const data = await response.json(); // Assuming response.data is JSON data
        setUGStudents(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsResponse("Error fetching examination. Please try again later.");
      }
    };

    fetchData();
  }, []);

  // Function to handle year selection change
  const handleSessionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSession(parseInt(event.target.value));
  };

  const handleCourseChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCourse(e.target.value);
  };

  const handleExamChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedExam(e.target.value);
  };

  const handleSubjectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSubjects(e.target.value);
  };

  const columns = [
    {
      Header: "S.No.",
      accessor: (row: any, index: number) => index + 1,
      sort: true,
    },
    {
      Header: "Enrollment No.",
      accessor: "enrollment_no",
      sort: true,
    },
    {
      Header: "Name",
      accessor: "first_name",
      sort: true,
    },
    {
      Header: "Total No of Class (Theory)",
      accessor: "total_class_theory",
      sort: true,
    },
    {
      Header: "Total Class Attended(Theory)",
      accessor: "total_class_attended_theory",
      sort: false,
    },
    {
      Header: "Percentage(Theory)",
      accessor: "attended_theory_class_percentage",
      sort: false,
    },
    {
      Header: "Total No of Class (Practical/Clinical Posting)",
      accessor: "total_class_practical",
      sort: true,
    },
    {
      Header: "Total Class Attended(Practical/Clinical Posting)",
      accessor: "total_class_attended_practical",
      sort: false,
    },
    {
      Header: "Percentage(Practical/Clinical Posting)",
      accessor: "attended_practical_class_percentage",
      sort: false,
    },
    {
      Header: "Attendance",
      accessor: "notice_doc",
      Cell: ({ value }: { value: string }) => (
        <a
          href={`https://aiimspatna.edu.in/advertisement/${value}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Add attendance
        </a>
      ),
      sort: false,
    },
  ];
  const sizePerPageList = [
    {
      text: "5",
      value: 5,
    },
    {
      text: "10",
      value: 10,
    },
    {
      text: "25",
      value: 25,
    },
    {
      text: "All",
      value: ugStudents?.length,
    },
  ];

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "PG Attendance", path: "admin/attendance/Attendance" },
          {
            label: "PG Attendance",
            path: "admin/attendance/Attendance",
            active: true,
          },
        ]}
        title={"PG Attendance"}
      />

      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <Row className="py-1">
                <Col lg={6} className="mb-2 mb-lg-0">
                  <select
                    className="form-select"
                    value={selectedSession}
                    onChange={handleSessionChange}
                    aria-label="Session"
                  >
                    <option value="">Session</option>
                    {session?.map((session: SessionInfo) => (
                      <option
                        key={session.sessionYear}
                        value={session.sessionYear}
                      >
                        {session.sessionYear}
                      </option>
                    ))}
                  </select>
                </Col>

                <Col lg={6} className="mb-2 mb-lg-0">
                  <select
                    className="form-select"
                    value={selectedCourse}
                    onChange={handleCourseChange}
                    aria-label="Course"
                  >
                    <option value="">Course</option>
                    {courses?.map((course: CourseInfo) => (
                      <option key={course.courseCode} value={course.courseCode}>
                        {course.courseName}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>

              <Row className="py-1">
                <Col lg={6} className="mb-2 mb-lg-0">
                  <select
                    className="form-select"
                    value={selectedExam}
                    onChange={handleExamChange}
                    aria-label="Examination"
                  >
                    <option value="">Examination</option>
                    {exams?.map((exam: ExamInfo) => (
                      <option key={exam.examCode} value={exam.examCode}>
                        {exam.examName}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col lg={6} className="mb-2 mb-lg-0">
                  <select
                    className="form-select"
                    value={selectedSubjects}
                    onChange={handleSubjectChange}
                    aria-label="Subject"
                  >
                    <option value="">Subject</option>
                    {subjects?.map((subject: SubjectInfo) => (
                      <option
                        key={subject.subjectCode}
                        value={subject.subjectCode}
                      >
                        {subject.subjectName}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <Card>
            {/* <Dropdown align="end" className="float-end">
              <Dropdown.Toggle variant="light">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="viewoptions">Import attendance</Tooltip>
                  }
                >
                  <span className="text-success">
                    <i className="bi bi-file-earmark-excel"></i>
                  </span>
                </OverlayTrigger>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <span className="text-success">
                
                  <i className="bi bi-file-earmark-excel"></i>
                </span>
                <Dropdown.Item>Excel Export</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}

            <Card.Body>
              <div className="mb-3 pt-2">
                <div className="text-end">
                  <Button variant="success" className="me-1">
                    <i className="bi bi-file-earmark-excel"></i> Import
                  </Button>
                </div>
              </div>
              <Table
                columns={columns}
                data={ugStudents}
                pageSize={5}
                sizePerPageList={sizePerPageList}
                isSortable={true}
                pagination={true}
                isSearchable={true}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PGAttendance;
