import React, { useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";
// import { API_BASE_URL } from "../../../../apiservices/apiService";

import { API_BASE_URL } from "../../apiservices/apiService";

// import userAvatar from "../../../../assets/images/users/avatar-1.jpg";

import userAvatar from "../../assets/images/users/avatar-1.jpg";

import { AnyARecord } from "dns";
interface ResumeData {
  personalInfo: any;
  expertise: any;
  contactInfo: any;
  experience: any[];
  education: any[];
  honoursAndAwards: any[];
  doctoralTheses: any[];
  professionalMemberships: any[];
  committeeMemberships: any[];
  researchProjects: any[];
  patents: any[];
  publications: any[];
}

const DownloadResume: React.FC<{ resumeData: ResumeData }> = ({
  resumeData,
}) => {
  const [loader, setLoader] = useState(false);
  const [viewResume, setViewResume] = useState(false);
  const {
    personalInfo,
    expertise,
    contactInfo,
    experience,
    education,
    honoursAndAwards,
    doctoralTheses,
    professionalMemberships,
    committeeMemberships,
    researchProjects,
    patents,
    publications,
  } = resumeData;

  const downloadPDF = () => {
    const capture = document.querySelector(".resume");

    if (!capture) {
      console.error("Could not find .resume element");
      return;
    }

    setLoader(true);

    html2canvas(capture as HTMLElement).then((canvas) => {
      const imgData = canvas.toDataURL("img/png");
      const doc = new jsPDF("p", "mm", "a4");

      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();

      doc.setFontSize(16);

      doc.addImage(imgData, "PNG", 0, 0, componentWidth, componentHeight);
      setLoader(false);
      doc.save("mycv.pdf");
    });
  };

  // console.log("resumeData from download", resumeData);
  return (
    <div>
      <Col
        lg={12}
        className="d-flex justify-content-between align-items-center"
      >
        <h5 className="header-title mb-3 mt-0">Faculty Profile</h5>
        <Button
          className="mb-3 mt-0"
          variant="primary"
          onClick={() => setViewResume(!viewResume)}
        >
          {viewResume ? <span>Hide CV</span> : <span>View CV</span>}
        </Button>

        {/* {viewResume && viewResume ? (
          <Button
            className="mb-3 mt-0"
            variant="primary"
            onClick={downloadPDF}
            disabled={!(loader === false)}
          >
            <i className="bi-file-earmark-pdf me-1"></i>
            {loader ? <span>Downloading CV</span> : <span>Download CV</span>}
          </Button>
        ) : (
          ""
        )} */}
      </Col>

      {viewResume && viewResume ? (
        <Container className="resume" style={{ border: "2px solid black" }}>
          {/* Personal Information */}
          <Row>
            <Col
              lg={12}
              className="d-flex justify-content-between align-items-center"
            >
              {personalInfo?.image ? (
                <Image
                  src={`${API_BASE_URL}/${personalInfo?.image?.replace(
                    "uploads/images/",
                    "images/"
                  )}`}
                  alt="user-profile"
                  className="avatar-lg rounded-circle"
                  roundedCircle
                />
              ) : (
                <Image
                  src={userAvatar}
                  alt="user-profile"
                  className="avatar-lg rounded-circle"
                  roundedCircle
                />
              )}
              <div>
                <h1>{personalInfo?.name}</h1>
                <h4>Designation: {personalInfo?.designation}</h4>
              </div>
            </Col>
          </Row>
          <Row className="d-flex justify-content-between ">
            <Col lg={6}>
              {/* Expertise */}
              <Row>
                <Col>
                  <h2>Expertise</h2>
                  <p>{expertise?.expertise}</p>
                  <p>{expertise?.briefInfo}</p>
                </Col>
              </Row>

              {/* Contact Information */}
              <Row>
                <Col>
                  <h2>Contact Information</h2>
                  <p>Email: {contactInfo?.emailid}</p>
                  <p>Mobile: {contactInfo?.mobileNumber}</p>
                  <p>
                    Location: {contactInfo?.city}, {contactInfo?.state},{" "}
                    {contactInfo?.country}
                  </p>
                </Col>
              </Row>

              {/* Experience */}
              <Row>
                <Col>
                  <h2>Experience</h2>
                  {experience?.map((exp: any, index: any) => (
                    <div key={index}>
                      <h4>{exp?.organization}</h4>
                      <p>{exp?.designation}</p>
                      <p>
                        {exp?.startDate} - {exp?.endDate || "Present"}
                      </p>
                    </div>
                  ))}
                </Col>
              </Row>

              {/* Education */}
              <Row>
                <Col>
                  <h2>Education</h2>
                  {education?.map((edu: any, index: any) => (
                    <div key={index}>
                      <h4>{edu?.qualification}</h4>
                      <p>{edu?.institute}</p>
                      <p>{edu?.subject}</p>
                      <p>Year: {edu?.passingYear}</p>
                    </div>
                  ))}
                </Col>
              </Row>

              {/* Honors and Awards */}
              <Row>
                <Col>
                  <h2>Honors and Awards</h2>
                  {honoursAndAwards?.map((award: any, index: any) => (
                    <div key={index}>
                      <h4>{award?.awardName}</h4>
                      <p>Year: {award?.year}</p>
                      <p>Awarded By: {award?.awardedBy}</p>
                    </div>
                  ))}
                </Col>
              </Row>

              <Row>
                <Col>
                  <h2>Doctoral Theses</h2>
                  {doctoralTheses?.map((thesis: any, index: any) => (
                    <div key={index}>
                      <h4>{thesis?.title}</h4>
                      <p>Researcher: {thesis?.researcherName}</p>
                      <p>Institute: {thesis?.institute}</p>
                      <p>Year: {thesis?.awardedYear}</p>
                    </div>
                  ))}
                </Col>
              </Row>

              {/* Add other sections similarly */}
            </Col>

            <Col lg={6}>
              {/* Doctoral Theses */}

              {/* Professional Memberships */}
              <Row>
                <Col>
                  <h2>Professional Memberships</h2>
                  {professionalMemberships?.map(
                    (membership: any, index: any) => (
                      <div key={index}>
                        <h4>{membership?.bodyName}</h4>
                        <p>Member Type: {membership?.memberType}</p>
                        <p>Year: {membership?.awardedYear}</p>
                      </div>
                    )
                  )}
                </Col>
              </Row>

              {/* Committee Memberships */}
              <Row>
                <Col>
                  <h2>Committee Memberships</h2>
                  {committeeMemberships?.map((committee: any, index: any) => (
                    <div key={index}>
                      <h4>{committee?.committeeName}</h4>
                      <p>Role: {committee?.role}</p>
                      <p>Year: {committee?.year}</p>
                    </div>
                  ))}
                </Col>
              </Row>

              {/* Research Projects */}
              <Row>
                <Col>
                  <h2>Research Projects</h2>
                  {researchProjects?.map((project: any, index: any) => (
                    <div key={index}>
                      <h4>{project?.title}</h4>
                      <p>Start Date: {project?.startDate}</p>
                      <p>End Date: {project?.endDate || "Ongoing"}</p>
                    </div>
                  ))}
                </Col>
              </Row>

              {/* Patents */}
              <Row>
                <Col>
                  <h2>Patents</h2>
                  {patents?.map((patent: any, index: any) => (
                    <div key={index}>
                      <h4>{patent?.title}</h4>
                      <p>Application Number: {patent?.applicationNumber}</p>
                      <p>Publication Date: {patent?.publicationDate}</p>
                    </div>
                  ))}
                </Col>
              </Row>

              {/* Publications */}
              <Row>
                <Col>
                  <h2>Publications</h2>
                  {publications?.map((publication: any, index: any) => (
                    <div key={index}>
                      <h4>{publication?.title}</h4>
                      <p>Year: {publication?.year}</p>
                      <p>
                        Authors:{" "}
                        {publication?.authors
                          ?.map((author: any) => author?.firstName)
                          ?.join(", ")}
                      </p>
                    </div>
                  ))}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      ) : (
        ""
      )}
    </div>
  );
};

export default DownloadResume;
