// import { height } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Image, Row, Table } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import { logoutUser } from "../../redux/actions";
import AuthModal from "../../components/Modal";
import { Alert } from "react-bootstrap";
import api from "../../utils/ApiMethod";
import {
  ADMIN_LOGIN_BASE_URL,
  API_BASE_URL,
} from "../../apiservices/apiService";

interface OrgtnalSruItem {
  _id?: string;
  aboutText?: string;
  isDeleted?: boolean;
}

interface userData {
  name?: string;
  email?: string;
  password?: string;
  hobbies?: string;
  role?: string;
  isDeleted?: boolean;
  imageUrl?: string;
  _id?: string;
  title?: string;
  adminApprovalStatus?: string;
  department?: string;
  moderatorApprovalStatus?: string;
  superAdminApprovalStatus?: string;
  superDuperAdminApprovalStatus?: string;
  modifiedBy?: string;
  createdBy?: string;
  introduction?: string;
  content?: string;
  url?: string;
  summary?: string;
  futurePlan?: string;
  researchBy?: string;
  yearOfResearch?: string;
  date?: Date;
  dateTo?: Date;
  awards?: string;
  image?: string;
  imageOne?: string;
  imageTwo?: string;
  imageThree?: string;
  imageFour?: string;
  imageFive?: string;
  imageSix?: string;
}

interface OrgtnalStru {
  _id: string;
  name?: string;
  bannerUrl?: string;
  mobileBannerUrl?: string;
  aboutText?: string;
  // OrgtnalSruItem:array;
  isDeleted: boolean;
  email?: string;
  hobbies?: string;
  role?: string;
  superDuperAdminApprovalStatus?: string;
  superAdminApprovalStatus?: string;
  adminApprovalStatus?: string;
  innerData?: userData[] | [];
}

interface UpdateServiceSectionProps {
  itemId: string; // Replace 'boolean' with the appropriate type if needed
  parentId?: string;
  innerdata: userData[];
  itemName?: string;
  departmentName?: string;
}
interface ParentId {
  itemId?: string; // Replace 'boolean' with the appropriate type if needed
  id?: string;
  parentId?: string;
}
interface id {
  id?: string;
}

const ViewModal: React.FC<UpdateServiceSectionProps> = ({
  itemId,
  parentId,
  innerdata,
  itemName,
  departmentName,
}) => {
  const [validated, setValidated] = useState<boolean>(false);
  const [serviceSection, setServiceSection] = useState<OrgtnalStru[]>([]);
  const [aboutText, setAboutText] = useState("");
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [description, setDescription] = useState("");
  const [isError, setIsError] = useState<boolean>(false);
  const [isResponse, setIsResponse] = useState("");
  const [url, setUrl] = useState("");
  const [image, setImage] = useState<File | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();

  // console.log("You are in the update section");
  // ------------------------- for saving the data to updte
  const { user, userLoggedIn, loading, error, userId } = useSelector(
    (state: RootState) => ({
      user: state.Auth.user,
      loading: state.Auth.loading,
      error: state.Auth.error,
      userLoggedIn: state.Auth.userLoggedIn,
      userId: state.Auth._id,
    })
  );
  let profileId = user._id ? user._id : user.user ? user.user._id : "";
  const Key_Key = user.moniterd_ttl
    ? user.moniterd_ttl
    : user.user
    ? user.user.moniterd_ttl
    : "";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.fetchDataAuth(
          `/user/${profileId}/${Key_Key}`
        );

        // console.log("response", response);
        if (response.status === 440) {
          alert("Session Expired");
          dispatch(logoutUser());
          window.location.href = ADMIN_LOGIN_BASE_URL; // Use the full URL, including the protocol (http or https)
        }
        if (!response.status == true) {
          // console.log("response--", await response.json())
          let errorMsg = await response.json();
          setIsResponse(errorMsg.message);
          // console.log("response from update user---",response)
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.data;
        setServiceSection(data);
      } catch (error) {
        // Handle fetch error
        console.error("Error during fetch:", error);
      }
    };
    fetchData(); // Call the async function directly
    // Note: You may want to add a cleanup function here if needed
  }, [profileId, Key_Key]); // Include dependencies if needed

  const handleSubmit = async (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  //   ------------------------------------------ setting image in the input--------------------------------
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImage(e.target.files[0]);
    }
  };

  const UpdateOrgStruItem = async (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      const formData = new FormData();
      formData.append("aboutText", aboutText);
      formData.append("name", name);
      formData.append("role", role);
      if (image) {
        formData.append("image", image);
      }
      try {
        const response = await api.updatePatchData(
          `/api/update/user/${profileId}/editor/${itemId}/${Key_Key}`,
          formData
        );
        // console.log(response.status, "response.status");
        if (response.status === 440) {
          alert("Session Expired");
          dispatch(logoutUser());
          window.location.href = ADMIN_LOGIN_BASE_URL; // Use the full URL, including the protocol (http or https)
        }
        if (response.status === 429) {
          setShowModal(true);
        }
        if (response.status === 400) {
          setIsError(true);
          let errorMessage = await response.json();
          setIsResponse(errorMessage.message);
        }
        if (!response.status == true) {
          let errorMsg = await response.json();
          setIsResponse(errorMsg.message);
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        if (response.status == 200 || response.status == 201) {
          alert("Data updated");
          window.location.reload();
        }
      } catch (error: any) {
        console.error("Error during edit the banner:", error);
      }
    }
    setValidated(true);
  };
  let Role = user.hobbies ? user.hobbies : user.user.hobbies;
  const Cancle = () => {
    window.location.reload();
  };

  const approve = async (itemId: string) => {
    try {
      // console.log("itemId", itemId, "profileId", profileId, "Key_Key", Key_Key);
      // const departmentName = 'FOM'
      const response = await api.updatePatchData(
        `/api/approve/aboutdepartment/${parentId}/itemid/${itemId}/${profileId}/${Key_Key}/${departmentName}`,
        ""
      );
      // console.log("response.status", response.status);
      if (response.status === 440) {
        alert("Session Expired");
        dispatch(logoutUser());
        window.location.href = ADMIN_LOGIN_BASE_URL; // Use the full URL, including the protocol (http or https)
      }
      if (!response.status == true) {
        let errorMsg = await response.json();
        setIsResponse(errorMsg.message);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      if (
        response.status == 200 ||
        response.status == 201 ||
        response.status == 204
      ) {
        alert("Approved");
        window.location.reload();
      }
    } catch (error) {
      setShowModal(true);
      console.error("Error during delete the employee:", error);
    }
  };

  const reject = async (id: string, codeforrRes: string) => {
    try {
      // console.log("itemId", itemId, "profileId", profileId, "Key_Key", Key_Key);
      // console.log(name, "name inside Aprove");
      // const departmentName = 'FOM'
      //   const departmentItem = {name=='About Department'?'ABS':name=='Head Of Department'? 'HOD':name=='Faculty & Staff'?'FAS':name=='Mission & Vision'?'MAV':name=='Infrastructure'?'INFRA':name=='Learning Objective'?'LO':name=='Best Practices'?'BP':name=='Research & Publications'?'RAP':name=='Academics'?'ACS':name=='In Patient Services'?'IPS':name=='Out Patient Services'?'OPS':name=='Price List'?'PL':name=='Links'?'LINK' : name=='Gallery'?'GALLERY':name=='Notices'?'NOT':''}
      const Item = {
        name:
          itemName === "About Department"
            ? "ABS"
            : itemName === "Head Of Department"
            ? "HOD"
            : itemName === "Faculty & Staff"
            ? "FAS"
            : itemName === "Mission & Vision"
            ? "MAV"
            : itemName === "Infrastructure"
            ? "INFRA"
            : itemName === "Learning Objective"
            ? "LO"
            : itemName === "Best Practices"
            ? "BP"
            : itemName === "Research & Publications"
            ? "RAP"
            : itemName === "Academics"
            ? "ACS"
            : itemName === "In Patient Services"
            ? "IPS"
            : itemName === "Out Patient Services"
            ? "OPS"
            : itemName === "Price List"
            ? "PL"
            : itemName === "Links"
            ? "LINK"
            : itemName === "Gallery"
            ? "GALLERY"
            : itemName === "Notices"
            ? "NOT"
            : itemName === "University Position"
            ? "UP"
            : itemName === "Events Organized by Department"
            ? "EOBD"
            : itemName === "Student Corner"
            ? "SC"
            : itemName === "Research & Projects"
            ? "RP"
            : "",
      };
      const departmentItem = Item.name;
      // console.log(departmentItem, "departmentItem--");
      const response = await api.updatePatchData(
        `/api/reject/aboutdepartment/${parentId}/itemid/${itemId}/${profileId}/${Key_Key}/${departmentName}/${departmentItem}/${Role}/${codeforrRes}`,
        ""
      );
      // console.log("response.status", response.status);
      if (response.status === 440) {
        alert("Session Expired");
        dispatch(logoutUser());
        window.location.href = ADMIN_LOGIN_BASE_URL; // Use the full URL, including the protocol (http or https)
      }
      if (!response.status == true) {
        let errorMsg = await response.json();
        setIsResponse(errorMsg.message);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      if (
        response.status == 200 ||
        response.status == 201 ||
        response.status == 204
      ) {
        if (codeforrRes == "rej") {
          alert("Rejected");
        } else if (codeforrRes == "apv") {
          alert("Approved");
        }

        window.location.reload();
      }
    } catch (error) {
      setShowModal(true);
      console.error("Error during delete the employee:", error);
    }
  };
  // console.log("innerdata", innerdata);

  return (
    <>
      <Card>
        <Card.Body>
          {(innerdata || [])
            ?.filter((item) => {
              // console.log("from the line 248--", item._ === itemId);
              return item._id === itemId;
            })
            ?.map((filterItem, index) => (
              <Row key={index}>
                <Col lg={8} className="bg-danger">
                  <Card>
                    <Card.Body>
                      <h4>Approve or Reject Request</h4>
                      {isResponse && (
                        <Alert variant="danger" className="my-2">
                          {isResponse}
                        </Alert>
                      )}
                      <div className="">
                        {/* { */}
                        {filterItem?.name ? (
                          <div className="d-flex">
                            {" "}
                            <p className="fontSize-34">
                              {name == "Faculty Members"
                                ? "Name "
                                : name == "Senior Residents"
                                ? "Name"
                                : name == "Student Corner"
                                ? "Name"
                                : name == "Head Of Department"
                                ? "Name"
                                : name == "University Position"
                                ? "Name of the Position"
                                : name == "Gallery"
                                ? "Event Name"
                                : name == "Notices Board"
                                ? "Subject"
                                : ""}
                            </p>
                            {"  "}{" "}
                            <p className="p-0">
                              {" "}
                              {filterItem?.name}
                              {"  "}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        {filterItem?.title ? (
                          <div className="d-flex">
                            {name == "Events Organized by Department"
                              ? "Title"
                              : name == "Research & Projects"
                              ? "Title "
                              : name == "About Department"
                              ? "Title "
                              : name == "Infrastructure"
                              ? "Title"
                              : name == "Best Practices"
                              ? "Title"
                              : name == "Learning Objective"
                              ? "Subject"
                              : name == "Mission & Vision"
                              ? " Mission"
                              : name == "University Position"
                              ? " From"
                              : ""}
                            <p className="fontSize-34"></p>
                            {"  "}{" "}
                            <p className="p-0">
                              {" "}
                              {filterItem?.title}
                              {"  "}{" "}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}

                        {filterItem?.introduction ? (
                          <div className="d-flex">
                            {" "}
                            <p className="fontSize-34">
                              {" "}
                              {name == "About Department"
                                ? "Introduction "
                                : name == "Infrastructure"
                                ? "Introduction"
                                : name == "Events Organized by Department"
                                ? "Introduction"
                                : name == "Head Of Department"
                                ? "About Head"
                                : name == "Faculty Members"
                                ? "About member"
                                : name == "Senior Residents"
                                ? "About Senior Residents"
                                : name == "Mission & Vision"
                                ? "Vision"
                                : name == "University Position"
                                ? "To"
                                : ""}
                            </p>
                            {"  "}{" "}
                            <p className="p-0">
                              {" "}
                              {filterItem?.introduction}
                              {"  "}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        {filterItem?.summary ? (
                          <div className="d-flex">
                            {" "}
                            <p className="fontSize-34">Abstract/Summary</p>
                            {"  "}{" "}
                            <p className="p-0">
                              {" "}
                              {filterItem?.summary}
                              {"  "}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        {filterItem?.content ? (
                          <div className="d-flex">
                            {" "}
                            <p className="fontSize-34">
                              {" "}
                              {name == "About Department"
                                ? "Content"
                                : name == "Mission & Vision"
                                ? " Core Values"
                                : ""}{" "}
                              {name == "Mission & Vision" ? (
                                <span style={{ color: "red" }}> *</span>
                              ) : (
                                ""
                              )}
                            </p>
                            {"  "}{" "}
                            <p className="p-0">
                              {" "}
                              {filterItem?.content}
                              {"  "}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        {filterItem?.futurePlan ? (
                          <div className="d-flex">
                            {" "}
                            <p className="fontSize-34"> Future Plan:-</p>
                            {"  "}{" "}
                            <p className="p-0">
                              {" "}
                              {filterItem?.futurePlan}
                              {"  "}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        {filterItem?.researchBy ? (
                          <div className="d-flex">
                            {" "}
                            <p className="fontSize-34"> Research By :-</p>
                            {"  "}{" "}
                            <p className="p-0">
                              {" "}
                              {filterItem?.researchBy}
                              {"  "}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}

                        {filterItem?.yearOfResearch ? (
                          <div className="d-flex">
                            {" "}
                            <p className="fontSize-34">
                              {" "}
                              {name == "Head Of Department" ? "Year" : ""}
                            </p>
                            {"  "}{" "}
                            <p className="p-0">
                              {" "}
                              {filterItem?.yearOfResearch}
                              {"  "}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}

                        {filterItem?.date ? (
                          <div className="d-flex">
                            {" "}
                            <p className="fontSize-34">
                              {name == "Events Organized by Department"
                                ? "Event Date"
                                : name == "Notices Board"
                                ? "From"
                                : ""}
                            </p>
                            {"  "}{" "}
                            <p className="p-0">
                              {" "}
                              {filterItem?.date}
                              {"  "}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}

                        {filterItem?.dateTo ? (
                          <div className="d-flex">
                            {" "}
                            <p className="fontSize-34">
                              {name == "Notices Board" ? "To" : ""}
                            </p>
                            {"  "}{" "}
                            <p className="p-0">
                              {" "}
                              {filterItem?.dateTo}
                              {"  "}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}

                        {/* } */}
                        <div className="d-flex">
                          {" "}
                          <p className="fontSize-34"> Super Admin:- </p>
                          {"  "}{" "}
                          <p className="p-0">
                            {" "}
                            {filterItem?.superDuperAdminApprovalStatus}
                            {"  "}{" "}
                          </p>
                        </div>

                        {/* <div className="d-flex">
                          {" "}
                          <p className="fontSize-34"> Super Admin 2:-</p>
                          {"  "}{" "}
                          <p className="p-0">
                            {" "}
                            {filterItem?.superAdminApprovalStatus}
                            {"  "}
                          </p>
                        </div> */}

                        <div className="d-flex">
                          {" "}
                          <p className="fontSize-34"> Admin:- </p>
                          {"  "}{" "}
                          <p className="p-0">
                            {" "}
                            {filterItem?.adminApprovalStatus}
                            {"  "}{" "}
                          </p>
                        </div>
                        <div className="d-flex">
                          {" "}
                          <p className="fontSize-34"> Created By:-</p>{" "}
                          <p className="p-0">
                            {" "}
                            {filterItem?.createdBy}
                            {"  "}{" "}
                          </p>
                        </div>
                        <div className="d-flex">
                          {" "}
                          <p className="fontSize-34"> Last Updated By:-</p>{" "}
                          <p className="p-0">
                            {" "}
                            {filterItem?.modifiedBy}
                            {"  "}{" "}
                          </p>
                        </div>

                        {Role === "Chess" ? (
                          filterItem?.adminApprovalStatus == "Pending" ? (
                            <h3 className="text-primary">
                              Admin not Approved yet
                            </h3>
                          ) : filterItem?.adminApprovalStatus == "Approved" ? (
                            <Row className="gap-3">
                              <Col lg={2}>
                                <button
                                  onClick={() => reject(itemId, "apv")}
                                  className="btn btn-danger"
                                >
                                  Approve
                                </button>
                              </Col>
                              <Col lg={2}>
                                <button
                                  onClick={() => reject(itemId, "rej")}
                                  className="btn btn-primary"
                                >
                                  Reject
                                </button>
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )
                        ) : Role === "Music" ? (
                          filterItem?.adminApprovalStatus == "Pending" ? (
                            <Row className="gap-3">
                              <Col lg={2}>
                                <button
                                  onClick={() => reject(itemId, "apv")}
                                  className="btn btn-danger"
                                >
                                  Approve
                                </button>
                              </Col>
                              <Col lg={2}>
                                <button
                                  onClick={() => reject(itemId, "rej")}
                                  className="btn btn-primary"
                                >
                                  Reject
                                </button>
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}

                        {/* {Role.Role === "Chess" ? <th>Action</th> : ""} */}

                        {/* {Role &&
                        Role === "Chess" &&
                        filterItem?.superDuperAdminApprovalStatus ==
                          "Pending" ? (
                          <Row className="gap-3">
                            <Col lg={2}>
                              <button
                                onClick={() => reject(itemId, "apv")}
                                className="btn btn-danger"
                              >
                                Approve
                              </button>
                            </Col>
                            <Col lg={2}>
                              <button
                                onClick={() => reject(itemId, "rej")}
                                className="btn btn-primary"
                              >
                                Reject
                              </button>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )} */}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                {/* <Col lg={4} className="bg-dark">

                                </Col> */}

                {/* image starts from here */}
                <Col lg={4}>
                  <Row>
                    <Col lg={4} className="">
                      {/* Assuming 'imageUrl' contains the URL of the image */}
                      {filterItem?.image ? (
                        <>
                          <h5>Image-</h5>
                          <a
                            href={`${API_BASE_URL}/${filterItem?.image?.replace(
                              "uploads/images/",
                              "images/"
                            )}`}
                            target="_blaank"
                          >
                            <Image
                              src={`${API_BASE_URL}/${filterItem?.image?.replace(
                                "uploads/images/",
                                "images/"
                              )}`}
                              // src={`${API_BASE_URL}/${filterItem?.image?.replace("uploads/images/", "images/")}`}
                              fluid
                              className="w-100"
                              alt="Image"
                            />
                          </a>
                        </>
                      ) : (
                        ""
                      )}
                    </Col>

                    <Col lg={4} className="">
                      {/* Assuming 'imageUrl' contains the URL of the image */}
                      {filterItem?.imageOne ? (
                        <>
                          <h5>Image One</h5>
                          <a
                            href={`${API_BASE_URL}/${filterItem?.imageOne?.replace(
                              "uploads/images/",
                              "images/"
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Image
                              src={`${API_BASE_URL}/${filterItem?.imageOne?.replace(
                                "uploads/images/",
                                "images/"
                              )}`}
                              fluid
                              className="w-100"
                              alt="Image"
                            />
                          </a>
                        </>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col lg={4} className="">
                      {/* Assuming 'imageUrl' contains the URL of the image */}
                      {filterItem?.imageTwo ? (
                        <>
                          <h5>Image Two</h5>

                          <a
                            href={`${API_BASE_URL}/${filterItem?.imageTwo?.replace(
                              "uploads/images/",
                              "images/"
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Image
                              src={`${API_BASE_URL}/${filterItem?.imageTwo?.replace(
                                "uploads/images/",
                                "images/"
                              )}`}
                              fluid
                              className="w-100"
                              alt="Image"
                            />
                          </a>
                        </>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col lg={4} className="">
                      {/* Assuming 'imageUrl' contains the URL of the image */}
                      {filterItem?.imageThree ? (
                        <>
                          <h5>Image Three</h5>
                          <a
                            href={`${API_BASE_URL}/${filterItem?.imageThree?.replace(
                              "uploads/images/",
                              "images/"
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Image
                              src={`${API_BASE_URL}/${filterItem?.imageThree?.replace(
                                "uploads/images/",
                                "images/"
                              )}`}
                              fluid
                              className="w-100"
                              alt="Image"
                            />
                          </a>
                        </>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col lg={4} className="">
                      {/* Assuming 'imageUrl' contains the URL of the image */}
                      {filterItem?.imageFour ? (
                        <>
                          <h5>Image Four</h5>
                          <a
                            href={`${API_BASE_URL}/${filterItem?.imageFour?.replace(
                              "uploads/images/",
                              "images/"
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Image
                              src={`${API_BASE_URL}/${filterItem?.imageFour?.replace(
                                "uploads/images/",
                                "images/"
                              )}`}
                              fluid
                              className="w-100"
                              alt="Image"
                            />
                          </a>
                        </>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col lg={4} className="">
                      {/* Assuming 'imageUrl' contains the URL of the image */}
                      {filterItem?.imageFive ? (
                        <>
                          <h5>Image five</h5>
                          <a
                            href={`${API_BASE_URL}/${filterItem?.imageFive?.replace(
                              "uploads/images/",
                              "images/"
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Image
                              src={`${API_BASE_URL}/${filterItem?.imageFive?.replace(
                                "uploads/images/",
                                "images/"
                              )}`}
                              fluid
                              className="w-100"
                              alt="Image"
                            />
                          </a>
                        </>
                      ) : (
                        ""
                      )}
                    </Col>

                    <Col lg={4} className="">
                      {/* Assuming 'imageUrl' contains the URL of the image */}
                      {filterItem?.imageSix ? (
                        <>
                          <h5>Image Six</h5>
                          <a
                            href={`${API_BASE_URL}/${filterItem?.imageSix?.replace(
                              "uploads/images/",
                              "images/"
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Image
                              src={`${API_BASE_URL}/${filterItem?.imageSix?.replace(
                                "uploads/images/",
                                "images/"
                              )}`}
                              fluid
                              className="w-100"
                              alt="Image"
                            />
                          </a>
                        </>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
        </Card.Body>
      </Card>
    </>
  );
};

export default ViewModal;
