// import { height } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Image, Row, Table } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import { logoutUser } from "../../redux/actions";
import AuthModal from "../../components/Modal";
import { Alert } from "react-bootstrap";
import api from "../../utils/ApiMethod";
import API_BASE_URL, {
  ADMIN_LOGIN_BASE_URL,
} from "../../apiservices/apiService";

import DownloadResume from "./DownloadResume";

interface OrgtnalSruItem {
  _id?: string;
  aboutText?: string;
  isDeleted?: boolean;
}

interface userData {
  name?: string;
  email?: string;
  password?: string;
  hobbies?: string;
  role?: string;
  isDeleted?: boolean;
  imageUrl?: string;
  _id?: string;
  title?: string;
  adminApprovalStatus?: string;
  department?: string;
  moderatorApprovalStatus?: string;
  superAdminApprovalStatus?: string;
  superDuperAdminApprovalStatus?: string;
  UpdatedBy?: string;
  createdBy?: string;
  modifiedBy?: string;
  image?: string;
  designation?: string;
}

interface ResumeData {
  personalInfo: any;
  expertise: any;
  contactInfo: any;
  experience: any[];
  education: any[];
  honoursAndAwards: any[];
  doctoralTheses: any[];
  professionalMemberships: any[];
  committeeMemberships: any[];
  researchProjects: any[];
  patents: any[];
  publications: any[];
}

interface OrgtnalStru {
  _id: string;
  name?: string;
  bannerUrl?: string;
  mobileBannerUrl?: string;
  aboutText?: string;
  // OrgtnalSruItem:array;
  isDeleted: boolean;
  email?: string;
  hobbies?: string;
  role?: string;
  superDuperAdminApprovalStatus?: string;
  superAdminApprovalStatus?: string;
  adminApprovalStatus?: string;
  innerData?: userData[] | [];
}

interface UpdateServiceSectionProps {
  itemId: string; // Replace 'boolean' with the appropriate type if needed
  parentId?: string;
  innerdata: userData[];
}
interface ParentId {
  itemId?: string; // Replace 'boolean' with the appropriate type if needed
  id?: string;
  parentId?: string;
}
interface id {
  id?: string;
}

const ViewModal: React.FC<UpdateServiceSectionProps> = ({
  itemId,
  parentId,
  innerdata,
}) => {
  const [validated, setValidated] = useState<boolean>(false);
  const [serviceSection, setServiceSection] = useState<OrgtnalStru[]>([]);
  const [aboutText, setAboutText] = useState("");
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [description, setDescription] = useState("");
  const [isError, setIsError] = useState<boolean>(false);
  const [isResponse, setIsResponse] = useState("");
  const [url, setUrl] = useState("");
  const [image, setImage] = useState<File | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const [dataLoading, setDataLoading] = useState(false);

  //  for viewing the data in resume formate
  // const [personalInfo, setPersonalInfo] = useState<PersonalInfo>({
  //   name: "",
  //   designation: "",
  //   employeeid: "",
  //   dob: "",
  //   doj: "",
  //   gender: "",
  //   image: "",
  // });
  // const [expertise, setExpertise] = useState<ExpertiseSection>({
  //   expertise: "",
  //   briefInfo: "",
  // });
  // const [contactInfo, setContactInfo] = useState<ContactInformation>({
  //   mobileNumber: "",
  //   emailid: "",
  //   country: "",
  //   state: "",
  //   city: "",
  //   zip: "",
  // });

  // const [experience, setExperience] = useState<ExperienceInformation>({
  //   organization: "",
  //   department: "",
  //   orgType: "",
  //   orgURL: "",
  //   district: "",
  //   designation: "",
  //   startDate: "",
  //   endDate: "",
  //   currentlyWorking: false,
  // });

  // const [experienceData, setExperienceData] = useState([]);

  // const [education, setEducation] = useState<EducationInformation>({
  //   qualification: "",
  //   institute: "",
  //   subject: "",
  //   passingYear: "",
  // });
  // const [educationData, setEducationData] = useState([]);

  // const [honoursAndAwards, setHonoursAndAwards] =
  //   useState<HonoursAndAwardsInfo>({
  //     year: "",
  //     awardName: "",
  //     awardedBy: "",
  //   });

  // const [honoursAndAwardData, setHonoursAndAwardData] = useState([]);

  // const [doctoralTheses, setDoctoralTheses] = useState<DoctoralThesesInfo>({
  //   researcherName: "",
  //   title: "",
  //   institute: "",
  //   batch: "",
  //   awardedYear: "",
  // });
  // const [doctoralThesesData, setDoctoralThesesData] = useState([]);

  // const [professionalMemberships, setProfessionalMemberships] =
  //   useState<ProfessionalMembershipsInfo>({
  //     bodyName: "",
  //     employeeId: "",
  //     memberType: "",
  //     awardedYear: "",
  //   });
  // const [professionalMembershipsData, setProfessionalMembershipsData] =
  //   useState([]);

  // const [committeeMemberships, setCommitteeMemberships] =
  //   useState<CommitteeMembershipsInfo>({
  //     committeeName: "",
  //     role: "",
  //     year: "",
  //     endYear: "",
  //   });
  // const [committeeMembershipsData, setCommitteeMembershipsData] = useState([]);

  // const [researchProjects, setResearchProjects] =
  //   useState<ResearchProjectsInfo>({
  //     title: "",
  //     startDate: "",
  //     endDate: "",
  //     ongoing: false,
  //     role: "",
  //     fundingAgency: "",
  //     grantNo: "",
  //     status: "",
  //     amount: "",
  //   });
  // const [researchProjectsData, setResearchProjectsData] = useState([]);

  // const [patents, setPatents] = useState<PatentInfo>({
  //   title: "",
  //   inventors: "",
  //   applicantName: "",
  //   applicationNumber: "",
  //   patentNumber: "",
  //   country: "",
  //   subjectCategory: "",
  //   fillingDate: "",
  //   publicationDate: "",
  //   status: "",
  // });
  // const [patentsData, setPatentsData] = useState([]);

  // const [publications, setPublications] = useState<PublicationsInfo>({
  //   workType: "",
  //   title: "",
  //   journalBookTitle: "",
  //   year: "",
  //   authors: [{ firstName: "", middleName: "", lastName: "" }],
  //   url: "",
  //   doi: "",
  //   volume: "",
  //   pageNo: "",
  //   editor: "",
  //   publisher: "",
  //   issues: "",
  // });
  // const [publicationsData, setPublicationsData] = useState([]);

  // const [academicInfo, setAcademicInfo] = useState<AcademicInfo>({
  //   orcidId: "",
  //   scopusId: "",
  //   researcherId: "",
  //   googleScholarId: "",
  // });

  const [resumeData, setResumeData] = useState<ResumeData>();
  // for viewing the data in resume formate ends here

  // console.log("You are in the update section");
  // ------------------------- for saving the data to updte
  const { user, userLoggedIn, loading, error, userId } = useSelector(
    (state: RootState) => ({
      user: state.Auth.user,
      loading: state.Auth.loading,
      error: state.Auth.error,
      userLoggedIn: state.Auth.userLoggedIn,
      userId: state.Auth._id,
    })
  );
  let profileId = user._id ? user._id : user.user ? user.user._id : "";
  const Key_Key = user.moniterd_ttl
    ? user.moniterd_ttl
    : user.user
    ? user.user.moniterd_ttl
    : "";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.fetchDataAuth(
          `/user/${profileId}/${Key_Key}`
        );
        if (response.status === 440) {
          alert("Session Expired");
          dispatch(logoutUser());
          window.location.href = ADMIN_LOGIN_BASE_URL; // Use the full URL, including the protocol (http or https)
        }
        if (!response.status == true) {
          // console.log("response--", await response.json())
          let errorMsg = await response.json();
          setIsResponse(errorMsg.message);
          // console.log("response from update user---",response)
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.data;
        setServiceSection(data);
      } catch (error) {
        // Handle fetch error
        console.error("Error during fetch:", error);
      }
    };
    fetchData(); // Call the async function directly
    // Note: You may want to add a cleanup function here if needed
  }, [profileId, Key_Key]); // Include dependencies if needed

  const handleSubmit = async (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  //   ------------------------------------------ setting image in the input--------------------------------
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImage(e.target.files[0]);
    }
  };

  const UpdateOrgStruItem = async (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      const formData = new FormData();
      formData.append("aboutText", aboutText);
      formData.append("name", name);
      formData.append("role", role);
      if (image) {
        formData.append("image", image);
      }
      try {
        const response = await api.updatePatchData(
          `/api/update/user/${profileId}/editor/${itemId}/${Key_Key}`,
          formData
        );
        // console.log(response.status, "response.status");
        if (response.status === 440) {
          alert("Session Expired");
          dispatch(logoutUser());
          window.location.href = ADMIN_LOGIN_BASE_URL; // Use the full URL, including the protocol (http or https)
        }
        if (response.status === 429) {
          setShowModal(true);
        }
        if (response.status === 400) {
          setIsError(true);
          let errorMessage = await response.json();
          setIsResponse(errorMessage.message);
        }
        if (!response.status == true) {
          let errorMsg = await response.json();
          setIsResponse(errorMsg.message);
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        if (response.status == 200 || response.status == 201) {
          alert("Data updated");
          window.location.reload();
        }
      } catch (error: any) {
        console.error("Error during edit the banner:", error);
      }
    }
    setValidated(true);
  };
  let Role = user.hobbies ? user.hobbies : user.user.hobbies;

  const Cancle = () => {
    window.location.reload();
  };

  const approve = async (itemId: string) => {
    try {
      // console.log("itemId", itemId, "profileId", profileId, "Key_Key", Key_Key);
      const response = await api.updatePatchDataDelete(
        `/api/approve/user/${profileId}/employee/${itemId}/${Role}/${Key_Key}`,
        ""
      );
      // console.log("response.status", response.status);
      if (response.status === 440) {
        alert("Session Expired");
        dispatch(logoutUser());
        window.location.href = ADMIN_LOGIN_BASE_URL; // Use the full URL, including the protocol (http or https)
      }
      if (!response.status == true) {
        let errorMsg = await response.json();
        setIsResponse(errorMsg.message);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      if (
        response.status == 200 ||
        response.status == 201 ||
        response.status == 204
      ) {
        alert("Approved");
        window.location.reload();
      }
    } catch (error) {
      setShowModal(true);
      console.error("Error during delete the employee:", error);
    }
  };
  const reject = async (id: string) => {
    try {
      // console.log("itemId", itemId, "profileId", profileId, "Key_Key", Key_Key);
      const response = await api.updatePatchDataDelete(
        `/api/reject/user/${profileId}/employee/${itemId}/${Role}/${Key_Key}`,
        ""
      );
      // console.log("response.status", response.status);
      if (response.status === 440) {
        alert("Session Expired");
        dispatch(logoutUser());
        window.location.href = ADMIN_LOGIN_BASE_URL; // Use the full URL, including the protocol (http or https)
      }
      if (!response.status == true) {
        let errorMsg = await response.json();
        setIsResponse(errorMsg.message);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      if (
        response.status == 200 ||
        response.status == 201 ||
        response.status == 204
      ) {
        alert("Rejected");
        window.location.reload();
      }
    } catch (error) {
      setShowModal(true);
      console.error("Error during delete the employee:", error);
    }
  };

  //  api to get user data for viewing

  // useEffect(() => {
  const fetchData = async (itemId: string) => {
    try {
      setDataLoading(true);
      const response = await api.fetchDataAuth(
        `/api/super/doctor/${profileId}/${itemId}/${Key_Key}`
      );

      // console.log('response', response)

      // if (!response.ok) {
      //   // Handle non-successful response (optional)
      //   console.error("Error fetching data:", response.statusText);
      //   return;
      // }
      if (response.status === 440) {
        alert("Session Expired");
        dispatch(logoutUser());
        window.location.href = "http://165.22.219.69:3002/auth/login"; // Use the full URL, including the protocol (http or https)
      }
      // const data = await response.json();
      // console.log('oguihblhlu', response?.data?.data[0])

      setResumeData(response?.data?.data[0]);
      // setPersonalInfo(data?.data[0]?.personalInfo);
      // setExpertise(data?.data[0]?.expertise);
      // setContactInfo(data?.data[0]?.contactInfo);
      // setExperienceData(data?.data[0]?.experience);
      // setEducationData(data?.data[0]?.education);
      // setHonoursAndAwardData(data?.data[0]?.honoursAndAwards);
      // setDoctoralThesesData(data?.data[0]?.doctoralTheses);
      // setProfessionalMembershipsData(data?.data[0]?.professionalMemberships);
      // setCommitteeMembershipsData(data?.data[0]?.committeeMemberships);
      // setResearchProjectsData(data?.data[0]?.researchProjects);
      // setPatentsData(data?.data[0]?.patents);
      // setPublicationsData(data?.data[0]?.publications);
      // setAcademicInfo(data?.data[0]?.academicIdentity);
      setDataLoading(false);
    } catch (error) {
      // Handle fetch error
      setDataLoading(false);
      console.error("Error during fetch:", error);
    }
  };

  // if (doctorDataProfileId) {
  //   fetchData();
  // }

  // Call the async function directly
  // Note: You may want to add a cleanup function here if needed
  // }, [profileId, Key_Key]); // Include dependencies if needed

  // console.log('resumeData', resumeData)

  return (
    <>
      <Card>
        <Card.Body>
          {(innerdata || [])
            ?.filter((item) => {
              // console.log("from the line 248--", item._ === itemId);
              return item._id === itemId;
            })
            ?.map((filterItem, index) => (
              <Row key={index}>
                <Col lg={8} className="bg-danger">
                  <Card>
                    <Card.Body>
                      <h4>Approve or Reject Request</h4>
                      {isResponse && (
                        <Alert variant="danger" className="my-2">
                          {isResponse}
                        </Alert>
                      )}
                      <div className="">
                        <div className="d-flex">
                          {" "}
                          <p className="fontSize-34"> Name:-</p>
                          {"  "}{" "}
                          <p className="p-0">
                            {" "}
                            {filterItem?.title}
                            {"  "}
                            {filterItem?.name}{" "}
                          </p>
                        </div>
                        <div className="d-flex">
                          {" "}
                          <p className="fontSize-34"> Email:-</p>
                          {"  "} <p className="p-0"> {filterItem?.email} </p>
                        </div>
                        <div className="d-flex">
                          {" "}
                          <p className="fontSize-34"> Role:-</p>
                          {"  "} <p className="p-0"> {filterItem?.role} </p>
                        </div>
                        <div className="d-flex">
                          {" "}
                          <p className="fontSize-34"> designation:-</p>
                          {"  "}{" "}
                          <p className="p-0"> {filterItem?.designation} </p>
                        </div>
                        <div className="d-flex">
                          {" "}
                          <p className="fontSize-34"> Department:-</p>
                          {"  "}{" "}
                          <p className="p-0"> {filterItem?.department} </p>
                        </div>
                        <div className="d-flex">
                          {" "}
                          <p className="fontSize-34"> Super Admin-</p>
                          {"  "}{" "}
                          <p className="p-0">
                            {" "}
                            {filterItem?.superDuperAdminApprovalStatus}
                            {"  "}{" "}
                          </p>
                        </div>

                        {/* <div className="d-flex">
                          {" "}
                          <p className="fontSize-34"> Super Admin 2:-</p>
                          {"  "}{" "}
                          <p className="p-0">
                            {" "}
                            {filterItem?.superAdminApprovalStatus}
                            {"  "}
                          </p>
                        </div> */}

                        <div className="d-flex">
                          {" "}
                          <p className="fontSize-34"> Admin:- </p>
                          {"  "}{" "}
                          <p className="p-0">
                            {" "}
                            {filterItem?.adminApprovalStatus}
                            {"  "}{" "}
                          </p>
                        </div>
                        <div className="d-flex">
                          {" "}
                          <p className="fontSize-34"> Created by:-</p>
                          {"  "}{" "}
                          <p className="p-0">
                            {" "}
                            {filterItem?.createdBy}
                            {"  "}{" "}
                          </p>
                        </div>
                        <div className="d-flex">
                          {" "}
                          <p className="fontSize-34"> Updated by:-</p>
                          {"  "}{" "}
                          <p className="p-0">
                            {" "}
                            {filterItem?.modifiedBy}
                            {"  "}{" "}
                          </p>
                        </div>
                        {/* {Role.Role === "Chess" ? <th>Action</th> : ""} */}

                        {Role === "Chess" ? (
                          filterItem?.adminApprovalStatus == "Pending" ? (
                            <h3 className="text-primary">
                              Admin not Approved yet{" "}
                              {`${filterItem?.adminApprovalStatus}`}
                            </h3>
                          ) : filterItem?.adminApprovalStatus == "Approved" &&
                            filterItem?.superDuperAdminApprovalStatus ===
                              "Pending" ? (
                            <Row className="gap-3">
                              <Col lg={2}>
                                <button
                                  onClick={() => approve(itemId)}
                                  className="btn btn-danger"
                                >
                                  Approve
                                </button>
                              </Col>
                              <Col lg={2}>
                                <button
                                  onClick={() => reject(itemId)}
                                  className="btn btn-primary"
                                >
                                  Reject
                                </button>
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )
                        ) : Role === "Music" ? (
                          filterItem?.adminApprovalStatus == "Pending" ? (
                            <Row className="gap-3">
                              <Col lg={2}>
                                <button
                                  onClick={() => approve(itemId)}
                                  className="btn btn-danger"
                                >
                                  Approve
                                </button>
                              </Col>
                              <Col lg={2}>
                                <button
                                  onClick={() => reject(itemId)}
                                  className="btn btn-primary"
                                >
                                  Reject
                                </button>
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}

                        {/*
                        {Role === "Chess" &&
                          filterItem?.adminApprovalStatus == "Pending" ?
                          (
                            <h3 className="text-primary">Admin not Approved yet</h3>
                          ) :

                          filterItem?.superDuperAdminApprovalStatus ==
                            "Pending" || filterItem?.adminApprovalStatus == "Approved" ? (

                            <Row className="gap-3">
                              <Col lg={2}>
                                <button
                                  onClick={() => approve(itemId)}
                                  className="btn btn-danger"
                                >
                                  Approve
                                </button>
                              </Col>
                              <Col lg={2}>
                                <button
                                  onClick={() => reject(itemId)}
                                  className="btn btn-primary"
                                >
                                  Reject
                                </button>
                              </Col>
                            </Row>
                          )
                            : (
                              ""
                            )} */}

                        {/* {
                          Role === "Music" &&
                            filterItem?.adminApprovalStatus == "Pending" ? (
                            <Row className="gap-3">
                              <Col lg={2}>
                                <button
                                  onClick={() => approve(itemId)}
                                  className="btn btn-danger"
                                >
                                  Approve
                                </button>
                              </Col>
                              <Col lg={2}>
                                <button
                                  onClick={() => reject(itemId)}
                                  className="btn btn-primary"
                                >
                                  Reject
                                </button>
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )} */}
                      </div>
                      {/* <Col lg={2}>
                        <button
                          onClick={() => fetchData(itemId)}
                          className="btn btn-primary"
                        >
                          View Resume
                        </button>
                      </Col> */}
                    </Card.Body>
                  </Card>
                </Col>

                <Col lg={4} className="bg-dark">
                  <>
                    <h5>Image Four</h5>
                    <a
                      href={`http://165.22.219.69:5002/${filterItem?.imageUrl?.replace(
                        "uploads/images/",
                        "images/"
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image
                        src={`http://165.22.219.69:5002/${filterItem?.imageUrl?.replace(
                          "uploads/images/",
                          "images/"
                        )}`}
                        fluid
                        className="w-100"
                        alt="Image"
                      />
                    </a>
                  </>
                </Col>
              </Row>
            ))}

          {/* for showing resume */}

          {resumeData ? (
            <DownloadResume resumeData={resumeData} />
          ) : (
            <div>Loading or No data available</div>
          )}
          {/* for showing resume */}
        </Card.Body>
      </Card>
    </>
  );
};

export default ViewModal;
