// import { height } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Image, Row, Table } from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import AuthModal from "../../components/Modal";
import { Alert } from "react-bootstrap";
import { logoutUser } from "../../redux/actions";
import api from "../../utils/ApiMethod";
import AproveDepartment from "./AproveDepartment";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import img from "../../assets/images/new.gif";
import { ADMIN_LOGIN_BASE_URL } from "../../apiservices/apiService";

//  data base connected to this model facultyOfMedicineModel

interface Child {
  _id: string;
  url?: string;
  title?: string;
  sportDetails: string;
  image: string;
  doc: string;
  isDeleted: boolean;
  address?: string;
  pin?: string;
  bgColor?: string;
  text?: string;
  content?: string;
  introduction?: string;
  researchBy?: string;
  awards?: string;
  modifiedBy?: string;
  createdBy?: string;
  researcherName?: string;
  yearOfResearch?: string;
  selectedDate?: Date;
  selectedDateTo?: Date;
  subject?: string;
  mission?: string;
  name?: string;
  vision?: string;
  summary?: string;
  date?: Date;
  dateTo?: Date;
  coreValues?: string;
  superDuperAdminApprovalStatus?: string;
  adminApprovalStatus?: string;
}

interface link {
  _id: string;
  title: string;
  url: string;
  image: string;
  doc: string;
  pin: string;
  isDeleted: boolean;
}

interface address {
  _id: string;
  title: string;
  address: string;
  pin: string;
  isDeleted: boolean;
}

interface Parent {
  itemId?: string; // Replace 'boolean' with the appropriate type if needed
  _id?: string;
  text?: string;
  content?: string;
  bannerUrl?: string;
  mobileBannerUrl?: string;
  name?: string;
  numberOfVisitors: string;
  data: [] | undefined;
  aboutus?: string; // An array of OrgtnalSruItem objects
  facultyAndStaff?: string; // An array of OrgtnalSruItem objects
  researchAndPublications?: string; // An array of OrgtnalSruItem objects
  academics?: string; // An array of OrgtnalSruItem objects
  inPatientService?: string; // An array of OrgtnalSruItem objects
  outPatientService?: string; // An array of OrgtnalSruItem objects
  priceList?: string; // An array of OrgtnalSruItem objects
  MisionAndVision?: string; // An array of OrgtnalSruItem objects
  links?: string; // An array of OrgtnalSruItem objects
  gallery?: string; // An array of OrgtnalSruItem objects
  innerData?: Child[];
  // OrgtnalSruItem:array;
  isDeleted: boolean;
  notice?: string;
  headOfDepartment?: string;
  infrastructure?: string;
  learningObjective?: string;
  bestPractices?: string;
  universityPosition?: string;
  researchAndProjects?: string;
  eventOrgDeprtmt?: string;
  studentCorner?: string;
  facultyOfResidents?: string;
  facultyOfMember?: string;
}

interface id {
  id: string;
  link?: string;
  name?: string;
  activeDepartmentName?: string;
}

interface Props {
  itemId: string; // Replace 'boolean' with the appropriate type if needed
  parentId?: string;
  innerdata: Child[];
  innerData?: Child[]; // Specify the correct type for innerData
  name?: string;
}

interface parentId {
  itemId?: string; // Replace 'boolean' with the appropriate type if needed
  id?: string;
  _id?: string | undefined;
  text?: string;
  parentId?: string | undefined;
  // data?: typeof parent[];
  data?: Parent[]; // Specify the correct prop type
  link?: string; // An array of OrgtnalSruItem objects
  address?: string;
  joinus?: string;
  inportantLink?: string;
  name?: string;
  activeDepartmentName?: string;
  innerData?: Child[];
}

const footerItems = [
  // these below data is provided by Upums department to add in the departments
  { name: "About Faculty" },
  { name: "Dean" },
  // { name: "Faculty & Staff" },
  { name: "Faculty Members" },
  // { name: "Senior Residents" },
  { name: "Mission & Vision" },
  { name: "Infrastructure" },
  { name: "Learning Objective" },
  { name: "Best Practices" },
  { name: "University Position" },
  { name: "Events Organized" },
  { name: "Student Corner" },
  { name: "Gallery" },
  { name: "Notices Board" },
  { name: "Research & Projects" },
  // // these above data is provided by Upums department to add in the departments

  // { name: "Research & Publications" },
  // { name: "Academics" },
  // { name: "In Patient Services" },
  // { name: "Out Patient Services" },
  // { name: "Price List" },
  // { name: "Links" },

  // { name: "numberOfVisitors" }
];

const DeleteDepartment: React.FC<parentId> = ({ data }) => {
  const [validated, setValidated] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const [isEditItem, setIsEditItem] = useState<string>("");
  const [activeDepartmentId, setActiveDepartmentId] = useState("");
  const [activeDepartmentName, setActiveDepartmentName] = useState("");
  // const [buttonLoading, setButtonLoding] = useState(false)
  const [buttonLoading, setButtonLoading] = useState<boolean[]>([]); // Array to hold loading state for each item
  const [showForAprov, setShowForAprov] = useState<boolean>(false);

  const [innerData, setInnerData] = useState("");
  const [name, setName] = useState("");
  const [isResponse, setIsResponse] = useState("");
  const { user, userLoggedIn, loading, error, userId } = useSelector(
    (state: RootState) => ({
      user: state.Auth.user,
      loading: state.Auth.loading,
      error: state.Auth.error,
      userLoggedIn: state.Auth.userLoggedIn,
      userId: state.Auth._id,
    })
  );
  let profileId = user._id ? user._id : user.user ? user.user._id : "";
  let Role = user.hobbies ? user.hobbies : user.user.hobbies;

  const Key_Key = user.moniterd_ttl
    ? user.moniterd_ttl
    : user.user
    ? user.user.moniterd_ttl
    : "";

  const deleteItem = async (itemId: string, index: number) => {
    setButtonLoading((prevState) => {
      const updatedLoadingState = [...prevState]; // Copying the loading state array
      updatedLoadingState[index] = true; // Setting loading state for the clicked item
      return updatedLoadingState;
    });
    try {
      const departmentName = "FON";

      const response = await api.updatePatchDataDelete(
        `/api/delete/department/${itemId}/${profileId}/${Key_Key}/${departmentName}`,
        ""
      );

      if (response.status === 440) {
        alert("Session Expired");
        dispatch(logoutUser());
        window.location.href = ADMIN_LOGIN_BASE_URL;
      }
      if (
        response.status == 200 ||
        response.status == 201 ||
        response.status == 204
      ) {
        alert("Deleted");
        window.location.reload();
      }
      // Assuming you want to parse the JSON response
      const data = await response.json();
      const userConfirmed = window.confirm("Deleted");

      if (userConfirmed) {
        window.location.reload();
      }
    } catch (error) {
      console.error("Error during edit the banner:", error);
    } finally {
      setButtonLoading((prevState) => {
        const updatedLoadingState = [...prevState]; // Copying the loading state array
        updatedLoadingState[index] = false; // Resetting loading state back to false after operation completes
        return updatedLoadingState;
      });
    }
  };

  const editItem = async (id: string) => {
    let newEditItem = data?.find((elem) => {
      return elem._id === id;
    });
    setIsEditItem(id);
  };

  const activeDepartment = async (id: string, departmentName: string) => {
    let newEditItem = data?.find((elem) => {
      setActiveDepartmentId(id);
      setActiveDepartmentName(departmentName);
      // console.log("activeDepartmentId", activeDepartmentId);
      return elem._id === id;
    });
  };
  const showItem = (item: any) => {
    // if (item === 'link') setInnerData(data[0]?.link); setName(item);
    if (item === "About Faculty") {
      if (data?.[0]?.aboutus !== undefined) {
        setInnerData(data[0]?.aboutus);
        setName(item);
      }
    }
    if (item === "Faculty & Staff") {
      if (data?.[0]?.facultyAndStaff !== undefined) {
        setInnerData(data[0]?.facultyAndStaff);
        setName(item);
      }
    }
    if (item === "Research & Publications") {
      if (data?.[0]?.researchAndPublications !== undefined) {
        setInnerData(data[0]?.researchAndPublications);
        setName(item);
      }
    }
    if (item === "Academics") {
      if (data?.[0]?.academics !== undefined) {
        setInnerData(data[0]?.academics);
        setName(item);
      }
    }
    if (item === "In Patient Services") {
      if (data?.[0]?.inPatientService !== undefined) {
        setInnerData(data[0]?.inPatientService);
        setName(item);
      }
    }
    if (item === "Out Patient Services") {
      if (data?.[0]?.outPatientService !== undefined) {
        setInnerData(data[0]?.outPatientService);
        setName(item);
      }
    }
    if (item === "Price List") {
      if (data?.[0]?.priceList !== undefined) {
        setInnerData(data[0]?.priceList);
        setName(item);
      }
    }
    if (item === "Links") {
      if (data?.[0]?.MisionAndVision !== undefined) {
        setInnerData(data[0]?.MisionAndVision);
        setName(item);
      }
    }
    if (item === "Gallery") {
      if (data?.[0]?.links !== undefined) {
        setInnerData(data[0]?.links);
        setName(item);
      }
    }
    if (item === "Mission & Vision") {
      if (data?.[0]?.gallery !== undefined) {
        setInnerData(data[0]?.gallery);
        setName(item);
      }
    }
    if (item === "Notices Board") {
      if (data?.[0]?.notice !== undefined) {
        setInnerData(data[0]?.notice);
        setName(item);
      }
    }
    if (item === "Dean") {
      if (data?.[0]?.headOfDepartment !== undefined) {
        setInnerData(data[0]?.headOfDepartment);
        setName(item);
      }
    }
    if (item === "Infrastructure") {
      if (data?.[0]?.infrastructure !== undefined) {
        setInnerData(data[0]?.infrastructure);
        setName(item);
      }
    }
    if (item === "Learning Objective") {
      if (data?.[0]?.learningObjective !== undefined) {
        setInnerData(data[0]?.learningObjective);
        setName(item);
      }
    }
    if (item === "Best Practices") {
      if (data?.[0]?.bestPractices !== undefined) {
        setInnerData(data[0]?.bestPractices);
        setName(item);
      }
    }
    if (item === "University Position") {
      if (data?.[0]?.universityPosition !== undefined) {
        setInnerData(data[0]?.universityPosition);
        setName(item);
      }
    }
    if (item === "Research & Projects") {
      if (data?.[0]?.researchAndProjects !== undefined) {
        setInnerData(data[0]?.researchAndProjects);
        setName(item);
      }
    }
    if (item === "Events Organized") {
      if (data?.[0]?.eventOrgDeprtmt !== undefined) {
        setInnerData(data[0]?.eventOrgDeprtmt);
        setName(item);
      }
    }
    if (item === "Student Corner") {
      if (data?.[0]?.studentCorner !== undefined) {
        setInnerData(data[0]?.studentCorner);
        setName(item);
      }
    }
    if (item === "Faculty Members") {
      if (data?.[0]?.facultyOfMember !== undefined) {
        setInnerData(data[0]?.facultyOfMember);
        setName(item);
      }
    }
    if (item === "Senior Residents") {
      if (data?.[0]?.facultyOfResidents !== undefined) {
        setInnerData(data[0]?.facultyOfResidents);
        setName(item);
      }
    }

    //
  };

  useEffect(() => {
    // setActiveDepartmentId('')
  }, [activeDepartmentId]);

  return (
    <>
      <>
        <Row>
          {isEditItem ? (
            <Col lg={12}>
              <UpdateDepartment
                itemId={isEditItem}
                // parentId={id}
                // innerData={data ||[]}
                // name={name}
              />
            </Col>
          ) : (
            <Card>
              <Card.Body>
                <h4>Delete and Update Departments</h4>

                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Sr. N</th>
                      <th>Title</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(data || [])?.map((item: parentId, i) => (
                      <tr key={item?._id}>
                        <td>{i + 1}</td> {/* You can use i+1 as the index */}
                        <td>{item?.text}</td>
                        <td>
                          <Row className="gap-2">
                            {Role && Role === "Chess" ? (
                              <>
                                <Col lg={3}>
                                  <button
                                    onClick={() =>
                                      deleteItem(item?._id ?? "", i)
                                    }
                                    className="btn btn-danger"
                                    // disabled={buttonLoading}
                                    disabled={buttonLoading[i]}
                                  >
                                    {buttonLoading[i] ? "Deleting" : "Delete"}
                                  </button>
                                </Col>

                                <Col lg={3}>
                                  <button
                                    onClick={() => editItem(item?._id ?? "")}
                                    className="btn btn-primary"
                                  >
                                    Update
                                  </button>
                                </Col>
                              </>
                            ) : (
                              ""
                            )}

                            <Col lg={3}>
                              <button
                                onClick={() =>
                                  activeDepartment(
                                    item?._id ?? "",
                                    item?.text ?? ""
                                  )
                                }
                                className="btn btn-success"
                              >
                                Open
                              </button>
                            </Col>
                          </Row>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          )}
        </Row>
        <div
          style={{ width: "100%", height: "1px", backgroundColor: "red" }}
        ></div>
        {activeDepartmentId ? (
          <>
            <Row>
              <Col lg={12}>
                <h3>Choose the following item to work on it</h3>
                <div className="d-flex  flex-wrap w-100">
                  {(footerItems || []).map((item, index) => (
                    <div
                      style={{ fontSize: "20px", padding: "10px 20px" }}
                      key={index}
                    >
                      <Button onClick={() => showItem(item.name)}>
                        <h4 style={{ color: "white" }}>{item.name}</h4>
                      </Button>
                    </div>
                  ))}
                </div>
              </Col>
              <Col lg={12}>
                {data && (
                  <AddDepartmentItems
                    id={activeDepartmentId}
                    link={innerData}
                    name={name}
                    activeDepartmentName={activeDepartmentName}
                  />
                )}
              </Col>
            </Row>
          </>
        ) : (
          ""
        )}

        <Row>
          <Col lg={12}>
            {activeDepartmentId && data && (
              <DeleteDepartmentItems
                id={activeDepartmentId}
                link={innerData}
                name={name}
                activeDepartmentName={activeDepartmentName}
              />
            )}
          </Col>
        </Row>
      </>
    </>
  );
};

const UpdateDepartment: React.FC<parentId> = ({ itemId }) => {
  const [validated, setValidated] = useState<boolean>(false);
  const [data, setData] = useState<Parent[]>([]);
  const [text, setText] = useState("");
  const [content, setContent] = useState("");
  const [link, setLink] = useState("");
  const [image, setImage] = useState<File | null>(null);
  const [innerData, setInnerData] = useState([]);
  const [name, setName] = useState("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isResponse, setIsResponse] = useState("");
  const dispatch = useDispatch<AppDispatch>();

  const { user, userLoggedIn, loading, error, userId } = useSelector(
    (state: RootState) => ({
      user: state.Auth.user,
      loading: state.Auth.loading,
      error: state.Auth.error,
      userLoggedIn: state.Auth.userLoggedIn,
      userId: state.Auth._id,
    })
  );
  let profileId = user._id ? user._id : user.user ? user.user._id : "";
  let Role = user.hobbies ? user.hobbies : user.user.hobbies;
  let profileName = user.name ? user.name : user.user.name;
  let subDepartmentName = user.department
    ? user.department
    : user.user.department;

  const Key_Key = user.moniterd_ttl
    ? user.moniterd_ttl
    : user.user
    ? user.user.moniterd_ttl
    : "";

  const handleSubmit = async (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  //   ------------------------------------------ setting image in the input--------------------------------
  const handleBannerUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImage(e.target.files[0]);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const departmentName = "FON"; // Example dynamic parameter

        let innerUrl = "";
        if (Role === "Chess") {
          innerUrl = `/api/department/${departmentName}`;
        } else if (Role == "Music") {
          innerUrl = `/api/department/${subDepartmentName}/${departmentName}`;
        } else {
          innerUrl = `notforyou`;
        }
        const response = await api.fetchData(innerUrl);

        const data = await response.data;
        setInnerData(data);
        setLink(data[0]?.link);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData(); // Call the async function to fetch and set data
  }, []);

  const updateBanner = async (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      const formData = new FormData();
      formData.append("text", text);
      formData.append("content", content);
      formData.append("department", "FON");
      formData.append("modifiedBy", profileName);

      if (image) {
        formData.append("image", image);
      }
      try {
        const response = await api.updatePatchData(
          `/api/update/department/${itemId}/${profileId}/${Key_Key}`,
          formData
        );
        // console.log(response, "update response");
        if (response.status === 440) {
          alert("Session Expired");
          dispatch(logoutUser());
          window.location.href = ADMIN_LOGIN_BASE_URL;
        }
        if (response.status === 429) {
          // console.log("response---6566656", response)
          setShowModal(true);
        }
        // console.log("response--statue", response)
        if (response.status === 400) {
          setIsError(true);
          let errorMessage = await response.json();
          // console.log(response.status, "response.status");
          setIsResponse(errorMessage.message);
        }
        // Assuming you want to parse the JSON response
        if (response.status == 200 || response.status == 201) {
          alert("Updated");
          window.location.reload();
        }
      } catch (error) {
        // console.log(response.status,"response.status")
        console.error("Error during updating the departent:", error);
      }
    }
    // setValidated(true);
  };

  const cancle = () => {
    const userConfirmed = window.confirm("Cancled");
    if (userConfirmed) {
      window.location.reload();
    }
  };

  return (
    <>
      <Card>
        <Card.Body>
          {(innerData || [])
            .filter((item: Child) => {
              return item._id === itemId;
            })
            .map((filterItem: Child, index: number) => (
              <>
                <Form
                  style={{ width: "100%" }}
                  onSubmit={updateBanner}
                  encType="multipart/form-data"
                  key={index}
                >
                  <h4>Update Department</h4>
                  {isError && (
                    <Alert variant="danger" className="my-2">
                      {isResponse}
                    </Alert>
                  )}
                  {showModal && <AuthModal />}
                  {/* <> */}
                  <Form.Group>
                    <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                      <h5>Name of the Department</h5>
                    </Form.Label>
                    <Form.Control
                      className="accordion-item"
                      type="text"
                      placeholder={filterItem?.text}
                      defaultValue={filterItem?.text}
                      onChange={(e) => setText(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                      <h5> About Department</h5>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={filterItem?.content}
                      defaultValue={filterItem?.content}
                      onChange={(e) => setContent(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="d-flex pt-1 justify-content-start">
                      <h5>Page Backdrop</h5>
                    </Form.Label>
                    <Form.Control
                      type="file"
                      id="image"
                      name="desktopBannerUrl"
                      accept="image/*"
                      onChange={handleBannerUrl}
                    />
                  </Form.Group>

                  <Form.Group className="pt-2 pb-2">
                    <Row>
                      <Col lg={4}>
                        {/* Delete button */}
                        <Button type="submit">Update</Button>
                      </Col>
                    </Row>
                  </Form.Group>
                </Form>
                <Col lg={4}>
                  <button onClick={() => cancle()} className="btn btn-primary">
                    Cancle
                  </button>
                </Col>
              </>
            ))}
        </Card.Body>
      </Card>
    </>
  );
};
// -------------------------------add org item----------------    ?:string;

const AddDepartmentItems: React.FC<id> = ({
  id: id,
  link,
  name,
  activeDepartmentName,
}) => {
  const [validated, setValidated] = useState<boolean>(false);

  //  ---------- states for getting from user start from here
  const [personName, setPersonName] = useState("");
  const [title, setTitle] = useState("");
  const [introduction, setIntroduction] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");
  const [count, setCount] = useState(0);
  const [futurePlan, setFuturePlan] = useState("");
  const [researchBy, setResearchBy] = useState("");
  const [yearOfResearch, setYearOfResearch] = useState("");
  const [awards, setAwards] = useState("");
  const [url, setUrl] = useState("");
  //  for files
  const [doc, setDoc] = useState<File | null>(null);
  const [image, setImage] = useState<File | null>(null);
  const [imageOne, setImageOne] = useState<File | null>(null);
  const [imageTwo, setImageTwo] = useState<File | null>(null);
  const [imageThree, setImageThree] = useState<File | null>(null);
  const [imageFour, setImageFour] = useState<File | null>(null);
  const [imageFive, setImageFive] = useState<File | null>(null);
  const [imageSix, setImageSix] = useState<File | null>(null);
  // const [selectedDate, setSelectedDate] = useState(null); // State to store selected date
  const [selectedDate, setSelectedDate] = useState<Date | null | undefined>(
    null
  ); // Explicitly set the type
  const [selectedDateTo, setSelectedDateTo] = useState<Date | null | undefined>(
    null
  ); // Explicitly set the type
  const [price, setPrice] = useState("");

  //  ---------- states for getting from user ends here
  const [buttonLoading, setButtonLoding] = useState(false);
  const [activeDepartmentId, setActiveDepartmentId] = useState("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [he, setShe] = useState<boolean>(false);

  const [isError, setIsError] = useState<boolean>(false);
  const [isResponse, setIsResponse] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const { user, userLoggedIn, loading, error, userId } = useSelector(
    (state: RootState) => ({
      user: state.Auth.user,
      loading: state.Auth.loading,
      error: state.Auth.error,
      userLoggedIn: state.Auth.userLoggedIn,
      userId: state.Auth._id,
    })
  );
  let profileId = user._id ? user._id : user.user ? user.user._id : "";
  const Key_Key = user.moniterd_ttl
    ? user.moniterd_ttl
    : user.user
    ? user.user.moniterd_ttl
    : "";
  let profileName = user.name ? user.name : user.user.name;

  const handleSubmit = async (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  const AddDepartmentItems = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    const form = event.currentTarget;
    setButtonLoding(true);
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      const formData = new FormData();
      //  ---------- states for getting from user start from here
      formData.append("name", personName);
      formData.append("title", title);
      formData.append("introduction", introduction);
      formData.append("summary", summary);
      formData.append("content", content);
      formData.append("futurePlan", futurePlan);
      formData.append("researchBy", researchBy);
      formData.append("yearOfResearch", yearOfResearch);
      formData.append("awards", awards);
      formData.append("url", url);
      // formData.append("selectedDate", selectedDate);
      if (selectedDate) {
        formData.append("selectedDate", selectedDate.toString());
      }
      if (selectedDateTo) {
        formData.append("selectedDateTo", selectedDateTo.toString());
      }
      if (doc) {
        formData.append("doc", doc);
      }
      if (image) {
        formData.append("image", image);
      }
      if (imageOne) {
        formData.append("imageOne", imageOne);
      }
      if (imageTwo) {
        formData.append("imageTwo", imageTwo);
      }
      if (imageThree) {
        formData.append("imageThree", imageThree);
      }
      if (imageFour) {
        formData.append("imageFour", imageFour);
      }
      if (imageFive) {
        formData.append("imageFive", imageFive);
      }
      if (imageSix) {
        formData.append("imageSix", imageSix);
      }
      formData.append("price", price);
      //  ---------- states for getting from user ends here
      formData.append("createdBy", profileName);
      formData.append("department", "FON");

      try {
        let url = "";
        if (name === "About Faculty") {
          url = `/api/aboutdepartment`;
        } else if (name === "Dean") {
          url = `/api/headOfDepartment`;
        } else if (name === "Faculty & Staff") {
          url = `/api/facultyandstaff`;
        } else if (name === "Faculty Members") {
          url = `/api/facultyOfMember`;
        } else if (name === "Senior Residents") {
          url = `/api/facultyOfResidents`;
        } else if (name === "Mission & Vision") {
          url = `/api/misionandvision`;
        } else if (name === "Infrastructure") {
          url = `/api/infrastructure`;
        } else if (name === "Learning Objective") {
          url = `/api/LearningObjective`;
        } else if (name === "Best Practices") {
          url = `/api/BestPractices`;
        } else if (name === "University Position") {
          url = `/api/universityposition`;
        } else if (name === "Events Organized") {
          url = `/api/EventOrgDeprtmt`;
        } else if (name === "Student Corner") {
          url = `/api/studentcorner`;
        } else if (name === "Gallery") {
          url = `/api/gallery`;
        } else if (name === "Notices Board") {
          url = `/api/notice`;
        } else if (name === "University Position") {
          url = `/api/UniversityPosition`;
        } else if (name === "Research & Projects") {
          url = `/api/ResearchAndProjects`;
        } else if (name === "Events Organized") {
          url = `/api/EventOrgDeprtmt`;
        } else if (name === "Student Corner") {
          url = `/api/StudentCorner`;
        }

        // else if (name === "Research & Publications") {
        //     url = `/api/researchandpublications`;
        // } else if (name === "Academics") {
        //     url = `/api/academics`;
        // } else if (name === "In Patient Services") {
        //     url = `/api/inpatientservice`;
        // } else if (name === "Out Patient Services") {
        //     url = `/api/outpatientservice`;
        // } else if (name === "Price List") {
        //     url = `/api/pricelist`;
        // } else if (name === "Links") {
        //     url = `/api/links`;
        // }

        const response = await api.createData(
          `${url}/${id}/${profileId}/${Key_Key}`,
          formData
        );
        // console.log("response from item department", response.status);
        if (response.status === 440) {
          alert("Session Expired");
          dispatch(logoutUser());
          window.location.href = ADMIN_LOGIN_BASE_URL;
        }

        if (response.status === 429) {
          // console.log("response---6566656", response)
          setShowModal(true);
        }
        // console.log("response--statue", response)

        if (response.status === 400) {
          setIsError(true);
          let errorMessage = await response.message;
          setIsResponse(errorMessage);
        }
        // Assuming you want to parse the JSON response
        if (response.status == 200 || response.status == 201) {
          alert("Added");
          window.location.reload();
          setButtonLoding(false);
        }
      } catch (error) {
        console.error("Error during edit the banner:", error);
      }
    }
    setValidated(true);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImage(e.target.files[0]);
    }
  };
  // image 1
  const handleImageOneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImageOne(e.target.files[0]);
    }
  };
  //  image 2
  const handleImageTwoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImageTwo(e.target.files[0]);
    }
  };
  //  image 3
  const handleImageThreeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImageThree(e.target.files[0]);
    }
  };
  // image 4
  const handleImageFourChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImageFour(e.target.files[0]);
    }
  };
  // image 5
  const handleImageFiveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImageFive(e.target.files[0]);
    }
  };
  // image 6
  const handleImageSixChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImageSix(e.target.files[0]);
    }
  };

  const handleDocUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setDoc(e.target.files[0]);
    }
  };

  // const handleDocumentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //     if (e.target.files && e.target.files.length > 0) {
  //         setDoc(e.target.files[0]);
  //     }
  // };

  const increaseCount = () => {
    if (count >= 6) {
      setCount(6);
    } else {
      setCount(count + 1);
    }
  };

  const decreaseCount = () => {
    if (count <= 0) {
      setCount(0);
    } else {
      setCount(count - 1);
    }
  };

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date ?? null);
  };
  const handleToDateChange = (date: Date | null) => {
    setSelectedDateTo(date ?? null);
  };

  useEffect(() => {
    const storedItemId = localStorage.getItem("parentId");
    setActiveDepartmentId(storedItemId ?? "");
    console.log("activeDepartmentId", activeDepartmentId);
  }, [id]);

  return (
    <>
      <Card>
        <Card.Body>
          <h4>
            {name ? "Add" : ""}{" "}
            <span style={{ fontSize: "20px", color: "red" }}>
              {name ? name : "Select from above to add"}
            </span>{" "}
            in{" "}
            <span style={{ fontSize: "20px", color: "red" }}>
              {" "}
              {activeDepartmentName}{" "}
            </span>
          </h4>
          {isError && (
            <Alert variant="danger" className="my-2">
              {isResponse}
            </Alert>
          )}
          {showModal && <AuthModal />}
          {name && (
            <Form
              style={{ width: "100%" }}
              onSubmit={AddDepartmentItems}
              encType="multipart/form-data"
            >
              {/* <> */}
              {/* name */}
              {name == "Faculty Members" ||
              name == "Senior Residents" ||
              name == "Student Corner" ||
              name == "Dean" ||
              name == "University Position" ||
              name == "Gallery" ||
              name == "Notices Board" ? (
                <>
                  <Form.Group>
                    <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                      <h5>
                        {/* {name == 'About Department' ? 'Name of the Department ' : name == 'Academics' ? 'Information' : name == 'Mission & Vision' ? 'Mission' : ''} <span style={{ color: "red" }}> *</span> */}
                        {name == "Faculty Members"
                          ? "Name "
                          : name == "Senior Residents"
                          ? "Name"
                          : name == "Student Corner"
                          ? "Name"
                          : name == "Dean"
                          ? "Name"
                          : name == "University Position"
                          ? "Name of the Position"
                          : name == "Gallery"
                          ? "Event Name"
                          : name == "Notices Board"
                          ? "Subject"
                          : ""}{" "}
                        <span style={{ color: "red" }}> *</span>
                      </h5>
                    </Form.Label>
                    <Form.Control
                      className="accordion-item"
                      type="text"
                      placeholder={
                        name == "Faculty Members"
                          ? "Name "
                          : name == "Senior Residents"
                          ? "Name"
                          : name == "Student Corner"
                          ? "Name"
                          : name == "Dean"
                          ? "Name"
                          : name == "University Position"
                          ? "Name of the Position"
                          : name == "Gallery"
                          ? "Event Name"
                          : name == "Notices Board"
                          ? "Subject"
                          : ""
                      }
                      // required
                      required={
                        name === "Faculty Members" ||
                        name === "Senior Residents" ||
                        name === "Student Corner" ||
                        name == "Dean" ||
                        name == "University Position" ||
                        name == "Notices Board"
                      }
                      defaultValue=""
                      onChange={(e) => setPersonName(e.target.value)}
                    />
                  </Form.Group>
                </>
              ) : (
                ""
              )}
              {/* title */}
              {name == "Events Organized" ||
              name == "Research & Projects" ||
              name == "About Faculty" ||
              name == "Infrastructure" ||
              name == "Best Practices" ||
              name == "Learning Objective" ||
              name == "Mission & Vision" ||
              name == "University Position" ? (
                <>
                  <Form.Group>
                    <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                      <h5>
                        {name == "Events Organized"
                          ? "Title"
                          : name == "Research & Projects"
                          ? "Title "
                          : name == "About Faculty"
                          ? "Title "
                          : name == "Infrastructure"
                          ? "Title"
                          : name == "Best Practices"
                          ? "Title"
                          : name == "Learning Objective"
                          ? "Subject"
                          : name == "Mission & Vision"
                          ? " Mission"
                          : name == "University Position"
                          ? " From"
                          : ""}{" "}
                        <span style={{ color: "red" }}> *</span>
                      </h5>
                    </Form.Label>
                    <Form.Control
                      className="accordion-item"
                      type="text"
                      placeholder={
                        name == "Events Organized"
                          ? "Title"
                          : name == "Research & Projects"
                          ? "Title"
                          : name == "About Faculty"
                          ? "Title "
                          : name == "Infrastructure"
                          ? "Title"
                          : name == "Best Practices"
                          ? "Title"
                          : name == "Learning Objective"
                          ? "Subject"
                          : name == "Mission & Vision"
                          ? " Mission"
                          : name == "University Position"
                          ? " From"
                          : ""
                      }
                      // required
                      required={
                        name == "Events Organized" ||
                        name == "Research & Projects" ||
                        name === "About Faculty" ||
                        name === "Infrastructure" ||
                        name === "Best Practices" ||
                        name == "Learning Objective" ||
                        name == "Mission & Vision" ||
                        name == "University Position"
                      }
                      defaultValue=""
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Form.Group>
                </>
              ) : (
                ""
              )}
              {/* introduction */}
              {name == "About Faculty" ||
              name == "Infrastructure" ||
              name == "Dean" ||
              name == "Events Organized" ||
              name == "Faculty Members" ||
              name == "Senior Residents" ||
              name == "Mission & Vision" ||
              name == "University Position" ? (
                <>
                  <Form.Group>
                    <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                      <h5>
                        {/* Name of Researcher <span style={{ color: "red" }}> *</span> */}
                        {/* Introduction  <span style={{ color: "red" }}> *</span> */}
                        {name == "About Faculty"
                          ? "Introduction "
                          : name == "Infrastructure"
                          ? "Introduction"
                          : name == "Events Organized"
                          ? "Introduction"
                          : name == "Dean"
                          ? "About Head"
                          : name == "Faculty Members"
                          ? "About member"
                          : name == "Senior Residents"
                          ? "About Senior Residents"
                          : name == "Mission & Vision"
                          ? "Vision"
                          : name == "University Position"
                          ? "To"
                          : ""}{" "}
                        <span style={{ color: "red" }}> *</span>
                      </h5>
                    </Form.Label>
                    <Form.Control
                      className="accordion-item"
                      type="text"
                      placeholder={
                        name == "About Faculty"
                          ? "Introduction "
                          : name == "Infrastructure"
                          ? "Introduction"
                          : name == "Events Organized"
                          ? "Introduction"
                          : name == "Dean"
                          ? "About Head"
                          : name == "Faculty Members"
                          ? "About member"
                          : name == "Senior Residents"
                          ? "About Senior Residents"
                          : name == "Mission & Vision"
                          ? "Vision"
                          : name == "University Position"
                          ? "To"
                          : ""
                      }
                      required={
                        name === "About Faculty" ||
                        name === "Infrastructure" ||
                        name == "Events Organized" ||
                        name == "Dean" ||
                        name == "Faculty Members" ||
                        name == "Mission & Vision" ||
                        name == "University Position"
                      }
                      defaultValue=""
                      onChange={(e) => setIntroduction(e.target.value)}
                    />
                  </Form.Group>
                </>
              ) : (
                ""
              )}
              {/*   Abstract/Summary */}
              {name === "Best Practices" || name == "Research & Projects" ? (
                <>
                  <Form.Group>
                    <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                      <h5>
                        Abstract/Summary{" "}
                        <span style={{ color: "red" }}> *</span>
                      </h5>
                    </Form.Label>
                    <Form.Control
                      className="accordion-item"
                      type="text"
                      placeholder="Abstract/Summary"
                      required={
                        name === "Best Practices" ||
                        name == "Research & Projects"
                      }
                      defaultValue=""
                      onChange={(e) => setSummary(e.target.value)}
                    />
                  </Form.Group>
                </>
              ) : (
                ""
              )}

              {/* Content */}
              {name == "About Faculty" || name == "Mission & Vision" ? (
                <>
                  <Form.Group>
                    <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                      <h5>
                        {/* Content */}
                        {/* {name == '' ? 'Content ' : ''} <span style={{ color: "red" }}> *</span> */}
                        {name == "About Faculty"
                          ? "Content"
                          : name == "Mission & Vision"
                          ? " Core Values"
                          : ""}{" "}
                        {name == "Mission & Vision" ? (
                          <span style={{ color: "red" }}> *</span>
                        ) : (
                          ""
                        )}
                      </h5>
                    </Form.Label>
                    <Form.Control
                      className="accordion-item"
                      type="text"
                      // placeholder="Content"
                      placeholder={
                        name == "About Faculty"
                          ? "Content "
                          : name == "Mission & Vision"
                          ? "Core Values"
                          : ""
                      }
                      required={name == "Mission & Vision"}
                      defaultValue=""
                      onChange={(e) => setContent(e.target.value)}
                    />
                  </Form.Group>
                </>
              ) : (
                ""
              )}
              {/*  Year*/}
              {name == "Dean" ? (
                <>
                  <Form.Group>
                    <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                      <h5>
                        {/* {name == 'Mission & Vision' ? 'Vision' : name == 'Academics' ? 'Teaching Programs' : ''}  <span style={{ color: "red" }}> *</span> */}
                        {name == "Dean" ? "Year" : ""}{" "}
                        <span style={{ color: "red" }}> *</span>
                      </h5>
                    </Form.Label>
                    <Form.Control
                      className="accordion-item"
                      type="text"
                      required={name === "Dean"}
                      placeholder="Year of Research"
                      defaultValue=""
                      onChange={(e) => setYearOfResearch(e.target.value)}
                    />
                  </Form.Group>
                </>
              ) : (
                ""
              )}

              {/*  from date */}
              {name == "Events Organized" || name == "Notices Board" ? (
                <>
                  <Form.Group>
                    <Form.Label className="d-flex pt-1 justify-content-start">
                      <h5>
                        {name == "Events Organized"
                          ? "Event Date"
                          : name == "Notices Board"
                          ? "From"
                          : ""}
                      </h5>{" "}
                      {name == "Events Organized" || name == "Notices Board" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </Form.Label>
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      dateFormat="MM/dd/yyyy"
                      placeholderText="Select a date"
                    />
                  </Form.Group>
                </>
              ) : (
                ""
              )}

              {/* To date  */}
              {name == "Notices Board" ? (
                <>
                  <Form.Group>
                    <Form.Label className="d-flex pt-1 justify-content-start">
                      <h5>{name == "Notices Board" ? "To" : ""}</h5>{" "}
                      {name == "Notices Board" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </Form.Label>
                    <DatePicker
                      selected={selectedDateTo}
                      onChange={handleToDateChange}
                      dateFormat="MM/dd/yyyy"
                      placeholderText="Select a date"
                    />
                  </Form.Group>
                </>
              ) : (
                ""
              )}

              {/*  Doc */}
              {name == "Dean" ||
              name == "Faculty Members" ||
              name == "Senior Residents" ||
              name == "Learning Objective" ||
              name == "Notices Board" ? (
                <>
                  <Form.Group>
                    <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                      <h5>
                        {/* {name == 'Mission & Vision' ? 'Vision' : name == 'Academics' ? 'Teaching Programs' : ''}  <span style={{ color: "red" }}> *</span> */}
                        {name == "Dean"
                          ? "Cv"
                          : name == "Faculty Members"
                          ? "Cv"
                          : name == "Senior Residents"
                          ? "Cv"
                          : name == "Learning Objective"
                          ? "Document"
                          : name == "Notices Board"
                          ? "Document"
                          : ""}{" "}
                        <span style={{ color: "red" }}> *</span>
                      </h5>
                    </Form.Label>
                    <Form.Control
                      type="file"
                      id="doc"
                      name="doc"
                      //  accept="image/*"
                      required={
                        name == "Learning Objective" ||
                        name == "Notices Board" ||
                        name == "Faculty Members" ||
                        name == "Senior Residents" ||
                        name == "Dean"
                      }
                      accept=".pdf"
                      onChange={handleDocUrl}
                    />
                  </Form.Group>
                </>
              ) : (
                ""
              )}

              {/* Buttons for inc or dec images */}
              {name == "Events Organized" ||
              name == "Best Practices" ||
              name == "Research & Projects" ||
              name == "Infrastructure" ? (
                <Row className="pt-2 lg-2">
                  <Col>
                    <h5>Inc and dec the Number of images </h5>
                  </Col>
                  <Col>
                    {count != 6 ? (
                      <Button onClick={() => increaseCount()}>+</Button>
                    ) : (
                      ""
                    )}
                  </Col>

                  <Col>
                    {count != 0 ? (
                      <Button
                        className="bg-danger"
                        onClick={() => decreaseCount()}
                      >
                        -
                      </Button>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              ) : (
                ""
              )}

              {/* Image */}
              {name == "Dean" ||
              name == "Faculty Members" ||
              name == "Senior Residents" ||
              name == "About Faculty" ||
              name == "Gallery" ? (
                <>
                  <Form.Group>
                    <Form.Label className="d-flex pt-1 justify-content-start">
                      {/* <h5>Image  <span style={{ color: "red" }}> *</span></h5> */}
                      {name == "Gallery" ? "Gallery Image" : "Image"}{" "}
                      {name == "Gallery" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      id="image"
                      name="bannerUrl"
                      required={name == "Gallery"}
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </Form.Group>
                </>
              ) : (
                ""
              )}

              {/*  image one  */}
              {count >= 1 ? (
                <>
                  {[
                    "Events Organized",
                    "Infrastructure",
                    "Best Practices",
                    "Research & Projects",
                  ].includes(name) && (
                    <Form.Group>
                      <Form.Label className="d-flex pt-1 justify-content-start">
                        <h5>Image 1</h5>
                      </Form.Label>
                      <Form.Control
                        type="file"
                        id="image"
                        name="bannerUrl"
                        accept="image/*"
                        onChange={handleImageOneChange}
                      />
                    </Form.Group>
                  )}
                </>
              ) : (
                ""
              )}

              {/* image Two */}
              {count >= 2 ? (
                <>
                  {[
                    "Events Organized",
                    "Infrastructure",
                    "Best Practices",
                    "Research & Projects",
                  ].includes(name) && (
                    <Form.Group>
                      <Form.Label className="d-flex pt-1 justify-content-start">
                        <h5>Image 2</h5>
                      </Form.Label>
                      <Form.Control
                        type="file"
                        id="image"
                        name="bannerUrl"
                        accept="image/*"
                        onChange={handleImageTwoChange}
                      />
                    </Form.Group>
                  )}
                </>
              ) : (
                ""
              )}
              {/* image two  */}

              {/* image three  */}
              {count >= 3 ? (
                <>
                  {[
                    "Events Organized",
                    "Infrastructure",
                    "Best Practices",
                    "Research & Projects",
                  ].includes(name) && (
                    <Form.Group>
                      <Form.Label className="d-flex pt-1 justify-content-start">
                        <h5>Image 3</h5>
                      </Form.Label>
                      <Form.Control
                        type="file"
                        id="image"
                        name="bannerUrl"
                        accept="image/*"
                        onChange={handleImageThreeChange}
                      />
                    </Form.Group>
                  )}
                </>
              ) : (
                ""
              )}

              {/* image four  */}
              {count >= 4 ? (
                <>
                  {[
                    "Events Organized",
                    "Infrastructure",
                    "Best Practices",
                    "Research & Projects",
                  ].includes(name) && (
                    <Form.Group>
                      <Form.Label className="d-flex pt-1 justify-content-start">
                        <h5>Image 4</h5>
                      </Form.Label>
                      <Form.Control
                        type="file"
                        id="image"
                        name="bannerUrl"
                        accept="image/*"
                        onChange={handleImageFourChange}
                      />
                    </Form.Group>
                  )}
                </>
              ) : (
                ""
              )}

              {/*  image five */}
              {count >= 5 ? (
                <>
                  {[
                    "Events Organized",
                    "Infrastructure",
                    "Best Practices",
                    "Research & Projects",
                  ].includes(name) && (
                    <Form.Group>
                      <Form.Label className="d-flex pt-1 justify-content-start">
                        <h5>Image 5</h5>
                      </Form.Label>
                      <Form.Control
                        type="file"
                        id="image"
                        name="bannerUrl"
                        accept="image/*"
                        onChange={handleImageFiveChange}
                      />
                    </Form.Group>
                  )}
                </>
              ) : (
                ""
              )}

              {/*  image six */}
              {count >= 6 ? (
                <>
                  {[
                    "Events Organized",
                    "Infrastructure",
                    "Best Practices",
                    "Research & Projects",
                  ].includes(name) && (
                    <Form.Group>
                      <Form.Label className="d-flex pt-1 justify-content-start">
                        <h5>Image 6</h5>
                      </Form.Label>
                      <Form.Control
                        type="file"
                        id="image"
                        name="bannerUrl"
                        accept="image/*"
                        onChange={handleImageSixChange}
                      />
                    </Form.Group>
                  )}
                </>
              ) : (
                ""
              )}

              {he ? (
                <>
                  {/* Fututre Plans */}
                  <Form.Group>
                    <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                      <h5>Fututre Plans</h5>
                    </Form.Label>
                    <Form.Control
                      className="accordion-item"
                      type="text"
                      placeholder="Fututre Plans"
                      defaultValue=""
                      onChange={(e) => setFuturePlan(e.target.value)}
                    />
                  </Form.Group>
                  {/*  Research by */}
                  <Form.Group>
                    <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                      <h5>
                        {/* Year <span style={{ color: "red" }}> *</span> */}
                        Research by
                      </h5>
                    </Form.Label>
                    <Form.Control
                      className="accordion-item"
                      type="text"
                      placeholder="Year of publication"
                      required
                      defaultValue=""
                      onChange={(e) => setResearchBy(e.target.value)}
                    />
                  </Form.Group>

                  {/* Awards */}
                  <Form.Group>
                    <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                      <h5>
                        {/* {name == 'Mission & Vision' ? ' Core Value' : name == 'Academics' ? 'Addition of new faculty for Teaching' : ''} */}
                        Awards
                      </h5>
                    </Form.Label>
                    <Form.Control
                      className="accordion-item"
                      type="text"
                      // placeholder={name == 'Mission & Vision' ? ' Core Value' : name == 'Academics' ? 'Addition of new faculty for Teaching' : ''}
                      placeholder="Awards"
                      defaultValue=""
                      onChange={(e) => setAwards(e.target.value)}
                    />
                  </Form.Group>
                  {/*  Important url */}
                  <Form.Group>
                    <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                      <h5>Important url</h5>
                    </Form.Label>
                    <Form.Control
                      className="accordion-item"
                      type="text"
                      placeholder="Url"
                      required
                      defaultValue=""
                      onChange={handleDocUrl}
                    />
                  </Form.Group>

                  {/*   Url */}
                  <Form.Group>
                    <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                      <h5>Url</h5>
                    </Form.Label>
                    <Form.Control
                      className="accordion-item"
                      type="text"
                      placeholder="url"
                      defaultValue=""
                      onChange={(e) => setUrl(e.target.value)}
                    />
                  </Form.Group>
                  {/*  Price */}
                  <Form.Group>
                    <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                      <h5>Price</h5>
                    </Form.Label>
                    <Form.Control
                      className="accordion-item"
                      type="text"
                      placeholder="Price"
                      defaultValue=""
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </Form.Group>
                </>
              ) : (
                ""
              )}

              <Form.Group className="pt-2 pb-2">
                <Button type="submit" disabled={buttonLoading}>
                  {buttonLoading ? "Adding" : "Add"}
                </Button>
              </Form.Group>
            </Form>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

const UpdateDepartmentItems: React.FC<Props> = ({
  itemId,
  parentId,
  innerdata,
  name,
}) => {
  const [validated, setValidated] = useState<boolean>(false);
  const [serviceSection, setServiceSection] = useState<Parent[]>([]);

  const [showCom, setShowCom] = useState(true);

  const [personName, setPersonName] = useState("");
  const [title, setTitle] = useState("");
  const [introduction, setIntroduction] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");
  const [count, setCount] = useState(0);
  const [futurePlan, setFuturePlan] = useState("");
  const [researchBy, setResearchBy] = useState("");
  const [yearOfResearch, setYearOfResearch] = useState("");
  const [awards, setAwards] = useState("");
  const [url, setUrl] = useState("");
  //  for files
  const [doc, setDoc] = useState<File | null>(null);
  const [image, setImage] = useState<File | null>(null);
  const [imageOne, setImageOne] = useState<File | null>(null);
  const [imageTwo, setImageTwo] = useState<File | null>(null);
  const [imageThree, setImageThree] = useState<File | null>(null);
  const [imageFour, setImageFour] = useState<File | null>(null);
  const [imageFive, setImageFive] = useState<File | null>(null);
  const [imageSix, setImageSix] = useState<File | null>(null);
  // const [selectedDate, setSelectedDate] = useState(null); // State to store selected date
  const [selectedDate, setSelectedDate] = useState<Date | null>(null); // Explicitly set the type
  const [selectedDateTo, setSelectedDateTo] = useState<Date | null>(null); // Explicitly set the type
  const [price, setPrice] = useState("");

  //  ---------- states for getting from user ends here
  const [buttonLoading, setButtonLoding] = useState(false);
  const [activeDepartmentId, setActiveDepartmentId] = useState("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [he, setShe] = useState<boolean>(false);

  const [isError, setIsError] = useState<boolean>(false);
  const [isResponse, setIsResponse] = useState("");

  // const [image, setImage] = useState<File | null>(null);
  const dispatch = useDispatch<AppDispatch>();

  const { user, userLoggedIn, loading, error, userId } = useSelector(
    (state: RootState) => ({
      user: state.Auth.user,
      loading: state.Auth.loading,
      error: state.Auth.error,
      userLoggedIn: state.Auth.userLoggedIn,
      userId: state.Auth._id,
    })
  );
  let profileId = user._id ? user._id : user.user ? user.user._id : "";
  const Key_Key = user.moniterd_ttl
    ? user.moniterd_ttl
    : user.user
    ? user.user.moniterd_ttl
    : "";
  let profileName = user.name ? user.name : user.user.name;

  const handleSubmit = async (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const cancle = () => {
    const userConfirmed = window.confirm("Cancled");

    if (userConfirmed) {
      window.location.reload();
    }
  };

  const UpdateProtySpotItem = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      const formData = new FormData();
      //  ---------- states for getting from user start from here
      formData.append("name", personName);
      formData.append("title", title);
      formData.append("introduction", introduction);
      formData.append("summary", summary);
      formData.append("content", content);
      formData.append("futurePlan", futurePlan);
      formData.append("researchBy", researchBy);
      formData.append("yearOfResearch", yearOfResearch);
      formData.append("awards", awards);
      formData.append("url", url);
      // formData.append("selectedDate", selectedDate);
      if (selectedDate) {
        formData.append(
          "selectedDate",
          typeof selectedDate != "string" ? "" : selectedDate
        );
      }
      if (selectedDateTo) {
        formData.append(
          "selectedDateTo",
          typeof selectedDateTo != "string" ? "" : selectedDateTo
        );
      }
      if (doc) {
        formData.append("doc", doc);
      }
      if (image) {
        formData.append("image", image);
      }
      if (imageOne) {
        formData.append("imageOne", imageOne);
      }
      if (imageTwo) {
        formData.append("imageTwo", imageTwo);
      }
      if (imageThree) {
        formData.append("imageThree", imageThree);
      }
      if (imageFour) {
        formData.append("imageFour", imageFour);
      }
      if (imageFive) {
        formData.append("imageFive", imageFive);
      }
      if (imageSix) {
        formData.append("imageSix", imageSix);
      }
      formData.append("price", price);
      //  ---------- states for getting from user ends here
      formData.append("modifiedBy", profileName);
      formData.append("department", "FON");

      try {
        let url = "";
        if (name === "About Faculty") {
          url = `/api/update/aboutdepartment`;
        } else if (name === "Faculty & Staff") {
          url = `/api/facultyandstaff`;
        } else if (name === "Research & Publications") {
          url = `/api/update/researchandpublications`;
        } else if (name === "Academics") {
          url = `/api/update/academics`;
        } else if (name === "In Patient Services") {
          url = `/api/update/inpatientservice`;
        } else if (name === "Out Patient Services") {
          url = `/api/update/outpatientservice`;
        } else if (name === "Price List") {
          url = `/api/update/pricelist`;
        } else if (name === "Links") {
          url = `/apiupdate/links`;
        } else if (name === "Gallery") {
          url = `/api/update/gallery`;
        } else if (name === "Mission & Vision") {
          url = `/api/update/misionandvision`;
        } else if (name === "Notices Board") {
          url = `/api/update/notice`;
        } else if (name === "Dean") {
          url = `/api/update/headOfDepartment`;
        } else if (name === "Infrastructure") {
          url = `/api/update/infrastructure`;
        } else if (name === "Learning Objective") {
          url = `/api/update/LearningObjective`;
        } else if (name === "Best Practices") {
          url = `/api/update/BestPractices`;
        } else if (name === "University Position") {
          url = `/api/update/UniversityPosition`;
        } else if (name === "Research & Projects") {
          url = `/api/update/ResearchAndProjects`;
        } else if (name === "Events Organized") {
          url = `/api/update/EventOrgDeprtmt`;
        } else if (name === "Student Corner") {
          url = `/api/update/StudentCorner`;
        } else if (name === "Faculty Members") {
          url = `/api/update/facultyOfMember`;
        } else if (name === "Senior Residents") {
          url = `/api/update/facultyOfResidents`;
        }

        const response = await api.updatePatchData(
          `${url}/${parentId}/itemid/${itemId}/${profileId}/${Key_Key}`,
          formData
        );

        console.log("response", response.status);

        if (response.status === 440) {
          alert("Session Expired");
          dispatch(logoutUser());
          window.location.href = ADMIN_LOGIN_BASE_URL;
        }

        if (response.status === 429) {
          // console.log("response---6566656", response)
          setShowModal(true);
        }
        // console.log("response--statue", response)

        if (response.status == 400) {
          setIsError(true);
          let errorMessage = await response.message;
          setIsResponse(errorMessage);
        }

        // Assuming you want to parse the JSON response
        if (response.status == 200 || response.status == 201) {
          alert("updated");
          window.location.reload();
        }
      } catch (error) {
        console.error("Error during edit the banner:", error);
      }
    }
    setValidated(true);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImage(e.target.files[0]);
    }
  };
  // image 1
  const handleImageOneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImageOne(e.target.files[0]);
    }
  };
  //  image 2
  const handleImageTwoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImageTwo(e.target.files[0]);
    }
  };
  //  image 3
  const handleImageThreeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImageThree(e.target.files[0]);
    }
  };
  // image 4
  const handleImageFourChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImageFour(e.target.files[0]);
    }
  };
  // image 5
  const handleImageFiveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImageFive(e.target.files[0]);
    }
  };
  // image 6
  const handleImageSixChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImageSix(e.target.files[0]);
    }
  };

  const handleDocUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setDoc(e.target.files[0]);
    }
  };

  // const handleDocumentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //     if (e.target.files && e.target.files.length > 0) {
  //         setDoc(e.target.files[0]);
  //     }
  // };

  const increaseCount = () => {
    if (count >= 6) {
      setCount(6);
    } else {
      setCount(count + 1);
    }
  };

  const decreaseCount = () => {
    if (count <= 0) {
      setCount(0);
    } else {
      setCount(count - 1);
    }
  };

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date ?? null);
  };
  const handleToDateChange = (date: Date | null) => {
    setSelectedDateTo(date ?? null);
  };

  useEffect(() => {}, [itemId]);

  return (
    <>
      {isError && (
        <Alert variant="danger" className="my-2">
          {isResponse}
        </Alert>
      )}
      {showModal && <AuthModal />}
      <Card>
        <Card.Body>
          {(innerdata || [])
            .filter((item) => {
              return item._id === itemId;
            })
            .map((filterItem, index) => (
              <Form
                style={{ width: "100%" }}
                onSubmit={UpdateProtySpotItem}
                encType="multipart/form-data"
                key={index}
              >
                <h4>Update Details</h4>
                {/* <> */}

                {/* <> */}
                {/* name */}
                {name == "Faculty Members" ||
                name == "Senior Residents" ||
                name == "Student Corner" ||
                name == "Dean" ||
                name == "University Position" ||
                name == "Gallery" ||
                name == "Notices Board" ? (
                  <>
                    <Form.Group>
                      <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                        <h5>
                          {/* {name == 'About Department' ? 'Name of the Department ' : name == 'Academics' ? 'Information' : name == 'Mission & Vision' ? 'Mission' : ''} <span style={{ color: "red" }}> *</span> */}
                          {name == "Faculty Members"
                            ? "Name "
                            : name == "Senior Residents"
                            ? "Name"
                            : name == "Student Corner"
                            ? "Name"
                            : name == "Dean"
                            ? "Name"
                            : name == "University Position"
                            ? "Name of the Position"
                            : name == "Gallery"
                            ? "Event Name"
                            : name == "Notices Board"
                            ? "Subject"
                            : ""}
                        </h5>
                      </Form.Label>
                      <Form.Control
                        className="accordion-item"
                        type="text"
                        placeholder={
                          name == "Faculty Members"
                            ? "Name "
                            : name == "Senior Residents"
                            ? "Name"
                            : name == "Student Corner"
                            ? "Name"
                            : name == "Dean"
                            ? "Name"
                            : name == "University Position"
                            ? "Name of the Position"
                            : name == "Gallery"
                            ? "Event Name"
                            : name == "Notices Board"
                            ? "Subject"
                            : ""
                        }
                        // required
                        defaultValue={
                          name == "Faculty Members"
                            ? `${filterItem?.name}`
                            : name == "Senior Residents"
                            ? `${filterItem?.name}`
                            : name == "Student Corner"
                            ? `${filterItem?.name}`
                            : name == "Dean"
                            ? `${filterItem?.name}`
                            : name == "University Position"
                            ? `${filterItem?.name}`
                            : name == "Gallery"
                            ? `${filterItem?.name}`
                            : name == "Notices Board"
                            ? `${filterItem?.name}`
                            : ""
                        }
                        onChange={(e) => setPersonName(e.target.value)}
                      />
                    </Form.Group>
                  </>
                ) : (
                  ""
                )}
                {/* title */}
                {name == "Events Organized" ||
                name == "Research & Projects" ||
                name == "About Faculty" ||
                name == "Infrastructure" ||
                name == "Best Practices" ||
                name == "Learning Objective" ||
                name == "Mission & Vision" ||
                name == "University Position" ? (
                  <>
                    <Form.Group>
                      <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                        <h5>
                          {name == "Events Organized"
                            ? "Title"
                            : name == "Research & Projects"
                            ? "Title "
                            : name == "About Faculty"
                            ? "Title "
                            : name == "Infrastructure"
                            ? "Title"
                            : name == "Best Practices"
                            ? "Title"
                            : name == "Learning Objective"
                            ? "Subject"
                            : name == "Mission & Vision"
                            ? " Mission"
                            : name == "University Position"
                            ? " From"
                            : ""}
                        </h5>
                      </Form.Label>
                      <Form.Control
                        className="accordion-item"
                        type="text"
                        placeholder={
                          name == "Events Organized"
                            ? `${filterItem?.title}`
                            : name == "Research & Projects"
                            ? `${filterItem?.title}`
                            : name == "About Faculty"
                            ? `${filterItem?.title}`
                            : name == "Infrastructure"
                            ? `${filterItem?.title}`
                            : name == "Best Practices"
                            ? `${filterItem?.title}`
                            : name == "Learning Objective"
                            ? `${filterItem?.title}`
                            : name == "Mission & Vision"
                            ? `${filterItem?.mission}`
                            : name == "University Position"
                            ? `${filterItem?.title}`
                            : ""
                        }
                        // required
                        // required={name == 'Events Organized by Department' || name == 'Research & Projects' || name === 'About Department' || name === 'Infrastructure' || name === 'Best Practices' || name == 'Learning Objective' || name == 'Mission & Vision' || name == 'University Position'}
                        defaultValue={
                          name == "Events Organized"
                            ? `${filterItem?.title}`
                            : name == "Research & Projects"
                            ? `${filterItem?.title}`
                            : name == "About Faculty"
                            ? `${filterItem?.title}`
                            : name == "Infrastructure"
                            ? `${filterItem?.title}`
                            : name == "Best Practices"
                            ? `${filterItem?.title}`
                            : name == "Learning Objective"
                            ? `${filterItem?.title}`
                            : name == "Mission & Vision"
                            ? `${filterItem?.mission}`
                            : name == "University Position"
                            ? `${filterItem?.title}`
                            : ""
                        }
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </Form.Group>
                  </>
                ) : (
                  ""
                )}
                {/* introduction */}
                {name == "About Faculty" ||
                name == "Infrastructure" ||
                name == "Dean" ||
                name == "Events Organized" ||
                name == "Faculty Members" ||
                name == "Senior Residents" ||
                name == "Mission & Vision" ||
                name == "University Position" ? (
                  <>
                    <Form.Group>
                      <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                        <h5>
                          {/* Name of Researcher <span style={{ color: "red" }}> *</span> */}
                          {/* Introduction  <span style={{ color: "red" }}> *</span> */}
                          {name == "About Faculty"
                            ? "Introduction "
                            : name == "Infrastructure"
                            ? "Introduction"
                            : name == "Events Organized"
                            ? "Introduction"
                            : name == "Dean"
                            ? "About Head"
                            : name == "Faculty Members"
                            ? "About member"
                            : name == "Senior Residents"
                            ? "About Senior Residents"
                            : name == "Mission & Vision"
                            ? "Vision"
                            : name == "University Position"
                            ? "To"
                            : ""}
                        </h5>
                      </Form.Label>
                      <Form.Control
                        className="accordion-item"
                        type="text"
                        placeholder={
                          name == "About Faculty"
                            ? "Introduction "
                            : name == "Infrastructure"
                            ? "Introduction"
                            : name == "Events Organized"
                            ? "Introduction"
                            : name == "Dean"
                            ? "About Head"
                            : name == "Faculty Members"
                            ? "About member"
                            : name == "Senior Residents"
                            ? "About Senior Residents"
                            : name == "Mission & Vision"
                            ? "Vision"
                            : name == "University Position"
                            ? "To"
                            : ""
                        }
                        // required={name === 'About Department' || name === 'Infrastructure' || name == 'Events Organized by Department' || name == 'Head Of Department' || name == 'Faculty Members' || name == 'Mission & Vision' || name == 'University Position'}
                        defaultValue={
                          name == "About Faculty"
                            ? `${filterItem?.introduction}`
                            : name == "Infrastructure"
                            ? `${filterItem?.introduction}`
                            : name == "Events Organized"
                            ? `${filterItem?.introduction}`
                            : name == "Dean"
                            ? `${filterItem?.introduction}`
                            : name == "Faculty Members"
                            ? `${filterItem?.introduction}`
                            : name == "Senior Residents"
                            ? `${filterItem?.introduction}`
                            : name == "Mission & Vision"
                            ? `${filterItem?.vision}`
                            : name == "University Position"
                            ? `${filterItem?.introduction}`
                            : ""
                        }
                        onChange={(e) => setIntroduction(e.target.value)}
                      />
                    </Form.Group>
                  </>
                ) : (
                  ""
                )}
                {/*   Abstract/Summary */}
                {name === "Best Practices" || name == "Research & Projects" ? (
                  <>
                    <Form.Group>
                      <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                        <h5>
                          Abstract/Summary{" "}
                          <span style={{ color: "red" }}> *</span>
                        </h5>
                      </Form.Label>
                      <Form.Control
                        className="accordion-item"
                        type="text"
                        placeholder="Abstract/Summary"
                        // required={name === 'Best Practices' || name == 'Research & Projects'}
                        defaultValue={
                          name == "Best Practices"
                            ? `${filterItem?.summary}`
                            : ""
                        }
                        onChange={(e) => setSummary(e.target.value)}
                      />
                    </Form.Group>
                  </>
                ) : (
                  ""
                )}

                {/* Content */}
                {name == "About Faculty" || name == "Mission & Vision" ? (
                  <>
                    <Form.Group>
                      <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                        <h5>
                          {/* Content */}
                          {/* {name == '' ? 'Content ' : ''} <span style={{ color: "red" }}> *</span> */}
                          {name == "About Faculty"
                            ? "Content"
                            : name == "Mission & Vision"
                            ? " Core Values"
                            : ""}{" "}
                          {name == "Mission & Vision" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </h5>
                      </Form.Label>
                      <Form.Control
                        className="accordion-item"
                        type="text"
                        // placeholder="Content"
                        placeholder={
                          name == "About Faculty"
                            ? "Content "
                            : name == "Mission & Vision"
                            ? "Core Values"
                            : ""
                        }
                        // required={name == 'Mission & Vision'}
                        defaultValue={
                          name == "About Faculty"
                            ? `${filterItem?.content}`
                            : name == "Mission & Vision"
                            ? `${filterItem?.coreValues}`
                            : ""
                        }
                        onChange={(e) => setContent(e.target.value)}
                      />
                    </Form.Group>
                  </>
                ) : (
                  ""
                )}
                {/*  Year*/}
                {name == "Dean" ? (
                  <>
                    <Form.Group>
                      <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                        <h5>
                          {/* {name == 'Mission & Vision' ? 'Vision' : name == 'Academics' ? 'Teaching Programs' : ''}  <span style={{ color: "red" }}> *</span> */}
                          {name == "Dean" ? "Year" : ""}{" "}
                          <span style={{ color: "red" }}> *</span>
                        </h5>
                      </Form.Label>
                      <Form.Control
                        className="accordion-item"
                        type="text"
                        // required={name === 'Head Of Department'}
                        placeholder={
                          name == "Dean" ? `${filterItem?.yearOfResearch}` : ""
                        }
                        defaultValue={
                          name == "Dean" ? `${filterItem?.yearOfResearch}` : ""
                        }
                        onChange={(e) => setYearOfResearch(e.target.value)}
                      />
                    </Form.Group>
                  </>
                ) : (
                  ""
                )}

                {/*  from date */}
                {name == "Events Organized" || name == "Notices Board" ? (
                  <>
                    <Form.Group>
                      <Form.Label className="d-flex pt-1 justify-content-start">
                        <h5>
                          {name == "Events Organized"
                            ? "Event Date"
                            : name == "Notices Board"
                            ? "From"
                            : ""}
                        </h5>{" "}
                        {name == "Events Organized" ||
                        name == "Notices Board" ? (
                          <span style={{ color: "red" }}> *</span>
                        ) : (
                          ""
                        )}
                      </Form.Label>
                      <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        dateFormat="MM/dd/yyyy"
                        //  filterItem ? format(new Date(filterItem.date), 'MM/dd/yyyy') : '';
                        placeholderText={
                          name == "Events Organized"
                            ? `${filterItem?.date}`
                            : name == "Notices Board"
                            ? `${filterItem?.date}`
                            : ""
                        }
                      />
                    </Form.Group>
                  </>
                ) : (
                  ""
                )}

                {/* To date  */}
                {name == "Notices Board" ? (
                  <>
                    <Form.Group>
                      <Form.Label className="d-flex pt-1 justify-content-start">
                        <h5>{name == "Notices Board" ? "To" : ""}</h5>{" "}
                        {name == "Notices Board" ? (
                          <span style={{ color: "red" }}> *</span>
                        ) : (
                          ""
                        )}
                      </Form.Label>
                      <DatePicker
                        selected={selectedDateTo}
                        onChange={handleToDateChange}
                        dateFormat="MM/dd/yyyy"
                        placeholderText={
                          name == "Notices Board" ? `${filterItem?.dateTo}` : ""
                        }
                      />
                    </Form.Group>
                  </>
                ) : (
                  ""
                )}

                {/*  Doc */}
                {name == "Dean" ||
                name == "Faculty Members" ||
                name == "Senior Residents" ||
                name == "Learning Objective" ||
                name == "Notices Board" ? (
                  <>
                    <Form.Group>
                      <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                        <h5>
                          {/* {name == 'Mission & Vision' ? 'Vision' : name == 'Academics' ? 'Teaching Programs' : ''}  <span style={{ color: "red" }}> *</span> */}
                          {name == "Dean"
                            ? "Cv"
                            : name == "Faculty Members"
                            ? "Cv"
                            : name == "Senior Residents"
                            ? "Cv"
                            : name == "Learning Objective"
                            ? "Document"
                            : name == "Notices Board"
                            ? "Document"
                            : ""}{" "}
                          <span style={{ color: "red" }}> *</span>
                        </h5>
                      </Form.Label>
                      <Form.Control
                        type="file"
                        id="doc"
                        name="doc"
                        //  accept="image/*"
                        // required={name == 'Learning Objective' || name == 'Notices Board' || name == 'Faculty Members' || name == 'Senior Residents' || name == 'Head Of Department'}
                        accept=".pdf"
                        onChange={handleDocUrl}
                      />
                    </Form.Group>
                  </>
                ) : (
                  ""
                )}

                {/* Buttons for inc or dec images */}
                {name == "Events Organized" ||
                name == "Best Practices" ||
                name == "Research & Projects" ||
                name == "Infrastructure" ? (
                  <Row className="pt-2 lg-2">
                    <Col>
                      <h5>Inc and dec the Number of images </h5>
                    </Col>
                    <Col>
                      {count != 6 ? (
                        <Button onClick={() => increaseCount()}>+</Button>
                      ) : (
                        ""
                      )}
                    </Col>

                    <Col>
                      {count != 0 ? (
                        <Button
                          className="bg-danger"
                          onClick={() => decreaseCount()}
                        >
                          -
                        </Button>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                ) : (
                  ""
                )}

                {/* Image */}
                {name == "Dean" ||
                name == "Faculty Members" ||
                name == "Senior Residents" ||
                name == "About Faculty" ||
                name == "Gallery" ? (
                  <>
                    <Form.Group>
                      <Form.Label className="d-flex pt-1 justify-content-start">
                        {/* <h5>Image  <span style={{ color: "red" }}> *</span></h5> */}
                        {name == "Gallery" ? "Gallery Image" : "Image"}{" "}
                        {name == "Gallery" ? (
                          <span style={{ color: "red" }}> *</span>
                        ) : (
                          ""
                        )}
                      </Form.Label>
                      <Form.Control
                        type="file"
                        id="image"
                        name="bannerUrl"
                        required={name == "Gallery"}
                        accept="image/*"
                        onChange={handleFileChange}
                      />
                    </Form.Group>
                  </>
                ) : (
                  ""
                )}

                {/*  image one  */}
                {count >= 1 && name ? (
                  <>
                    {[
                      "Events Organized",
                      "Infrastructure",
                      "Best Practices",
                      "Research & Projects",
                    ].includes(name) && (
                      <Form.Group>
                        <Form.Label className="d-flex pt-1 justify-content-start">
                          <h5>Image 1</h5>
                        </Form.Label>
                        <Form.Control
                          type="file"
                          id="image"
                          name="bannerUrl"
                          accept="image/*"
                          onChange={handleImageOneChange}
                        />
                      </Form.Group>
                    )}
                  </>
                ) : (
                  ""
                )}

                {/* image Two */}
                {count >= 2 && name ? (
                  <>
                    {[
                      "Events Organized",
                      "Infrastructure",
                      "Best Practices",
                      "Research & Projects",
                    ].includes(name) && (
                      <Form.Group>
                        <Form.Label className="d-flex pt-1 justify-content-start">
                          <h5>Image 2</h5>
                        </Form.Label>
                        <Form.Control
                          type="file"
                          id="image"
                          name="bannerUrl"
                          accept="image/*"
                          onChange={handleImageTwoChange}
                        />
                      </Form.Group>
                    )}
                  </>
                ) : (
                  ""
                )}
                {/* image two  */}

                {/* image three  */}
                {count >= 3 && name ? (
                  <>
                    {[
                      "Events Organized",
                      "Infrastructure",
                      "Best Practices",
                      "Research & Projects",
                    ].includes(name) && (
                      <Form.Group>
                        <Form.Label className="d-flex pt-1 justify-content-start">
                          <h5>Image 3</h5>
                        </Form.Label>
                        <Form.Control
                          type="file"
                          id="image"
                          name="bannerUrl"
                          accept="image/*"
                          onChange={handleImageThreeChange}
                        />
                      </Form.Group>
                    )}
                  </>
                ) : (
                  ""
                )}

                {/* image four  */}
                {count >= 4 && name ? (
                  <>
                    {[
                      "Events Organized",
                      "Infrastructure",
                      "Best Practices",
                      "Research & Projects",
                    ].includes(name) && (
                      <Form.Group>
                        <Form.Label className="d-flex pt-1 justify-content-start">
                          <h5>Image 4</h5>
                        </Form.Label>
                        <Form.Control
                          type="file"
                          id="image"
                          name="bannerUrl"
                          accept="image/*"
                          onChange={handleImageFourChange}
                        />
                      </Form.Group>
                    )}
                  </>
                ) : (
                  ""
                )}

                {/*  image five */}
                {count >= 5 && name ? (
                  <>
                    {[
                      "Events Organized",
                      "Infrastructure",
                      "Best Practices",
                      "Research & Projects",
                    ].includes(name) && (
                      <Form.Group>
                        <Form.Label className="d-flex pt-1 justify-content-start">
                          <h5>Image 5</h5>
                        </Form.Label>
                        <Form.Control
                          type="file"
                          id="image"
                          name="bannerUrl"
                          accept="image/*"
                          onChange={handleImageFiveChange}
                        />
                      </Form.Group>
                    )}
                  </>
                ) : (
                  ""
                )}

                {/*  image six */}
                {count >= 6 && name ? (
                  <>
                    {[
                      "Events Organized",
                      "Infrastructure",
                      "Best Practices",
                      "Research & Projects",
                    ].includes(name) && (
                      <Form.Group>
                        <Form.Label className="d-flex pt-1 justify-content-start">
                          <h5>Image 6</h5>
                        </Form.Label>
                        <Form.Control
                          type="file"
                          id="image"
                          name="bannerUrl"
                          accept="image/*"
                          onChange={handleImageSixChange}
                        />
                      </Form.Group>
                    )}
                  </>
                ) : (
                  ""
                )}

                {he ? (
                  <>
                    {/* Fututre Plans */}
                    <Form.Group>
                      <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                        <h5>Fututre Plans</h5>
                      </Form.Label>
                      <Form.Control
                        className="accordion-item"
                        type="text"
                        placeholder="Fututre Plans"
                        defaultValue=""
                        onChange={(e) => setFuturePlan(e.target.value)}
                      />
                    </Form.Group>
                    {/*  Research by */}
                    <Form.Group>
                      <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                        <h5>
                          {/* Year <span style={{ color: "red" }}> *</span> */}
                          Research by
                        </h5>
                      </Form.Label>
                      <Form.Control
                        className="accordion-item"
                        type="text"
                        placeholder="Year of publication"
                        required
                        defaultValue=""
                        onChange={(e) => setResearchBy(e.target.value)}
                      />
                    </Form.Group>

                    {/* Awards */}
                    <Form.Group>
                      <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                        <h5>
                          {/* {name == 'Mission & Vision' ? ' Core Value' : name == 'Academics' ? 'Addition of new faculty for Teaching' : ''} */}
                          Awards
                        </h5>
                      </Form.Label>
                      <Form.Control
                        className="accordion-item"
                        type="text"
                        // placeholder={name == 'Mission & Vision' ? ' Core Value' : name == 'Academics' ? 'Addition of new faculty for Teaching' : ''}
                        placeholder="Awards"
                        defaultValue=""
                        onChange={(e) => setAwards(e.target.value)}
                      />
                    </Form.Group>
                    {/*  Important url */}
                    <Form.Group>
                      <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                        <h5>Important url</h5>
                      </Form.Label>
                      <Form.Control
                        className="accordion-item"
                        type="text"
                        placeholder="Url"
                        required
                        defaultValue=""
                        onChange={handleDocUrl}
                      />
                    </Form.Group>

                    {/*   Url */}
                    <Form.Group>
                      <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                        <h5>Url</h5>
                      </Form.Label>
                      <Form.Control
                        className="accordion-item"
                        type="text"
                        placeholder="url"
                        defaultValue=""
                        onChange={(e) => setUrl(e.target.value)}
                      />
                    </Form.Group>
                    {/*  Price */}
                    <Form.Group>
                      <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                        <h5>Price</h5>
                      </Form.Label>
                      <Form.Control
                        className="accordion-item"
                        type="text"
                        placeholder="Price"
                        defaultValue=""
                        onChange={(e) => setPrice(e.target.value)}
                      />
                    </Form.Group>
                  </>
                ) : (
                  ""
                )}

                <Row>
                  <Col lg={3}>
                    <Form.Group className="pt-2 pb-2">
                      <Button type="submit">Update</Button>
                    </Form.Group>
                  </Col>
                  <Col lg={3}>
                    <Form.Group className="pt-2 pb-2">
                      <Button
                        className="btn btn-danger"
                        type="button"
                        onClick={() => cancle()}
                      >
                        Cancle
                      </Button>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            ))}
        </Card.Body>
      </Card>
    </>
  );
};

// _________________________________________________________
// const DeleteOriStruItem = ({id}) => {
const DeleteDepartmentItems: React.FC<parentId> = ({
  id,
  link,
  name,
  activeDepartmentName,
}) => {
  const [validated, setValidated] = useState<boolean>(false);
  const [innerdata, setInnerData] = useState<Child[]>([]);
  const [isEditItem, setIsEditItem] = useState<string>("");
  const dispatch = useDispatch<AppDispatch>();
  const [buttonLoading, setButtonLoading] = useState<boolean[]>([]); // Array to hold loading state for each item
  const [showForAprov, setShowForAprov] = useState<boolean>(false);

  const { user, userLoggedIn, loading, error, userId } = useSelector(
    (state: RootState) => ({
      user: state.Auth.user,
      loading: state.Auth.loading,
      error: state.Auth.error,
      userLoggedIn: state.Auth.userLoggedIn,
      userId: state.Auth._id,
    })
  );
  let profileId = user._id ? user._id : user.user ? user.user._id : "";
  let Role = user.hobbies ? user.hobbies : user.user.hobbies;

  const Key_Key = user.moniterd_ttl
    ? user.moniterd_ttl
    : user.user
    ? user.user.moniterd_ttl
    : "";
  useEffect(() => {
    const fetchData = async () => {
      let url = "";
      if (name === "About Faculty") {
        url = `/api/aboutdepartment`;
      } else if (name === "Faculty & Staff") {
        url = `/api/facultyandstaff`;
      } else if (name === "Research & Publications") {
        url = `/api/researchandpublications`;
      } else if (name === "Academics") {
        url = `/api/academics`;
      } else if (name === "In Patient Services") {
        url = `/api/inpatientservice`;
      } else if (name === "Out Patient Services") {
        url = `/api/outpatientservice`;
      } else if (name === "Price List") {
        url = `/api/pricelist`;
      } else if (name === "Links") {
        url = `/api/links`;
      } else if (name === "Gallery") {
        url = `/api/gallery`;
      } else if (name === "Mission & Vision") {
        url = `/api/misionandvision`;
      } else if (name === "Notices Board") {
        url = `/api/notice`;
      } else if (name === "Dean") {
        url = `/api/headOfDepartment`;
      } else if (name === "Infrastructure") {
        url = `/api/infrastructure`;
      } else if (name === "Learning Objective") {
        url = `/api/LearningObjective`;
      } else if (name === "Best Practices") {
        url = `/api/BestPractices`;
      } else if (name === "University Position") {
        url = `/api/UniversityPosition`;
      } else if (name === "Research & Projects") {
        url = `/api/ResearchAndProjects`;
      } else if (name === "Events Organized") {
        url = `/api/EventOrgDeprtmt`;
      } else if (name === "Student Corner") {
        url = `/api/StudentCorner`;
      } else if (name === "Faculty Members") {
        url = `/api/facultyOfMember`;
      } else if (name === "Senior Residents") {
        url = `/api/facultyOfResidents`;
      }
      try {
        const departmentName = "FON";
        const response = await api.fetchData(`${url}/${id}/${departmentName}`);

        if (response.status === 440) {
          alert("Session Expired");
          dispatch(logoutUser());
          window.location.href = ADMIN_LOGIN_BASE_URL;
        }
        setInnerData(response.data.filteredData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData(); // Call the async function to fetch and set data
  }, [name, id]);
  // --delete org str item
  const deleteItem = async (itemId: string, index: number) => {
    setButtonLoading((prevState) => {
      const updatedLoadingState = [...prevState]; // Copying the loading state array
      updatedLoadingState[index] = true; // Setting loading state for the clicked item
      return updatedLoadingState;
    });
    try {
      let url = "";
      if (name === "About Faculty") {
        url = `/api/delete/aboutdepartment`;
      } else if (name === "Faculty & Staff") {
        url = `/api/delete/facultyandstaff`;
      } else if (name === "Research & Publications") {
        url = `/api/delete/researchandpublications`;
      } else if (name === "Academics") {
        url = `/api/delete/academics`;
      } else if (name === "In Patient Services") {
        url = `/api/delete/academics`;
      } else if (name === "Out Patient Services") {
        url = `/api/delete/academics`;
      } else if (name === "Price List") {
        url = `/api/delete/academics`;
      } else if (name === "Links") {
        url = `/api/delete/academics`;
      } else if (name === "Gallery") {
        url = `/api/delete/academics`;
      } else if (name === "Mission & Vision") {
        url = `/api/delete/academics`;
      } else if (name === "Notices Board") {
        url = `/api/delete/notice`;
      } else if (name === "Dean") {
        url = `/api/delete/headOfDepartment`;
      } else if (name === "Infrastructure") {
        url = `/api/delete/infrastructure`;
      } else if (name === "Learning Objective") {
        url = `/api/delete/LearningObjective`;
      } else if (name === "Best Practices") {
        url = `/api/delete/BestPractices`;
      } else if (name === "University Position") {
        url = `/api/delete/UniversityPosition`;
      } else if (name === "Research & Projects") {
        url = `/api/delete/ResearchAndProjects`;
      } else if (name === "Events Organized") {
        url = `/api/delete/EventOrgDeprtmt`;
      } else if (name === "Student Corner") {
        url = `/api/delete/StudentCorner`;
      } else if (name === "Faculty Members") {
        url = `/api/delete/facultyOfMember`;
      } else if (name === "Senior Residents") {
        url = `/api/delete/facultyOfResidents`;
      }

      const formData = new FormData();
      formData.append("facultyOfMedicineModel", "facultyOfMedicineModel");
      const departmentName = "FON";
      const response = await api.updatePatchDataDelete(
        `${url}/${id}/itemid/${itemId}/${profileId}/${Key_Key}/${departmentName}`,
        ""
      );

      if (response.status === 440) {
        alert("Session Expired");
        dispatch(logoutUser());
        window.location.href = ADMIN_LOGIN_BASE_URL;
      }
      if (
        response.status == 200 ||
        response.status == 201 ||
        response.status == 204
      ) {
        alert("Deleted");
        window.location.reload();
      }
    } catch (error) {
      console.log("Error during edit the banner:", error);
    } finally {
      setButtonLoading((prevState) => {
        const updatedLoadingState = [...prevState]; // Copying the loading state array
        updatedLoadingState[index] = false; // Resetting loading state back to false after operation completes
        return updatedLoadingState;
      });
    }
  };

  const editItem = async (id: string) => {
    let newEditItem = innerdata.find((elem) => {
      return elem._id === id;
    });
    setIsEditItem(id);
    setShowForAprov(false);
  };

  const ViewData = async (id: string, itemName: string) => {
    let newEditItem = innerdata?.find((elem) => {
      return elem._id === id;
    });
    // console.log("itemName", itemName);
    setIsEditItem(id);
    setShowForAprov(true);
    // setShowForAprovItemId(!showForAprovItemId)
  };

  const closeViewData = () => {
    setShowForAprov(false);
    setIsEditItem("");
  };
  return (
    <div>
      <Row>
        <Col lg={10}>
          <Card>
            <Card.Body>
              <h4>
                Update or delete{" "}
                <span style={{ fontSize: "20px", color: "red" }}>
                  {name ? name : "Select"}
                </span>{" "}
                from {activeDepartmentName}
              </h4>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th className="w-5px">#</th>
                    <th className="w-60">Title</th>
                    <th>Actions</th> {/* New column for actions */}
                  </tr>
                </thead>
                {(innerdata || []).map((item, i) => (
                  <>
                    <tbody key={i}>
                      <tr key={item._id}>
                        <td>
                          {Role == "Chess" &&
                          item?.superDuperAdminApprovalStatus == "Pending" ? (
                            <Image
                              src={img}
                              width={40}
                              height={8}
                              alt="Picture of the marquee"
                              className="pr-1"
                            />
                          ) : (
                            ""
                          )}

                          {Role == "Music" &&
                          item?.adminApprovalStatus == "Pending" ? (
                            <Image
                              src={img}
                              width={40}
                              height={8}
                              alt="Picture of the marquee"
                              className="pr-1"
                            />
                          ) : (
                            ""
                          )}
                          {"   "}
                          {i + 1}
                        </td>{" "}
                        {/* You can use i+1 as the index */}
                        <td>
                          {item?.name
                            ? item?.name
                            : item?.title
                            ? item?.title
                            : item?.researchBy
                            ? item?.researchBy
                            : item?.mission}
                        </td>
                        <td>
                          {/* Delete button */}
                          <Row className="gap-2">
                            <Col lg={2}>
                              <button
                                onClick={() => deleteItem(item._id, i)}
                                className="btn btn-danger"
                              >
                                Delete
                              </button>
                            </Col>

                            <Col lg={2}>
                              <button
                                onClick={() => editItem(item._id)}
                                className="btn btn-primary"
                              >
                                Update
                              </button>
                            </Col>
                            <Col lg={2}>
                              <button
                                onClick={() => ViewData(item._id, name ?? "")}
                                className="btn btn-primary"
                              >
                                View
                              </button>
                            </Col>
                          </Row>
                        </td>
                      </tr>
                    </tbody>
                  </>
                ))}
              </Table>
            </Card.Body>
          </Card>
        </Col>
        {isEditItem && !showForAprov ? (
          <Col lg={10}>
            <UpdateDepartmentItems
              itemId={isEditItem}
              parentId={id}
              innerdata={innerdata}
              name={name}
            />
          </Col>
        ) : (
          <Col lg={10}>
            <AproveDepartment
              itemId={isEditItem}
              innerdata={innerdata}
              parentId={id}
              itemName={name}
              departmentName={"FON"}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};
// ______________________________________________________________ DepartmentOfMedicine
const FacultyOfNursing = () => {
  const [validated, setValidated] = useState<boolean>(false);
  const [data, setData] = useState<Parent[]>([]);
  const [text, setText] = useState("");
  const [content, setContent] = useState("");

  const [link, setLink] = useState("");
  const [image, setImage] = useState<File | null>(null);
  const [innerData, setInnerData] = useState("");
  const [name, setName] = useState("");
  const [buttonLoading, setButtonLoding] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isResponse, setIsResponse] = useState("");
  const dispatch = useDispatch<AppDispatch>();

  const { user, userLoggedIn, loading, error, userId } = useSelector(
    (state: RootState) => ({
      user: state.Auth.user,
      loading: state.Auth.loading,
      error: state.Auth.error,
      userLoggedIn: state.Auth.userLoggedIn,
      userId: state.Auth._id,
    })
  );
  let profileId = user._id ? user._id : user.user ? user.user._id : "";
  let Role = user.hobbies ? user.hobbies : user.user.hobbies;
  let profileName = user.name ? user.name : user.user.name;

  const Key_Key = user.moniterd_ttl
    ? user.moniterd_ttl
    : user.user
    ? user.user.moniterd_ttl
    : "";
  let subDepartmentName = user.department
    ? user.department
    : user.user.department;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await fetch(
        //   "http://165.22.219.69:5002/api/footerpage"
        // );
        const departmentName = "FON";
        let innerUrl = "";
        if (Role === "Chess") {
          innerUrl = `/api/department/${departmentName}`;
        } else if (Role == "Music") {
          innerUrl = `/api/department/${subDepartmentName}/${departmentName}`;
        } else {
          innerUrl = `notforyou`;
        }
        const response = await api.fetchData(innerUrl);
        // const response = await fetch("http://165.22.219.69:5002/api/footerpage");

        if (!response.status == true) {
          // console.log("response--", await response.json())
          let errorMsg = await response.json();
          setIsResponse(errorMsg.message);
          // console.log("response from update user---",response)
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.data;

        if (data.length > 0) {
          // Assuming you want to set the pageId to the first item's _id
          // setPageId(data[0]._id);
        }

        setData(data);
        setLink(data[0]?.link);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the async function to fetch and set data
  }, []);

  const handleSubmit = async (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  //   ------------------------------------------ setting image in the input--------------------------------
  const handleBannerUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImage(e.target.files[0]);
    }
  };

  /*---------------- handle Form submit ---------------------------------------*/

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setButtonLoding(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      const formData = new FormData();
      formData.append("text", text);
      formData.append("content", content);
      formData.append("department", "FON");
      formData.append("createdBy", profileName);
      if (image) {
        formData.append("image", image);
      }

      try {
        // const response = await fetch(
        //   `http://165.22.219.69:5002/api/footerpage/${profileId}/${Key_Key}`,
        //   // const response = await fetch(`http://165.22.219.69:5002/api/footerpage`,

        //   {
        //     method: "POST",
        //     body: formData,
        //     credentials: "include",
        //   }
        // );
        const response = await api.createData(
          `/api/department/${profileId}/${Key_Key}`,
          formData
        );

        if (response.status === 440) {
          alert("Session Expired");
          dispatch(logoutUser());
          window.location.href = ADMIN_LOGIN_BASE_URL;
        }
        if (response.status === 429) {
          // console.log("response---6566656", response)
          setShowModal(true);
        }
        // console.log("response--statue", response)

        if (response.status === 400) {
          setIsError(true);
          let errorMessage = await response.message;
          setIsResponse(errorMessage);
        }

        if (response.status == 200 || response.status == 201) {
          alert("Added");
          window.location.reload();
          setButtonLoding(false);
        }
      } catch (error) {
        console.error("Error during edit the banner:", error);
      }
    }
    setValidated(true);
  };

  /*----------------  handle update----------------*/

  let objectId: any;

  if (data.length > 0) {
    const { _id } = data[0];
    objectId = _id;
  }

  const handleUpdate = async (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      const formData = new FormData();
      formData.append("text", text);
      formData.append("content", content);
      formData.append("facultyOfMedicineModel", "facultyOfMedicineModel");

      if (image) {
        formData.append("image", image);
      }

      try {
        // const response = await fetch(
        //   // `http://165.22.219.69:5002/api/update/footerpage/${objectId}`,
        //   `http://165.22.219.69:5002/api/update/footerpage/${objectId}/${profileId}/${Key_Key}`,
        //   {
        //     method: "PATCH",
        //     body: formData,
        //     credentials: "include",
        //   }
        // );

        const response = await api.updatePatchData(
          `/api/update/department/${objectId}/${profileId}/${Key_Key}`,
          formData
        );

        if (response.status === 440) {
          alert("Session Expired");
          dispatch(logoutUser());
          window.location.href = ADMIN_LOGIN_BASE_URL;
        }

        if (response.status === 429) {
          // console.log("response---6566656", response)
          setShowModal(true);
        }
        // console.log("response--statue", response)

        if (response.status === 400) {
          setIsError(true);
          let errorMessage = await response.message;
          setIsResponse(errorMessage);
        }
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        // Assuming you want to parse the JSON response
        const data = await response.json();
        // const userConfirmed = window.confirm("updated");

        if (data) {
          alert("Data updated");
          window.location.reload();
        }
      } catch (error) {
        console.error("Error during edit the banner:", error);
      }
    }
    setValidated(true);
  };

  const showItem = (item: any) => {
    // if (item === 'link') setInnerData(data[0]?.link); setName(item);
    if (item === "About Faculty") {
      if (data[0]?.aboutus !== undefined) {
        setInnerData(data[0]?.aboutus);
        setName(item);
      }
    }

    if (item === "Faculty & Staff") {
      if (data[0]?.facultyAndStaff !== undefined) {
        setInnerData(data[0]?.facultyAndStaff);
        setName(item);
      }
    }

    if (item === "Research & Publications") {
      if (data[0]?.researchAndPublications !== undefined) {
        setInnerData(data[0]?.researchAndPublications);
        setName(item);
      }
    }
    if (item === "Academics") {
      if (data[0]?.academics !== undefined) {
        setInnerData(data[0]?.academics);
        setName(item);
      }
    }
    if (item === "In Patient Services") {
      if (data[0]?.inPatientService !== undefined) {
        setInnerData(data[0]?.inPatientService);
        setName(item);
      }
    }
    if (item === "Out Patient Services") {
      if (data[0]?.outPatientService !== undefined) {
        setInnerData(data[0]?.outPatientService);
        setName(item);
      }
    }
    if (item === "Price List") {
      if (data[0]?.priceList !== undefined) {
        setInnerData(data[0]?.priceList);
        setName(item);
      }
    }
    if (item === "Links") {
      if (data[0]?.MisionAndVision !== undefined) {
        setInnerData(data[0]?.MisionAndVision);
        setName(item);
      }
    }
    if (item === "Gallery") {
      if (data[0]?.links !== undefined) {
        setInnerData(data[0]?.links);
        setName(item);
      }
    }
    if (item === "Mission & Vision") {
      if (data[0]?.gallery !== undefined) {
        setInnerData(data[0]?.gallery);
        setName(item);
      }
    }
    if (item === "Notices Board") {
      if (data[0]?.notice !== undefined) {
        setInnerData(data[0]?.notice);
        setName(item);
      }
    }
    if (item === "University Position") {
      if (data?.[0]?.universityPosition !== undefined) {
        setInnerData(data[0]?.universityPosition);
        setName(item);
      }
    }
    if (item === "Research & Projects") {
      if (data?.[0]?.researchAndProjects !== undefined) {
        setInnerData(data[0]?.researchAndProjects);
        setName(item);
      }
    }
    if (item === "Events Organized") {
      if (data?.[0]?.eventOrgDeprtmt !== undefined) {
        setInnerData(data[0]?.eventOrgDeprtmt);
        setName(item);
      }
    }
    if (item === "Student Corner") {
      if (data?.[0]?.studentCorner !== undefined) {
        setInnerData(data[0]?.studentCorner);
        setName(item);
      }
    }
  };
  console.log("objectId", objectId);
  return (
    <>
      <div>
        <Card>
          <h1 className="text-danger">Faculty of Nursing</h1>
          <Card.Body>
            <Row>
              {/* {data.length === 0 ? ( */}
              {Role && Role === "Chess" ? (
                <Col lg={5}>
                  <Form
                    style={{ width: "100%" }}
                    onSubmit={handleFormSubmit}
                    encType="multipart/form-data"
                  >
                    <h4>Add Department</h4>
                    {isError && (
                      <Alert variant="danger" className="my-2">
                        {isResponse}
                      </Alert>
                    )}
                    {showModal && <AuthModal />}
                    {/* <> */}
                    <Form.Group>
                      <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                        <h5>
                          Name of the Department{" "}
                          <span style={{ color: "red" }}> *</span>
                        </h5>
                      </Form.Label>
                      <Form.Control
                        className="accordion-item"
                        type="text"
                        placeholder="Name of the Department"
                        required
                        defaultValue=""
                        onChange={(e) => setText(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="d-flex  pt-2justify-content-start font-weight-bold">
                        <h5>
                          About Department{" "}
                          <span style={{ color: "red" }}> *</span>{" "}
                        </h5>
                      </Form.Label>
                      <Form.Control
                        className="accordion-item"
                        type="text"
                        placeholder="About Faculty"
                        defaultValue=""
                        onChange={(e) => setContent(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="d-flex pt-1 justify-content-start">
                        <h5>Page Backdrop</h5>
                      </Form.Label>
                      <Form.Control
                        type="file"
                        id="image"
                        name="bannerUrl"
                        accept="image/*"
                        onChange={handleBannerUrl}
                      />
                    </Form.Group>

                    <Form.Group className="pt-2 pb-2">
                      <Button type="submit" disabled={buttonLoading}>
                        {buttonLoading ? "Adding" : "Add"}
                      </Button>
                    </Form.Group>
                  </Form>
                </Col>
              ) : (
                ""
              )}

              {/* =============== practice ---------------- */}

              {/* -------------------------- update data--------------------------------------------- */}
              <Col lg={12}>{data && <DeleteDepartment data={data} />}</Col>
            </Row>

            {/* --------- department child design  ---------- */}

            <div
              style={{ width: "100%", height: "1px", backgroundColor: "red" }}
            ></div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default FacultyOfNursing;
