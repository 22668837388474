import React, { useState } from "react";
import PageTitle from "../../../../components/PageTitle";
import { Col, Row } from "react-bootstrap";

import "react-datepicker/dist/react-datepicker.css";
import StudentRegistrationForm from "./ProfileStudentRegistration";

interface Student {
  studentName: string;
  gender: string;
  aadharNumber: string;
  fatherName: string;
  motherName: string;
  dob: Date | null;
  caldob: string;
  ageCategory: string;
  mobileNumber: string;
  email: string;
  gameName: string[];
}

const ProfileRegisterStudent: React.FC = () => {
  const [validated, setValidated] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isResponse, setIsResponse] = useState("");

  return (
    <>
      <div>
        <PageTitle
          breadCrumbItems={[
            { label: "Pages", path: "/pages/student-profile" },
            {
              label: "Student Profile",
              path: "/pages/student-profile",
              active: true,
            },
          ]}
          title={"Student Profile"}
        />
        <Row>
          <Col lg={12}>
            <StudentRegistrationForm />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ProfileRegisterStudent;
